import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as GalleryIcon } from '../../../assets/icons/gallery-icon-text-area@3x.svg';
import { Variables } from '../Variables';
import { Emojis } from '../Emojis';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import InsertLink from '@material-ui/icons/InsertLink';
import AuthContext from 'contexts/AuthContext';
import ImageLibrary from './ImageLibrary';
import { API } from 'aws-amplify';
import { insertAtPosition } from '../utility';
import CircularProgress from '@material-ui/core/CircularProgress';

import '../forms.css';

import { ReactComponent as VariableSelect } from 'pages/Flows/FlowDiagram/tempIcons/varSelect.svg';
import { ErrorLabel, Modal } from 'stories';
import { useModal } from 'hooks';
import { Storage } from 'aws-amplify';
import { parseS3KeyFromImageId } from 'services/utility/parseS3KeyFromImageId';
Storage.configure({ level: 'public' });

export default function FieldExtras(props) {
  const tokens = useContext(AuthContext);

  const theme = useTheme();

  const buckets = {
    public: process.env.REACT_APP_S3_DASHBOARD_ASSETS,
    dialogues: process.env.REACT_APP_S3_DASHBOARD_ASSETS,
    pdfDocs: process.env.REACT_APP_S3_PUBLIC_ASSETS,
  };

  const { register } = useFormContext();
  const { isShowing, toggle } = useModal();

  const [textImage, setTextImage] = useState(props.image);
  const [imageUrl, setImageUrl] = useState(null);
  const [textFile, setTextFile] = useState(null);

  const [showPreview, setShowPreview] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showVariables, setShowVariables] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isLinkShowing, setIsLinkShowing] = useState(false);

  const [loadingImage, setLoadingImage] = useState(false);

  const [currentImagePicker, setCurrentImagePicker] = useState(null);
  const [currentFilePicker, setCurrentFilePicker] = useState(null);

  const [imageError, setImageError] = useState(null);

  const [displayInput, setDisplayInput] = useState('');
  const [urlInput, setUrlInput] = useState('');
  const [linkModalError, setLinkModalError] = useState(null);
  const [isCreatingLink, setIsCreatingLink] = useState(false);

  const displayInputRef = useRef(null);
  const urlInputRef = useRef(null);

  const openImagePicker = () => {
    if (textImage) {
      setTextImage(null);
      currentImagePicker.value = null;
      if (props.acceptFile) {
        props.acceptFile(null, 'image');
      }
    } else {
      currentImagePicker.click();
    }
  };

  const openFilePicker = () => {
    if (textFile) {
      setTextFile(null);
      currentFilePicker.value = null;
      if (props.acceptFile) {
        props.acceptFile(null, 'file');
      }
    } else {
      currentFilePicker.click();
    }
  };

  useEffect(() => {
    setTextImage(null);
    setTextFile(null);
  }, [props.resetFiles]);

  useEffect(() => {
    if (props.resetFile && props.resetFile.indexOf('image') > -1) {
      setTextImage(null);
    } else if (props.resetFile && props.resetFile.indexOf('file') > -1) {
      setTextFile(null);
    }
  }, [props.resetFile]);

  useEffect(() => {
    setTextImage(props.image);
    loadImage(props.image);
  }, [props.image]);

  const loadImage = async imageId => {
    if (props.type !== 'dialogues') {
      try {
        const presignedUrl = await Storage.get(
          parseS3KeyFromImageId(imageId),
          { bucket: process.env.REACT_APP_S3_DASHBOARD_ASSETS }
        );
        setImageUrl(presignedUrl);
      } catch (e) { }
    }
  };

  const verifyImage = async (event, type) => {
    setLoadingImage(true);

    let file = event.target.files[0];

    if (file) {
      let size = (file.size / 1024 / 1024).toFixed(4);

      if (size < 1) {
        if (props.acceptFile) {
          props.acceptFile('loading', type);
        }

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async e => {
          setIsUpload(true);

          // if (props.type === "dialogues") {
          let filePath = await uploadFile(type, file.type, e.target.result);
          // }

          if (type === 'image') {
            setTextImage(filePath);
            loadImage(filePath);
          } else {
            setTextFile(filePath);
          }

          setLoadingImage(false);
        };
      } else {
        console.log('BAD SIZE', size);
        setLoadingImage(false);
        setImageError('Image too large, must be under 1MB');
        setTimeout(() => {
          setImageError(null);
        }, 3000);
      }
    }
  };

  const uploadFile = async (fileType, contentType, file) => {
    let ranId = idGenerator();
    let fileExt = contentType.split('/')[1];

    let fileData = file + '';
    let imgData = file.split(',')[1];
    fileData = new Buffer.from(imgData, 'base64');

    let type = props.type ? props.type : 'public';

    let uploadBucket =
      contentType.indexOf('pdf') > -1 ? buckets.pdfDocs : buckets[type];

    let options = {
      bucket: uploadBucket,
      contentType: contentType,
    };

    if (contentType.indexOf('pdf') > -1) {
      options.ACL = 'public-read';
    }

    let filePath = fileType + '/' + tokens.env + '/' + ranId + '.' + fileExt;

    let s3resp = await Storage.put(filePath, fileData, options);

    if (props.acceptFile) {
      props.acceptFile(uploadBucket + '|' + filePath, fileType);
    }

    return uploadBucket + '|' + filePath;
  };

  const idGenerator = () => {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  };

  const acceptOptn = newValue => {
    props.setValue(newValue);
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  const asu_tuition_url = process.env.REACT_APP_ASU_TUITION + "/financial-aid";

  React.useEffect(() => {
    console.log('in useEffect', showEmojis);
    if (showEmojis) {
      document.addEventListener('click', handleEmojiClose, false);
    }

    return () => document.removeEventListener('click', handleEmojiClose);
  }, [showEmojis]);

  const handleEmojiClose = event => {
    const parent = document.querySelector('.emoji-mart');

    if (
      parent &&
      event.target.id !== 'toggleEmoji' &&
      showEmojis &&
      !parent.contains(event.target)
    ) {
      setShowEmojis(false);
    }
  };

  const {
    form,
    cannotEqualList,
    errorMessage,
    disabled,
    ...validProps
  } = props;

  const adjustImageLibraryWidth = () => {
    // console.log(window);
  };

  adjustImageLibraryWidth();

  const commitDisplayValue = e => {
    setDisplayInput(e.target.value);
  };

  const commitUrlValue = e => {
    setUrlInput(e.target.value);
  };

  const toggleLinkModal = () => {
    setIsLinkShowing(!isLinkShowing);
  };

  const createShortLink = () => {
    setLinkModalError(null);
    setIsCreatingLink(true);
    let text = displayInputRef.current.value;
    let url = urlInputRef.current.value;

    let localErrorMessage = '';

    if (text === '') {
      localErrorMessage += 'Missing Name ';
    }

    if (url === '') {
      localErrorMessage += 'Missing URL';
    }

    if (text === '' && url === '') {
      localErrorMessage = 'Missing Name & URL';
    }

    if (localErrorMessage !== '') {
      setIsCreatingLink(false);
      setLinkModalError(localErrorMessage);
    } else {
      /*axios
        .post(
          tokens.env === 'truedev'
            ? 'https://fqkovjlo3f.execute-api.us-west-2.amazonaws.com/truedev/link'
            : 'https://uca4pthoh0.execute-api.us-west-2.amazonaws.com/test/link',
          {
            action: 'create-link',
            text: text,
            url: url,
            createdAt: new Date().toJSON(),
            createdBy: tokens.asurite,
            group: JSON.stringify(tokens.groups),
          }
        )*/
      const apiName = 'linkRestAPI';
      const path = '/link';
      const myInit = {
        body: {
          action: 'create-link',
          text: text,
          url: url,
          createdAt: new Date().toJSON(),
          createdBy: tokens.asurite,
          group: JSON.stringify(tokens.groups),
        },
        responseType: "text"
        //headers: {} // OPTIONAL
      };

      API.post(apiName, path, myInit)
        .then(function (response) {
          console.log(response);

          let newStr = insertAtPosition(
            `[url:${response}]`,
            props.value,
            props.currentTextField
          );

          acceptOptn(newStr); //write to textbox

          toggleLinkModal();
          setIsCreatingLink(false);
        })
        .catch(function (error) {
          setIsCreatingLink(false);
          setLinkModalError(error.message);
          console.log(error.message);
        });
    }
  };

  const renderButtons = () => {
    if (isCreatingLink) {
      return (
        <CircularProgress
          style={{ color: theme.palette.general.lightMaroon }}
          size="1.5rem"
        />
      );
    } else {
      return (
        <>
          <button
            className="link-modal__button link-modal__button--yellow"
            onClick={createShortLink}
          >
            Ok
          </button>
          <button className="link-modal__button" onClick={toggleLinkModal}>
            Cancel
          </button>
        </>
      );
    }
  };

  return (
    <div
      {...validProps}
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        zIndex: '999',
        backgroundColor: 'white',
      }}
    >
      {props.allowextras ? (
        <div
          className="imageHolder"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: showEmojis ? '5px' : null,
            borderTop: showEmojis ? '1px solid #d9d9d9' : null,
            borderLeft: showEmojis ? '1px solid #d9d9d9' : null,
            borderRight: showEmojis ? '1px solid #d9d9d9' : null,
            borderRadius: '5px',
            // zIndex: "999",
            // backgroundColor: "white",
          }}
        >
          <input
            type="file"
            onChange={e => verifyImage(e, 'image')}
            ref={fId => {
              setCurrentImagePicker(fId);
            }}
            style={{ display: 'none' }}
            accept=".jpeg,.jpg,.png,.gif"
          />

          <input
            type="file"
            onChange={e => verifyImage(e, 'file')}
            ref={fId => {
              setCurrentFilePicker(fId);
            }}
            style={{ display: 'none' }}
            accept=".pdf"
          />

          <input
            type="text"
            value={textImage ? textImage : ''}
            name={
              props.imageName && props.propertyID
                ? `${props.imageName} ${props.propertyID}`
                : props.imageName
                  ? props.imageName
                  : 'image'
            }
            ref={fId => {
              register(fId);
            }}
            style={{ display: 'none' }}
          />

          {props.allowextras ? (
            <div>
              {props.allowextras.indexOf('image') > -1 ? (
                <strong title="Add Image">
                  <CropOriginalIcon
                    style={{
                      cursor: 'pointer',
                      color: textImage ? 'dodgerblue' : 'gray',
                    }}
                    onClick={openImagePicker}
                  />
                </strong>
              ) : null}
              {props.allowextras.indexOf('imgGallery') > -1 ? (
                <SvgIcon
                  component={GalleryIcon}
                  viewBox="-1 -4 24 24"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={toggle}
                />
              ) : null}

              {props.allowextras.indexOf('file') > -1 ? (
                <AttachFileIcon
                  style={{
                    cursor: 'pointer',
                    color: textFile ? 'dodgerblue' : 'gray',
                  }}
                  onClick={openFilePicker}
                />
              ) : null}

              {props.allowextras.indexOf('emoji') > -1 ? (
                <strong title="Add Emoji">
                  <SentimentSatisfiedOutlinedIcon
                    id="toggleEmoji"
                    style={{
                      cursor: 'pointer',
                      color: showEmojis ? '#ff8300' : 'gray',
                    }}
                    onClick={e => {
                      setShowVariables(false);
                      setShowEmojis(!showEmojis);
                    }}
                  />
                </strong>
              ) : null}

              {props.allowextras.indexOf('var') > -1 ? (
                <strong title="Add Variable">
                  <VariableSelect
                    style={{
                      cursor: 'pointer',
                      height: '18px',
                      width: '39px',
                      fill: showVariables ? '#518315' : 'gray',
                      verticalAlign: 'text-top',
                      'margin-top': '-6px',
                      marginLeft: '2px',
                    }}
                    onClick={e => {
                      setShowEmojis(false);
                      setShowVariables(!showVariables);
                    }}
                  />
                </strong>
              ) : null}
              {props.allowextras.indexOf('link') > -1 ? (
                <strong title="Add Link">
                  <InsertLink
                    id="addLink"
                    style={{
                      cursor: 'pointer',
                      color: 'gray',
                    }}
                    onClick={e => {
                      /*setShowVariables(false);
                      setShowEmojis(!showEmojis);*/
                      setIsLinkShowing(!isLinkShowing);
                    }}
                  />
                </strong>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}

      {showEmojis ? (
        <div className={props.type === 'dialogues' ? 'placeEmojiHolder' : ''}>
          <Emojis
            acceptOptn={acceptOptn}
            fieldRef={props.currentTextField}
            currentValue={props.value}
          />
        </div>
      ) : null}
      {showVariables ? (
        <div>
          <Variables
            ignoreInsert={props.ignoreInsert}
            acceptOptn={acceptOptn}
            fieldRef={props.currentTextField}
            currentValue={props.value}
          />
        </div>
      ) : null}

      {textImage && props.type !== 'dialogues' ? (
        <div>
          <div
            style={{
              fontSize: '12px',
              textDecoration: 'underline',
              color: '#565656',
              cursor: 'pointer',
            }}
            onClick={() => setShowPreview(!showPreview)}
          >
            {showPreview ? 'Hide' : 'Show'} Image Preview
          </div>
          {showPreview ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={imageUrl} style={{ width: '50%' }} />
            </div>
          ) : null}
        </div>
      ) : null}

      {loadingImage && props.type !== 'dialogues' ? (
        <div>
          <div
            style={{
              fontSize: '12px',
              color: '#565656',
              cursor: 'pointer',
            }}
          >
            Loading file, please do not close or save until completed
          </div>
        </div>
      ) : null}

      {imageError ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <ErrorLabel message={imageError} />
        </div>
      ) : null}
      <Modal
        style={{
          //if imageGallery is in flows, adjust width
          width: window.location.href.includes('view-flow') ? '40vw' : '62vw',
        }}
        isShowing={isShowing}
        hide={toggle}
        content={
          <ImageLibrary
            type={props.type}
            acceptFile={props.acceptFile ? props.acceptFile : null}
            loadImage={loadImage}
            setTextImage={setTextImage}
            onCloseHandler={toggle}
          />
        }
      />
      <Modal
        noOverflowY={true}
        style={{
          //if imageGallery is in flows, adjust width
          width: window.location.href.includes('view-flow') ? '40vw' : '62vw',
          overflowY: 'auto',
        }}
        isShowing={isLinkShowing}
        hide={toggleLinkModal}
        content={
          <>
            <h1> Insert Link </h1>
            <br />
            <h3> Display as </h3>
            <input
              type="text"
              className="link-modal__input"
              placeholder="Financial Aid"
              onChange={commitDisplayValue}
              ref={displayInputRef}
            />
            <h3>URL</h3>
            <input
              type="text"
              className="link-modal__input"
              placeholder={asu_tuition_url}
              onChange={commitUrlValue}
              ref={urlInputRef}
            />
            <br />
            <br />
            {linkModalError && (
              <div className="link-modal__error">{linkModalError}</div>
            )}
            {renderButtons()}
          </>
        }
      />
    </div>
  );
}
