import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { OpenSunny } from 'components/Sunny';

import { roleCheck } from 'services/utility/RoleMatrix';
import PaginationPanel from 'stories/Table/PaginationPanel';
import { SectionHeader } from 'components/SectionHeader';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import AuthContext from 'contexts/AuthContext';
import { store } from 'contexts/GlobalStore';
import { onUpdateCriticalEscalation } from 'graphql/subscriptions';
import { CreateNewButton, Table } from 'stories';
import NodeProperties from 'pages/Flows/FlowDiagram/Edit/NodeProperties.js';
import './Alerts.css';
import { tableUpdate } from 'services/utility/tableUpdate';


const pageLimit = 7;

let subscription = null;
let rawData = [];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Alerts(props) {
  const tokens = useContext(AuthContext);

  const theme = useTheme();
  const GlobalState = useContext(store);
  const { dispatch } = GlobalState;
  const {
    tableSort,
    spliceTableData,
    clearNextTokens,
    tableDataEmpty,
    globalTableState,
  } = GlobalState.state;

  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [filter, setFilter] = useState('All');
  const [group, setGroup] = useState('All');
  const [totalResults, setTotalResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPageClicked, setPrevPageClicked] = useState(false);
  const [nextTokenList, setNextTokenList] = useState([]);
  const [rerenderKey, setRerenderKey] = useState(Date.now());
  const [refetchKey, setRefetchKey] = useState(Date.now());
  const [editClicked, setEditClicked] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [showProperties, setShowProperties] = useState(false);

  const [loadedTableState, setLoadedTableState] = useState(false);
  const [loadedTableSearchState, setLoadedTableSearchState] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'setTableDataEmpty',
      tableDataEmpty: false,
    });
  }, []);

  useEffect(() => {
    if (clearNextTokens) {
      setNextTokenList([]);
      dispatch({
        type: 'clearNextTokens',
        clearNextTokens: false,
      });

      if (currentPage === 1) {
        setRefetchKey(Date.now());
      } else {
        setCurrentPage(1);
      }
    }
  }, [clearNextTokens]);

  useEffect(() => {
    createFetchData();
  }, [
    rerenderKey,
    refetchKey,
    searchActive,
    currentPage,
    filter,
    group,
    tableSort,
  ]);

  useEffect(() => {
    if (tableDataEmpty) {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else if (!tableDataEmpty && !loadedTableState) {
      const pathName = window.location.pathname.replace('/', '');
      let storedPage =
        globalTableState &&
        globalTableState[pathName] &&
        globalTableState[pathName].pageNumber;
      if (storedPage) {
        setCurrentPage(storedPage);
        setLoadedTableState(true);
      }
    }
  }, [tableDataEmpty]);

  useEffect(() => {
    const pathName = window.location.pathname.replace('/', '');
    let newGlobalTableState = Object.assign({}, globalTableState);
    if (!newGlobalTableState[pathName]) {
      newGlobalTableState[pathName] = {};
    }
    newGlobalTableState[pathName].pageNumber = currentPage;
    dispatch({
      type: 'setGlobalTableState',
      globalTableState: newGlobalTableState,
    });
  }, [currentPage]);

  const fetchData = async (searchText, filter, sort) => {
    if (tableDataEmpty) {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: true,
      });
    }
    try {
      let sortQuery = { field: 'timestamp', direction: 'desc' };
      if (sort) {
        sortQuery = { field: sort.field, direction: sort.direction };
      }

      let tokenIndex = currentPage - 2;
      if (currentPage > nextTokenList.length) {
        tokenIndex = nextTokenList.length - 1;
      }

      const result = await API.graphql(
        graphqlOperation(queries.searchCriticalEscalations, {
          limit: pageLimit,
          filter: createFilterQuery(searchText, filter),
          sort: sortQuery,
          nextToken: nextTokenList[tokenIndex],
        })
      );

      const { nextToken, total } = result.data.searchCriticalEscalations;
      let spliceCampsCount = 0;
      if (
        nextToken &&
        !prevPageClicked &&
        currentPage - 1 >= nextTokenList.length
      ) {
        setNextTokenList([...nextTokenList, nextToken]);
        if (
          currentPage > [...nextTokenList, nextToken].length &&
          currentPage !== 1
        ) {
          // use recursion to get subsequent page's nextToken
          return setRerenderKey(Date.now());
        }
      }

      let loadedUpdate = true;
      let loadedDelete = true;
      let loadedNew = false;

      if (spliceTableData) {
        if (spliceTableData.type === 'deleteAlert') {
          result.data.searchCriticalEscalations.items.forEach((el, index) => {
            if (el.id === spliceTableData.id) {
              loadedDelete = false;
            }
          });
          if (loadedDelete) {
            dispatch({
              type: 'setSpliceTableData',
              spliceTableData: null,
            });
          }
        } else {
          result.data.searchCriticalEscalations.items.forEach((el, index) => {
            if (spliceTableData.type === 'delete') {
              loadedNew = true;
            } else {
              if (el.timestamp === spliceTableData.item.timestamp.toString()) {
                loadedNew = true;
              }
            }
          });
        }
      } else if (!spliceTableData) {
        loadedNew = true;
      }

      if (!loadedUpdate || !loadedNew || !loadedDelete) {
        dispatch({
          type: 'clearNextTokens',
          clearNextTokens: true,
        });
        return setRerenderKey(Date.now());
      } else if (loadedUpdate && loadedNew && loadedDelete) {
        if (total) {
          setTotalResults(total - spliceCampsCount);
        }
        dispatch({
          type: 'setSpliceTableData',
          spliceTableData: null,
        });

        rawData = result.data.searchCriticalEscalations.items;
        const parsedData = parseData(
          result.data.searchCriticalEscalations.items
        );
        setData(parsedData);
        // console.log(parsedData)

        dispatch({
          type: 'setLoadingTableData',
          loadingTableData: false,
        });
      }
    } catch (error) {
      console.log('alerts fetch data error: ', error);
    } finally {
      tableDataEmpty &&
        dispatch({
          type: 'setLoadingTableData',
          loadingTableData: false,
        });
    }
  };

  const createFetchData = () => {
    //restore previous search
    const pathName = window.location.pathname.replace('/', '');
    let localSearchActive = searchActive;
    let localSearchKeyword = searchKeyword;
    if (
      !loadedTableSearchState &&
      globalTableState &&
      globalTableState[pathName] &&
      globalTableState[pathName].search !== ''
    ) {
      localSearchActive = true;
      localSearchKeyword = globalTableState[pathName].search;
      setSearchActive(true);
      setSearchKeyword(globalTableState[pathName].search);
    }
    setLoadedTableSearchState(true);

    if (localSearchActive && filter !== 'All' && tableSort) {
      fetchData(localSearchKeyword, filter, tableSort);
    } else if (localSearchActive && filter !== 'All' && !tableSort) {
      fetchData(localSearchKeyword, filter, null);
    } else if (localSearchActive && filter === 'All' && tableSort) {
      fetchData(localSearchKeyword, null, tableSort);
    } else if (localSearchKeyword === '' && filter !== 'All' && tableSort) {
      fetchData(null, filter, tableSort);
    } else if (localSearchActive) {
      fetchData(localSearchKeyword);
    } else if (filter !== 'All') {
      fetchData(null, filter);
    } else if (tableSort) {
      fetchData(null, null, tableSort);
    } else {
      fetchData();
    }
  };

  const createFilterQuery = (searchText, filter, groupFilterFlag) => {
    const filterConditions = [];

    let groupCheck = [{ group: { ne: 'Benji_1593621826' } }];

    if (!roleCheck(tokens.role, 'alerts', 'viewAllContent')) {
      filterConditions.push({ submittedBy: { eq: tokens.asurite } });
      filterConditions.push({ status: { eq: getStatusKey('Published') } });
      filterConditions.push({ status: { eq: getStatusKey('Approved') } });
    }

    let filterQuery = {
      deleted: { eq: false },
    };

    filterQuery.and = [
      {
        and: [...groupCheck],
      },
    ];

    if (filterConditions.length > 0) {
      filterQuery.and.push({
        or: [...filterConditions],
      });
    }

    if (groupFilterFlag) {
      filterQuery.or = [];
      tokens.groups.forEach(element => {
        filterQuery.or.push({ group: { eq: element } });
      });
    } else if (group !== 'All') {
      filterQuery.or = [];
      let grp = group;
      filterQuery.or.push({
        or: [{ group: { eq: grp } }],
      });
    }

    if (searchText && filter) {
      filterQuery.triggerPhrase = { matchPhrasePrefix: searchText };
      filterQuery.status = { eq: getStatusKey(filter) };
    } else if (filter) {
      filterQuery.status = { eq: getStatusKey(filter) };
    } else if (searchText) {
      filterQuery.triggerPhrase = { matchPhrasePrefix: searchText };
    }
    return filterQuery;
  };

  const getStatusKey = name => {
    if (name === 'Pending') {
      return 1;
    } else if (name === 'Unapproved') {
      return 2;
    } else if (name === 'Approved') {
      return 3;
    } else if (name === 'Published') {
      return 4;
    } else if (name === 'Disabled') {
      return 5;
    } else {
      return null;
    }
  };

  const parseData = data => {
    return data.map(el => {
      return {
        id: el.id,
        name: el.triggerPhrase,
        status: {
          statusID: el.status,
          processing: el.processing,
          editing: el.editing,
          error: el.processingError,
        },
        //  group: el.group,
        submittedBy: el.submittedBy,
        submittedOn: el.timestamp,
        actions: {
          edit: roleCheckEdit(el.group, el.submittedBy) ? true : false,
          delete: roleCheckDel(el.group, el.submittedBy) ? true : false,
        },
      };
    });
  };

  const roleCheckEdit = (group, asurite) => {
    if (roleCheck(tokens.role, 'alerts', 'edit')) {
      if (tokens.asurite === asurite) {
        return true;
      } else {
        if (tokens.role === 'content creator' || tokens.role === 'approver') {
          if (tokens.groups.includes(group)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  const roleCheckDel = (group, asurite) => {
    if (roleCheck(tokens.role, 'alerts', 'delete')) {
      if (tokens.asurite === asurite) {
        return true;
      } else {
        if (tokens.role === 'content creator' || tokens.role === 'approver') {
          if (tokens.groups.includes(group)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    console.log("[Alerts.js] lastMessage updated", props.lastMessage);

    let post = {
      data: {},
      cms_identifier: ""
    };
    
    if (props.lastMessage !== null && props.lastMessage.data) {
      let full_post = JSON.parse(props.lastMessage.data)
      post.data = full_post.data || {};
      post.cms_identifier = full_post.cms_identifier || "";
    }

    if (post.cms_identifier === "onUpdateCriticalEscalation") {
      let kbItem = post.data;
      let tableUpdateResult = tableUpdate(kbItem, rawData);
      let foundId = tableUpdateResult.foundId;

      rawData = tableUpdateResult.newArray;

      if (foundId) {
        if (kbItem.deleted) {
          createFetchData();
        } else {
          let parsedData = parseData(tableUpdateResult.newArray);
          setData(parsedData);
          console.log("[Alerts.js] lastEdit", kbItem.lastEdit);
          setRerenderKey(kbItem.lastEdit);
        }
      }
    }

    return () => {
      // clean up in hook
    };

  }, [props.lastMessage]);

  const onSubmitDelete = async el => {
    let rawEsc;
    rawData.forEach(data => {
      if (el.id === data.id) {
        rawEsc = data;
      }
    });

    const getEscalation = await API.graphql(
      graphqlOperation(queries.getEscalation, {
        id: rawEsc.escalation,
      })
    );

    const { attachedAlertIds } = getEscalation.data.getEscalation;
    const newEscalations = attachedAlertIds.filter(e => e !== rawEsc.id);

    const mutateEscalation = await API.graphql(
      graphqlOperation(mutations.updateEscalation, {
        input: {
          id: rawEsc.escalation,
          attachedAlertIds: newEscalations,
        },
      })
    );

    const criticalEscalationToDelete = {
      id: el.id,
      status: 6,
      deletedBy: {
        asurite: tokens.asurite,
        timestamp: Date.now().toString(),
      },
    };
    try {
      const mutateCriticalEscalation = await API.graphql(
        graphqlOperation(mutations.updateCriticalEscalation, {
          input: criticalEscalationToDelete,
        })
      );

      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } catch (error) {
      console.log('criticalEscalation delete error: ', error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onClickEdit = el => {
    rawData.forEach(data => {
      if (el.id === data.id) {
        dispatch({
          type: 'updateEditInfo',
          editInfo: {
            id: data.id,
            status: data.status,
            escalation: data.escalation,
            triggerPhrase: data.triggerPhrase,
            //       group: data.group,
            answerData: data.answerData,
            variations: data.variations,
            submittedByAsurite: data.submittedBy,
            submittedByTimestamp: data.timestamp,
            processingError: data.processingError,
            editedBy: JSON.stringify(data.editedBy),
            submittedBy: data.submittedBy,
            fallbackAnswer: data.fallbackAnswer,
            fallbackAnswerImage: data.fallbackAnswerImage,
            escalateSms: data.escalateSms,
          },
        });
      }
    });
    props.history.push({
      pathname: '/alert-add',
    });
  };

  const onChangeSearch = value => {
    setSearchKeyword(value);
  };

  const onClickSearch = () => {
    if (searchKeyword === '') {
      return;
    } else {
      setNextTokenList([]);
      setSearchActive(true);
      if (currentPage === 1) {
        setRerenderKey(Date.now());
      } else {
        setCurrentPage(1);
      }
    }
  };

  const onClickReset = () => {
    setNextTokenList([]);
    setSearchActive(false);
    setRerenderKey(Date.now);
    dispatch({
      type: 'changeTableSort',
      tableSort: null,
    });

    if (group !== 'All') {
      setGroup('All');
    } else if (currentPage === 1) {
      setRerenderKey(Date.now());
    } else {
      setCurrentPage(1);
    }
  };

  const onSelectFilter = value => {
    setCurrentPage(1);
    dispatch({
      type: 'clearNextTokens',
      clearNextTokens: true,
    });
    if (value === filter || !value) {
      setFilter('All');
      dispatch({ type: 'changeTableFilter', tableFilter: 'All' });
    } else {
      setFilter(value);
      dispatch({ type: 'changeTableFilter', tableFilter: value });
    }
  };

  const onSelectGroup = value => {
    setCurrentPage(1);
    dispatch({
      type: 'clearNextTokens',
      clearNextTokens: true,
    });
    if (value === group || !value) {
      setGroup('All');
      dispatch({ type: 'changeTableFilter', tableFilter: 'All' });
    } else {
      setGroup(value);
      dispatch({ type: 'changeTableFilter', tableFilter: value });
    }
  };

  const onClickFetchMore = () => {
    setPrevPageClicked(false);
    if (currentPage === Math.ceil(totalResults / pageLimit)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onClickFetchLess = async () => {
    setPrevPageClicked(true);
    if (currentPage <= 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const onChangeJumpToPage = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const jumpToPageOptions = () => {
    const totalPages = Math.ceil(totalResults / pageLimit);
    const options = [];

    for (let i = 1; i <= totalPages; i++) {
      options.push(i);
    }
    const mappedOptions = options.map(el => {
      return { value: el, label: el };
    });
    return mappedOptions;
  };

  const onClickCreateNew = () => {
    dispatch({ type: 'updateEditInfo', editInfo: { empty: true } });
    dispatch({
      type: 'changeTableSort',
      tableSort: null,
    });
    dispatch({ type: 'changeTableFilter', tableFilter: 'All' });
  };

  const filterOptions = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Unapproved', label: 'Unapproved' },
    { value: 'Published', label: 'Published' },
    // { value: "Disabled", label: "Disabled" }
  ];

  let groupOptions = JSON.parse(
    sessionStorage.getItem('allGroups-' + tokens.env)
  ).map(object => {
    let newObject = {};
    newObject.value = object.id;
    newObject.label = object.name;
    return newObject;
  });

  let propertyName = 'label';

  groupOptions.sort((a, b) => {
    if (a[propertyName] < b[propertyName]) {
      return -1;
    }
    if (a[propertyName] > b[propertyName]) {
      return 1;
    }
    return 0;
  });

  const tableLabels = [
    { title: 'Alert Phrase', wide: true },
    { title: 'Status', notClickable: true },
    //   { title: 'Group' },
    { title: 'Created By' },
    { title: 'Created On' },
    { title: 'Actions', alignRight: true, notClickable: true },
  ];

  const getSortKey = label => {
    label = label.toUpperCase();
    if (label === 'ALERT PHRASE') {
      return 'triggerPhrase';
    } else if (label === 'STATUS') {
      return 'statusKey';
    } else if (label === 'GROUP') {
      return 'groupKey';
    } else if (label === 'CREATED BY') {
      return 'submittedByKey';
    } else if (label === 'CREATED ON') {
      return 'timestampKey';
    }
  };

  const closeRunBot = () => {
    setShowProperties(false);
  };

  const openRunBot = () => {
    setShowProperties(true);
  };

  const botInfo = {
    type: 'runBot',
  };

  return (
    <div style={theme.components.pageContainer} key={rerenderKey}>
      <div className="SectionHeader">
        <SectionHeader
          onChangeSearch={onChangeSearch}
          onClickSearch={onClickSearch}
          onClickReset={onClickReset}
          onSelectFilter={onSelectFilter}
          onSelectGroup={onSelectGroup}
          groupOptions={groupOptions}
          filterOptions={filterOptions}
          noSort
          noModality
          noGroup
          rightView={
            roleCheck(tokens.role, 'alerts', 'create') && (
              <div onClick={onClickCreateNew}>
                <button class="CreateAlert">
                  <CreateNewButton
                    to="/alert-add"
                    label="Create New Alert"
                    noWrap={true}
                  />
                </button>
              </div>
            )
          }
        />
      </div>
      <OpenSunny onClick={openRunBot} />
      <NodeProperties
        show={showProperties}
        node={botInfo}
        close={closeRunBot}
      />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Alert has been deleted successfully!
        </Alert>
      </Snackbar>
      <div className="TablePage">
        <Paper style={theme.components.paper}>
          <div>
            {/* <div /> */}
            <Table
              title="Alerts Table"
              labels={tableLabels}
              data={data}
              useversionstatus={true}
              onChangeSearch={onChangeSearch}
              onClickSearch={onClickSearch}
              onClickReset={onClickReset}
              onSelectFilter={onSelectFilter}
              onSubmitDelete={onSubmitDelete}
              onClickEdit={onClickEdit}
              // onClickView={onClickView}
              filterOptions={filterOptions}
              getSortKey={getSortKey}
              defaultSortKey="timestampKey"
              noSort
              // noFilter
              noSearch
            />
            <PaginationPanel
              theme={theme}
              currentPage={currentPage}
              pageCount={Math.ceil(totalResults / pageLimit)}
              onClickFetchMore={onClickFetchMore}
              onClickFetchLess={onClickFetchLess}
              jumpToPageOptions={jumpToPageOptions}
              onChangeJumpToPage={onChangeJumpToPage}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
}

Alerts.propTypes = {
  navigation: PropTypes.object,
};
