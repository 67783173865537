/**
 * The dynamodb table (defined in TF as "aws_dynamodb_table.image")
 * Has a column called 'imageId' which is a multi-valued attribute
 * It has the format 'bucket-name|object-key'
 * Bucket name now comes from terraform and is injected into the app via env vars.
 * So this column should be normalized to just be the s3 key.
 * This function hides this implementation detail and can be removed when the imageId column is normalized.
 * @param {string} imageId - The imageId field
 * @returns {string|null} - Returns the s3 object key
 */
export const parseS3KeyFromImageId = (imageId) => imageId?.split('|').at(1) || null;