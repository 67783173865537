import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

import {
  listKnowledgeBaseCategorys,
  searchKnowledgeBases,
} from 'graphql/queries';
import { onUpdateKnowledgeBase } from 'graphql/subscriptions';
import * as mutations from 'graphql/mutations';

import { roleCheck } from 'services/utility/RoleMatrix';
import AuthContext from 'contexts/AuthContext';
import { store as KBStore } from 'contexts/KBStore';
import { store as GlobalStore } from 'contexts/GlobalStore';
import { ReactComponent as WebIcon } from '../../assets/icons/icon-web.svg';
import { ReactComponent as SMSIcon } from '../../assets/icons/icon-sms.svg';
import { ReactComponent as VoiceIcon } from '../../assets/icons/voice-icon.svg';
import SvgIcon from '@material-ui/core/SvgIcon';

import PaginationPanel from 'stories/Table/PaginationPanel';
import { combineData } from 'pages/KnowledgeBase/utility';
import { Table } from 'stories';
import { SectionHeader } from 'components/SectionHeader';
import { getUserGroups } from 'hooks';
import './FAQs.css';
import { tableUpdate } from 'services/utility/tableUpdate';

let subscription = null;
let FAQs = [];
let filteredData = null;
let categories = [];

export default function FAQsTable(props) {
  const theme = useTheme();
  const tokens = useContext(AuthContext);
  const KBState = useContext(KBStore);
  const dispatchKB = KBState.dispatch;
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;
  const {
    spliceTableData,
    tableSort,
    tableDataEmpty,
    globalTableState,
  } = GlobalState.state;

  const [FAQsData, setFAQsData] = useState([]);
  const [rawFAQsData, setRawFAQsData] = useState([]);
  const [editClicked, setEditClicked] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filter, setFilter] = useState('All');
  const [group, setGroup] = useState('All');
  const [modality, setModality] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchActive, setSearchActive] = useState(false);
  const [totalResults, setTotalResults] = useState(null);
  const [prevPageClicked, setPrevPageClicked] = useState(false);
  const [groupOptions, setGroupOptions] = useState([]);
  const [refetchKey, setRefetchKey] = useState(Date.now());
  const [rerenderKey, setRerenderKey] = useState(Date.now());

  const [loadedTableState, setLoadedTableState] = useState(false);
  const [loadedTableSearchState, setLoadedTableSearchState] = useState(false);

  const { groups } = getUserGroups();

  // console.log('GROUPS: ', groups);

  const pageLimit = 7;

  useEffect(() => {
    dispatchGlobal({
      type: 'setTableDataEmpty',
      tableDataEmpty: false,
    });
  }, []);

  useEffect(() => {
    createFetchData();
  }, [
    currentPage,
    searchActive,
    refetchKey,
    filter,
    group,
    modality,
    tableSort,
  ]);

  useEffect(() => {
    if (tableDataEmpty) {
      dispatchGlobal({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else if (!tableDataEmpty && !loadedTableState) {
      const pathName = window.location.pathname.replace('/', '');
      let storedPage =
        globalTableState &&
        globalTableState[pathName] &&
        globalTableState[pathName].pageNumber;
      if (storedPage) {
        setCurrentPage(storedPage);
        setLoadedTableState(true);
      }
    }
  }, [tableDataEmpty]);

  useEffect(() => {
    let groupOptionsx = groups.map(object => {
      let newObject = {};
      newObject.value = object.id;
      newObject.label = object.name;
      return newObject;
    });

    let propertyName = 'label';

    groupOptionsx.sort((a, b) => {
      if (a[propertyName] < b[propertyName]) {
        return -1;
      }
      if (a[propertyName] > b[propertyName]) {
        return 1;
      }
      return 0;
    });

    setGroupOptions(groupOptionsx);
  }, [groups]);

  useEffect(() => {
    const pathName = window.location.pathname.replace('/', '');
    let newGlobalTableState = Object.assign({}, globalTableState);
    if (!newGlobalTableState[pathName]) {
      newGlobalTableState[pathName] = {};
    }
    newGlobalTableState[pathName].pageNumber = currentPage;
    dispatchGlobal({
      type: 'setGlobalTableState',
      globalTableState: newGlobalTableState,
    });
  }, [currentPage]);

  const fetchData = async (searchText, filter, sort) => {
    dispatchGlobal({
      type: 'setLoadingTableData',
      loadingTableData: true,
    });
    try {
      props.setActiveCategory(null);

      // direction is either asc or desc
      let sortQuery = { field: 'lastEditKey', direction: 'desc' };
      if (sort) {
        sortQuery = { field: sort.field, direction: sort.direction };
      }

      let filterQ = createFilterQuery(searchText, filter);

      const KBData = await API.graphql(
        graphqlOperation(searchKnowledgeBases, {
          filter: filterQ,
          sort: sortQuery,
        })
      );

      if (!KBData.data.searchKnowledgeBases.items.length) {
        return dispatchGlobal({
          type: 'setTableDataEmpty',
          tableDataEmpty: true,
        });
      } else {
        dispatchGlobal({
          type: 'setTableDataEmpty',
          tableDataEmpty: false,
        });
      }

      const categoryData = await API.graphql(
        graphqlOperation(listKnowledgeBaseCategorys, {
          filter: {
            deleted: { eq: false },
          },
        })
      );

      categories = categoryData.data.listKnowledgeBaseCategorys.items;
      FAQs = KBData.data.searchKnowledgeBases.items;

      const combinedData = combineData(FAQs, categories);

      const filteredData = await FAQCheck(combinedData);
      let modalityJSX;

      filteredData.forEach((object, index) => {
        let sms = false;
        let chat = false;
        let voice = false;
        object.answerData.forEach(answer => {
          if (answer.type === 'sms') {
            sms = true;
          } else if (answer.type === 'chat') {
            chat = true;
          } else if (answer.type === 'voice') {
            voice = true;
          }

          modalityJSX = (
            <>
              {sms && (
                <SvgIcon
                  style={{ transform: 'scale(0.8)' }}
                  viewBox="-5 -1 16.5 16.5"
                  component={SMSIcon}
                />
              )}
              {chat && (
                <SvgIcon
                  style={{ transform: 'scale(0.8)' }}
                  viewBox="-5 -1 15 15"
                  component={WebIcon}
                />
              )}

              {voice && (
                <SvgIcon
                  style={{ transform: 'scale(0.8)' }}
                  viewBox="-5 -1 23 23"
                  component={VoiceIcon}
                />
              )}
            </>
          );
          filteredData[index].modality = modalityJSX;
        });
      });

      let loadedUpdate = true;
      let loadedNew = false;

      if (spliceTableData) {
        filteredData.forEach((el, index) => {
          if (spliceTableData.type === 'delete') {
            loadedNew = true;
            if (el.id === spliceTableData.id) {
              if (el.status !== 6) {
                loadedUpdate = false;
              }
            }
          } else if (spliceTableData.type === 'editFAQ') {
            spliceTableData.FAQs.forEach(FAQ => {
              if (el.id === FAQ.id) {
                if (el.lastEdit === FAQ.lastEdit) {
                  loadedNew = true;
                }
              }
            });
          } else if (spliceTableData.type === 'addFAQ') {
            spliceTableData.FAQs.forEach(FAQ => {
              if (el.id === FAQ.id) {
                loadedNew = true;
              }
            });
          }
        });
      } else if (!spliceTableData) {
        loadedNew = true;
      }

      if (categories && FAQs) {
        if (!FAQs.length) {
        } else if (!loadedUpdate || !loadedNew) {
          return setRefetchKey(Date.now());
        } else if (loadedUpdate && loadedNew) {
          dispatchGlobal({
            type: 'setSpliceTableData',
            spliceTableData: null,
          });

          setRawFAQsData(filteredData);
          let parsedData = parseData(filteredData);

          let groupCheck = (group, arr) => {
            for (let i of arr) {
              if (i === group) {
                return true;
              }
            }
            return false;
          };

          if (tokens.role === 'approver' || tokens.role === 'content creator') {
            // console.log(tokens.role)
            parsedData = parsedData.filter(el =>
              groupCheck(el.group, tokens.groups)
            );
          }
          // console.log(parsedData);
          const total = parsedData.length;

          if (total) {
            setTotalResults(total);
          }

          setFAQsData(parsedData);

          dispatchGlobal({
            type: 'setLoadingTableData',
            loadingTableData: false,
          });
        }
      }
    } catch (error) {
      console.log('fetchData error: ', error);
    } finally {
      tableDataEmpty &&
        dispatchGlobal({
          type: 'setLoadingTableData',
          loadingTableData: false,
        });
    }
  };

  /* console.log("[FAQ.js] re-render", Date.now()); */ 

  useEffect(() => {
    console.log("[FAQ.js] lastMessage updated", props.lastMessage);

    let post = {
      data: {},
      cms_identifier: ""
    };
    
    if (props.lastMessage !== null && props.lastMessage.data) {
      let full_post = JSON.parse(props.lastMessage.data)
      post.data = full_post.data || {};
      post.cms_identifier = full_post.cms_identifier || "";
    }

    if (post.cms_identifier === "onUpdateKnowledgeBase") {
      let kbItem = post.data;
      let tableUpdateResult = tableUpdate(kbItem, FAQs);
      let foundId = tableUpdateResult.foundId;

      FAQs = tableUpdateResult.newArray;

      if (foundId) {
        if (kbItem.deleted) {
          createFetchData();
        } else {
          filteredData = combineData(tableUpdateResult.newArray, categories);
          setRawFAQsData(filteredData);
          let parsedData = parseData(filteredData);

          setFAQsData(parsedData);
          props.setRerenderKey(kbItem.lastEdit);
        }
      }
    }
  }, [props.lastMessage]);

  const createFetchData = () => {
    //restore previous search
    const pathName = window.location.pathname.replace('/', '');
    let localSearchActive = searchActive;
    let localSearchKeyword = searchKeyword;
    if (
      !loadedTableSearchState &&
      globalTableState &&
      globalTableState[pathName] &&
      globalTableState[pathName].search !== ''
    ) {
      localSearchActive = true;
      localSearchKeyword = globalTableState[pathName].search;
      setSearchActive(true);
      setSearchKeyword(globalTableState[pathName].search);
    }
    setLoadedTableSearchState(true);

    if (localSearchActive && filter !== 'All' && tableSort) {
      fetchData(localSearchKeyword, filter, tableSort);
    } else if (localSearchActive && filter !== 'All' && !tableSort) {
      fetchData(localSearchKeyword, filter, null);
    } else if (localSearchActive && filter === 'All' && tableSort) {
      fetchData(localSearchKeyword, null, tableSort);
    } else if (localSearchKeyword === '' && filter !== 'All' && tableSort) {
      fetchData(null, filter, tableSort);
    } else if (localSearchActive) {
      fetchData(localSearchKeyword);
    } else if (filter !== 'All') {
      fetchData(null, filter);
    } else if (tableSort) {
      fetchData(null, null, tableSort);
    } else {
      fetchData();
    }
  };

  const parseData = data => {
    return data.map(el => {
      let name = '';
      if (el.parentCategory && el.subcategoryNames) {
        name = `${el.parentCategory.title}: ${el.subcategoryNames[0]}`;
      }

      return {
        id: el.id,
        name: name === '' || !el.subcategoryNames.length ? 'N/A' : name,
        description: el.question,
        status: {
          statusID: el.status,
          processing: el.processing,
          editing: el.editing,
          error: el.processingError,
        },
        modality: el.modality,
        variations: el.variationsCount,
        group: el.group,
        editedBy: el.editedBy[el.editedBy.length - 1].asurite,
        editedOn: el.lastEdit,
        submittedBy: el.submittedBy,
        submittedOn: el.submittedOn,
        actions: {
          edit: roleCheckEdit(el.group, el.submittedBy),
          delete: roleCheckDel(el.group, el.submittedBy),
        },
      };
    });
  };

  const roleCheckDel = (group, asurite) => {
    if (!roleCheck(tokens.role, 'knowledgeBase', 'deleteFAQ')) {
      return false;
    } else {
      if (
        roleCheck(tokens.role, 'knowledgeBase', 'viewAllContent') ||
        tokens.asurite === asurite ||
        tokens.groups.includes(group)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const roleCheckEdit = (group, asurite) => {
    if (!roleCheck(tokens.role, 'knowledgeBase', 'editFAQ')) {
      return false;
    } else {
      if (
        roleCheck(tokens.role, 'knowledgeBase', 'viewAllContent') ||
        tokens.asurite === asurite ||
        tokens.groups.includes(group)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const createFilterQuery = (
    searchText,
    filter,
    groupFilterFlag,
    modalityFilter
  ) => {
    const filterConditions = [];

    let groupCheck = [];

    // Remove Benji
    groupCheck.push({ group: { ne: 'Benji_1593621826' } });

    if (!roleCheck(tokens.role, 'knowledgeBase', 'viewAllContent')) {
      tokens.groups.forEach(el => {
        filterConditions.push({ group: { eq: el } });
      });
      // filterConditions.push({ submittedBy: { eq: tokens.asurite } });
      // filterConditions.push({ status: { eq: getStatusKey("Published") } });
      // filterConditions.push({ status: { eq: getStatusKey("Approved") } });
    }

    let filterQuery = {
      deleted: { eq: false },
    };

    filterQuery.and = [
      {
        and: [...groupCheck],
      },
    ];

    if (filterConditions.length > 0) {
      filterQuery.and.push({
        or: [...filterConditions],
      });
    }

    if (groupFilterFlag) {
      filterQuery.or = [];
      tokens.groups.forEach(element => {
        filterQuery.or.push({ group: { eq: element } });
      });
    } else if (group !== 'All') {
      filterQuery.or = [];
      let grp = group;
      filterQuery.or.push({
        or: [{ group: { eq: grp } }],
      });
    }

    if (searchText && filter) {
      filterQuery.question = { matchPhrasePrefix: searchText };
      filterQuery.status = { eq: getStatusKey(filter) };
    } else if (filter) {
      filterQuery.status = { eq: getStatusKey(filter) };
    } else if (searchText) {
      filterQuery.question = { matchPhrasePrefix: searchText };
    }

    console.log('Modal', modality);

    let selectedType = '';
    if (modality === 'sms') {
      selectedType = 'sms';
    } else if (modality === 'chat') {
      selectedType = 'chat';
    } else if (modality === 'voice') {
      selectedType = 'voice';
    }

    if (modality !== 'All') {
      filterQuery.modality = {
        match: {
          modality: `${selectedType}`,
        },
      };
    }

    return filterQuery;
  };

  const getStatusKey = name => {
    if (name === 'Pending') {
      return 1;
    } else if (name === 'Unapproved') {
      return 2;
    } else if (name === 'Approved') {
      return 3;
    } else if (name === 'Published') {
      return 4;
    } else if (name === 'Disabled') {
      return 5;
    } else {
      return null;
    }
  };

  const FAQCheck = async FAQs => {
    let FAQsWithoutCatNames = [];
    const updatedFAQs = FAQs;
    FAQs.forEach(el => {
      if (
        (!el.categoryName && el.status !== 6) ||
        (!el.subcategoryNames.length && el.status !== 6)
      ) {
        // FAQsWithoutCatNames.push(el);
      }
    });
    await FAQsWithoutCatNames.forEach(async el => {
      try {
        const delFAQ = await API.graphql(
          graphqlOperation(mutations.updateKnowledgeBase, {
            input: { id: el.id, status: 6 },
          })
        );
      } catch (error) {
        console.log('delFAQ error: ', error);
      }
    });

    return updatedFAQs;
  };

  const onChangeSearch = value => {
    setSearchKeyword(value);
  };

  const onClickSearch = () => {
    if (searchKeyword === '') {
      return;
    } else {
      setSearchActive(true);
      if (currentPage === 1) {
        setRefetchKey(Date.now());
      } else {
        setCurrentPage(1);
      }
    }
  };

  const onClickReset = () => {
    setSearchActive(false);
    setRerenderKey(Date.now());
    setModality('All');
    dispatchGlobal({
      type: 'changeTableSort',
      tableSort: null,
    });

    if (group !== 'All') {
      setGroup('All');
    } else if (currentPage === 1) {
      setRefetchKey(Date.now());
    } else {
      setCurrentPage(1);
    }
  };

  const onSelectGroup = value => {
    setCurrentPage(1);
    dispatchGlobal({
      type: 'clearNextTokens',
      clearNextTokens: true,
    });
    if (value === group || !value) {
      setGroup('All');
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: 'All' });
    } else {
      setGroup(value);
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: value });
    }
  };

  const onSelectModality = value => {
    setCurrentPage(1);
    dispatchGlobal({
      type: 'clearNextTokens',
      clearNextTokens: true,
    });
    if (value === modality || !value) {
      setModality('All');
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: 'All' });
    } else {
      setModality(value);
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: value });
    }
  };

  const onClickFetchMore = () => {
    setPrevPageClicked(false);
    if (currentPage === Math.ceil(totalResults / pageLimit)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onClickFetchLess = async () => {
    setPrevPageClicked(true);
    if (currentPage <= 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const onChangeJumpToPage = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const onSelectFilter = value => {
    setCurrentPage(1);
    if (value === filter || !value) {
      setFilter('All');
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: 'All' });
    } else {
      setFilter(value);
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: value });
    }
    dispatchGlobal({ type: 'setRefetchKey', refetchKey: Date.now() });
    dispatchGlobal({ type: 'setCurrentPage', currentPage: 1 });
  };

  const onSubmitDelete = async item => {
    dispatchGlobal({
      type: 'setSpliceTableData',
      spliceTableData: { ...item, type: 'delete' },
    });
    const updatedData = {
      id: item.id,
      status: 6,
      deletedBy: { asurite: tokens.asurite, timestamp: moment().valueOf() },
    };
    try {
      // to show on current users side timeout
      setTimeout(() => {
        API.graphql(
          graphqlOperation(mutations.updateKnowledgeBase, {
            input: updatedData,
          })
        );
      }, 1000);
    } catch (error) {
      console.log('updateKnowledgeBase error: ', error);
    } finally {
      dispatchGlobal({ type: 'setRefetchKey', refetchKey: Date.now() });
      props.setRerenderKey(Date.now());
    }
  };

  const onClickEdit = el => {
    rawFAQsData.forEach(data => {
      if (el.id === data.id) {
        if (data.flow) {
          data.flow = JSON.parse(data.flow);
        }

        for (var i = 0; i < data.variations.length; ++i) {
          data.variations[i] = data.variations[i].replace(/,/g, '~~~');
        }

        dispatchGlobal({
          type: 'updateEditInfo',
          editInfo: data,
        });
      }
    });
    dispatchGlobal({
      type: 'changeTableSort',
      FAQSort: {
        field: 'lastEditKey',
        direction: 'desc',
      },
    });
    dispatchKB({
      type: 'setFormTitle',
      formTitle: 'EDIT FAQ',
    });
    setEditClicked(true);
  };

  const jumpToPageOptions = () => {
    const totalPages = Math.ceil(totalResults / pageLimit);
    const options = [];

    for (let i = 1; i <= totalPages; i++) {
      options.push(i);
    }
    const mappedOptions = options.map(el => {
      return { value: el, label: el };
    });
    return mappedOptions;
  };

  const filterOptions = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Unapproved', label: 'Unapproved' },
    { value: 'Published', label: 'Published' },
    { value: 'Disabled', label: 'Disabled' },
  ];

  const modalityOptions = [
    { value: 'sms', label: 'SMS' },
    { value: 'chat', label: 'CHAT' },
    { value: 'voice', label: 'VOICE' },
  ];

  const FAQTableLabels = [
    { title: 'FAQ', wide: true },
    { title: 'Status', notClickable: true },
    { title: 'Modality', notClickable: true },
    { title: 'Variations' },
    { title: 'Group' },
    { title: 'Edited By' },
    { title: 'Edited On' },
    { title: 'Submitted By' },
    { title: 'Submitted On' },
    { title: 'Actions', alignRight: true, notClickable: true },
  ];

  const getSortKey = label => {
    label = label.toUpperCase();
    if (label === 'EDITED BY') {
      return 'editedByKey';
    } else if (label === 'STATUS') {
      return 'statusKey';
    } else if (label === 'VARIATIONS') {
      return 'variationsCountKey';
    } else if (label === 'GROUP') {
      return 'groupKey';
    } else if (label === 'EDITED ON') {
      return 'lastEditKey';
    } else if (label === 'SUBMITTED BY') {
      return 'submittedByKey';
    } else if (label === 'SUBMITTED ON') {
      return 'submittedOnKey';
    } else if (label === 'FAQ') {
      return 'question';
    }
  };

  const indexOfLastRecord = currentPage * pageLimit;
  const indexOfFirstRecord = indexOfLastRecord - pageLimit;
  const currentPageRecords = FAQsData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  if (editClicked) {
    return <Redirect to="/knowledge-base/add-FAQ" />;
  } else {
    return (
      <div
        style={{
          width: '100%',
          borderLeft: `1px solid ${theme.palette.general.mediumGrey}`,
          ...props.style,
        }}
        key={rerenderKey}
      >
        <div className="SectionHeader_FAQs">
          <SectionHeader
            onChangeSearch={onChangeSearch}
            onClickSearch={onClickSearch}
            onClickReset={onClickReset}
            onSelectGroup={onSelectGroup}
            onSelectModality={onSelectModality}
            groupOptions={groupOptions}
            modalityOptions={modalityOptions}
            onSelectFilter={onSelectFilter}
            filterOptions={filterOptions}
            noSort
            //  noModality
          />
        </div>
        <div className="TablePage_FAQs">
          <Table
            title="Knowledge Base FAQs"
            labels={FAQTableLabels}
            data={currentPageRecords}
            onChangeSearch={onChangeSearch}
            onClickSearch={onClickSearch}
            onClickReset={onClickReset}
            onSelectFilter={onSelectFilter}
            onSubmitDelete={onSubmitDelete}
            onClickEdit={onClickEdit}
            filterOptions={filterOptions}
            getSortKey={getSortKey}
            defaultSortKey="lastEditKey"
            noSort
            noSearch
          />
          <PaginationPanel
            theme={theme}
            currentPage={currentPage}
            pageCount={Math.ceil(totalResults / pageLimit)}
            onClickFetchMore={onClickFetchMore}
            onClickFetchLess={onClickFetchLess}
            jumpToPageOptions={jumpToPageOptions}
            onChangeJumpToPage={onChangeJumpToPage}
          />
        </div>
      </div>
    );
  }
}
