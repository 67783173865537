import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import _, { set } from 'lodash';
import { Timer } from 'timer-node';
import Paper from '@material-ui/core/Paper';
import Avatar from 'react-avatar';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { default as AvatarX } from '@material-ui/core/Avatar';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Element, scroller as scroll } from 'react-scroll';

import PhoneIcon from '@material-ui/icons/Phone';
import PersonIcon from '@material-ui/icons/Person';
import AuthContext from 'contexts/AuthContext';
import { useModal, getUserGroups } from 'hooks';
import * as tagHelper from './Helpers/tagHelper.js';
import * as filterHelper from './Helpers/filterHelper.js';
import * as groupsAndUsersHelper from './Helpers/groupsAndUsersHelper.js';
import {
  inboxBtn,
  reviewBtn,
  assistBtn,
  allBtn,
  searchBtn,
} from './Helpers/topButtons.js';

import Messages from './Messages';
import { Conversation } from './Conversation';
import { TopNavigation } from './TopNavigation';
import { SearchContainer } from './SearchContainer';
import { ConvoActionMenu, ConvoActionSubMenu } from './Menu';
import { DialogueProperties } from './DialoguePropertiesContainer/index.js';
import { TriageQueue } from './TriageQueueContainer';
import { AgentBanner } from './AgentBanner';

import { default as CampaignFilterButton } from './CampaignFilter/CampaignFilterButton';

import { API, graphqlOperation } from 'aws-amplify';
import {
  handleDialogues,
  listUsers,
  searchMessages,
  convoTagByConvoId,
  listEscalations,
  notesByConvoId,
  listCampaigns,
  listChatbotGroups,
  websocketConnectionByConvoId,
  listDialogueNotess,
  byStatusNumber,
} from 'graphql/queries';

import {
  createChatbotConvoTag,
  updateChatbotConvoTag,
} from 'graphql/mutations';

import './Dialogues.scss';

import { environments } from 'services';
import { Button } from '@material-ui/core';
import { object } from '@storybook/addon-knobs';
import { Modal, ModalContent, Select } from 'stories';

import { store as GlobalStore } from 'contexts/GlobalStore';

import moment from 'moment-timezone';

const useStyles = makeStyles({
  opened: {
    display: 'block',
  },
  closed: {
    transform: 'scaleX(-1)',
    display: 'block',
  },
  tabsRoot: {
    flexGrow: 1,
    minHeight: '70px',
    height: '100%',
  },
  tabRoot: {
    flexGrow: 1,
    minHeight: '70px',
    height: '100%',
  },
});
const styles = theme => ({
  label: {
    color: '#FFF000',
  },
  indicator: {
    backgroundColor: '#FFF',
  },
});

export default function Dialogues(props) {
  const { isShowing, toggle } = useModal();
  const { getGroup } = getUserGroups();
  const theme = useTheme();
  const tokens = useContext(AuthContext);

  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;

  const [filteredConvoList, setFilteredConvoList] = useState({
    inbox: [],
    review: [],
    assisted: [],
    all: [],
    sr: [],
  });

  const [page, setPage] = useState(1);
  const [allowedToLoadMore, setAllowedToLoadMore] = useState(true);
  const [selectedConvo, setSelectedConvo] = useState('');
  const [prevSelectedConvo, setPrevSelectedConvo] = useState('');
  const [otherAgentChatting, setOtherAgentChatting] = useState(false);
  const [refreshVariable, setRefreshVariable] = useState(false);
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [showAddTag, setShowAddTag] = useState(false);
  const [tagUserInfo, setTagUserInfo] = useState({});
  const [searchMessageInput, setSearchMessageInput] = useState('');
  const [searchFieldInput, setSearchFieldInput] = useState('');
  const [selectedConvoTags, setSelectedConvoTags] = useState([]);
  const [selectedUserTags, setSelectedUserTags] = useState([]);
  const [isDialogueOpen, setDialogueOpen] = useState(false);
  const [escalationData, setEscalationData] = useState([]);
  const [searchForMessage, setSearchForMessage] = useState(null);
  const [dialogueProperties, setDialogueProperties] = useState({});
  const [keyedUsers, setKeyedUsers] = useState({});
  const [keyedUserGroups, setKeyedUserGroups] = useState({});
  const [keyedGroups, setKeyedGroups] = useState({});
  const [groupFolders, setGroupFolders] = useState({});
  const [liveAgentTimers, setLiveAgentTimers] = useState([]);
  const [convoNotes, setConvoNotes] = useState([]);
  const [convosWithNotesById, setConvosWithNotesById] = useState([]);
  const [anchorEl, setAnchorEl] = useState(false);
  const [subanchorEl, setSubAnchorEl] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filterAnchorEl2, setFilterAnchorEl2] = useState(null);
  const [filterAnchorEl3, setFilterAnchorEl3] = useState(null);
  const [filterAnchorEl4, setFilterAnchorEl4] = useState(null);
  const [filterAnchorEl5, setFilterAnchorEl5] = useState(null);
  const [updateBanner, setUpdateBanner] = useState(null);
  const [filterTag, setFilterTag] = useState('');
  const [resolving, setResolving] = useState(null);
  const [triggeredAddRule, setTriggeredAddRule] = useState(null);
  const [writeInboxSearch, setWriteInboxSearch] = useState(null);
  const [reRenderKey, setReRenderKey] = useState(null);
  const [lastClickedInd, setLastClickedInd] = useState(-1);
  const [triageTotal, setTriageTotal] = useState(0);
  const [assignedToMeCount, setAssignedToMeCount] = useState(0);
  const [updateNeedsReview, setUpdateNeedsReview] = useState(0);
  const [filterRoles, setFilterRoles] = useState([]);
  const [filterRoles2, setFilterRoles2] = useState([]);
  const [undergradStatus, setUndergradStatus] = useState(null);
  const [prospectiveStatus, setProspectiveStatus] = useState(null);
  const [filterModalityType, setFilterModalityType] = useState(null);
  const [unassignUserOptions, setUnassignUserOptions] = useState([]);
  const [unassignGroupOptions, setUnassignGroupOptions] = useState([]);
  const [tagsToUnassign, setTagsToUnassign] = useState({
    userTag: null,
    groupTag: null,
  });

  const [counts, setCounts] = useState({
    inbox: 0,
    review: 0,
    assisted: 0,
    all: 0,
    sr: 0,
  });

  const liveAgentTimerDuration = 300;

  const [clickedConvo, setClickedConvo] = useState(null);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [availableCampaigns, setAvailableCampaigns] = useState([]);
  const [availableEscalations, setAvailableEscalations] = useState([]);
  const [msgsInReview, setMsgsInReview] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isConvoListLoading, setIsConvoListLoading] = useState(false);

  const [noteQueryString, setNoteQueryString] = useState('');
  const [subMenuType, setSubMenuType] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [allGroups, setAllGroups] = useState([]);

  // List Filter = Triage Queue Selection
  const [listFilter, setListFilter] = useState(null);
  const [listFilterName, setListFilterName] = useState('All');

  // Folder Filter = Triage Queue, Folder Selection
  const [folderFilter, setFolderFilter] = useState(null);

  //List View Filter = All/Inbox/Review/Assited
  const [listViewFilter, setListViewFilter] = useState('unseenBy true');
  const [listViewFilterName, setListViewFilterName] = useState('Inbox');

  // Convo Filter = Filter Selection from Search Bar
  const [convoFilter, setConvoFilter] = useState(null);
  const [convoUIFilter, setConvoUIFilter] = useState(null);
  const [convoUIFilter2, setConvoUIFilter2] = useState(null);
  const [convoUIFilter3, setConvoUIFilter3] = useState(null);
  const [convoFilterExclude, setConvoFilterExclude] = useState([]);

  const [myGroups, setMyGroups] = useState([]);
  const [myReviewTags, setMyReviewTags] = useState([]);
  const [tab, setTab] = useState(0);
  const [customTags, setCustomTags] = useState([]);

  const [inboxSearch, setInboxSearch] = useState('');
  const [inboxSearchIgnoreCharMin, setInboxSearchIgnoreCharMin] = useState(
    false
  );
  const [inboxSearchKeywordMatches, setInboxSearchKeywordMatches] = useState(
    false
  );

  const [topButtons, setTopButtons] = useState([
    inboxBtn,
    reviewBtn,
    assistBtn,
    allBtn,
    searchBtn,
  ]);
  const [showSearchResultsTab, setShowSearchResultsTab] = useState(false);
  const [previousSearchTerm, setPreviousSearchTerm] = useState('');

  const [
    inboxSearchKeywordMatchCount,
    setInboxSearchKeywordMatchCount,
  ] = useState(0);

  const [allDialogueCount, setAllDialogueCount] = useState(0);
  const [fetchConvoCounter, setFetchConvoCounter] = useState(0);

  const [allConvos, setAllConvos] = useState({
    inbox: { page: 0, total: 0, data: [] },
    review: { page: 0, total: 0, data: [] },
    assisted: { page: 0, total: 0, data: [] },
    all: { page: 0, total: 0, data: [] },
    sr: { page: 0, total: 0, data: [] },
  });

  // campaign exclusivity switch
  const [campaignSwitch, setCampaignSwitch] = useState(null);
  const [campaignBridgeDetected, setCampaignBridgeDetected] = useState(false);

  // kb not found modal
  const [isShowingCustomAlertModal, setShowCustomAlertModal] = useState(false);

  const handleOpenCustomAlertModal = () => {
    setShowCustomAlertModal(true);
  };

  const handleCloseCustomAlertModal = () => {
    setShowCustomAlertModal(false);
  };

  const searchInboxRef = React.useRef(null);

  const [selectedCampaign, setSelectedCampaign] = useState({
    id: '',
    status: 0,
  });

  useEffect(() => {
    fetchConversations(true);
    fetchCampaigns();
    fetchEscalations();
    getGroups();
    getCustomTags();
    getUsers();
    getConvosWithNotes();
    if (tokens.env === 'truedev') {
      detectCampaignBridge();
    }
  }, []);

  useEffect(() => {
    if (inboxSearch === '') {
      if (searchInboxRef.current.value !== '') {
        setPreviousSearchTerm(searchInboxRef.current.value);
        setWriteInboxSearch('');
      }
    } else {
      //if (inboxSearch !== previousSearchTerm) {
      let newFilteredConvos = JSON.parse(JSON.stringify(allConvos));
      newFilteredConvos.sr = { page: 0, total: 0, data: [] };
      setAllConvos(newFilteredConvos);
      searchAndUpdate(inboxSearch);
      /*} else {
        setSelectedButton(searchBtn);
        changeListViewFilter(searchBtn.filter, searchBtn.name);
        setTab(topButtons.length - 1);
      }*/
    }
  }, [inboxSearch]);

  const batchGetDialogueCount = async () => {
    let tag = listFilter ? listFilter : null;
    let grp = listFilter
      ? listFilter.substring(listFilter.indexOf(' '))
      : 'true';

    let queries = [];
    let mustHaves = ['unseenBy ', 'needsReview ', 'previouslyAssisted '];

    let appendTags = [];

    if (folderFilter) {
      appendTags.push(folderFilter);
    }

    if (convoFilter) {
      appendTags.push(convoFilter);
    }

    if (tag) {
      mustHaves.push(tag);
    }

    for (var i = 0; i < mustHaves.length; ++i) {
      let t = [
        mustHaves[i].indexOf(grp) === -1 ? mustHaves[i] + grp : mustHaves[i],
      ].concat(appendTags);

      queries.push({
        tags: t,
      });
    }

    if (!tag) {
      queries.push({
        name: 'all',
      });
    }

    console.log('filterRoles', filterRoles);

    let resp = await API.graphql(
      graphqlOperation(searchMessages, {
        operation: 'batchGetDialogueTotal',
        payload: JSON.stringify({
          queries: queries,
          botType: 'sunny',
          type: filterModalityType,
          rolesToGet: filterRoles,
          rolesToGet2: filterRoles2,
          newUndergradStatus: undergradStatus,
          newProspectiveStatus:
            swapProspectiveStatus[prospectiveStatus] || null,
          useWildcardQuery: tag || listFilter || folderFilter ? false : true,
        }),
      })
    );

    let dialogueTotal = JSON.parse(resp.data.searchMessages);

    console.log('DIALOGUE TOTAL: ', dialogueTotal);
    //console.log("!!! queries are", queries);
    console.log('!!! dialogueTotal is', dialogueTotal);
    let counts = {
      inbox: dialogueTotal[0].count,
      review: dialogueTotal[1].count,
      assisted: dialogueTotal[2].count,
      all: dialogueTotal[3].count,
    };

    return counts;
  };

  const getDialogueCount = async tag => {
    let resp = await API.graphql(
      graphqlOperation(searchMessages, {
        operation: 'getDialogueTotal',
        payload: JSON.stringify({
          botType: 'sunny',
          tag: tag,
        }),
      })
    );

    let dialogueTotal = JSON.parse(resp.data.searchMessages)[0].count;
    if (dialogueTotal > 9999) {
      dialogueTotal = '9999+';
    }

    return dialogueTotal;
  };

  const modalityTypeFilter = convo => {
    if (filterModalityType === 'sms') {
      if (convo.sms !== null) {
        return true;
      } else {
        return false;
      }
    } else if (filterModalityType === 'voice') {
      if (convo.voice !== null) {
        return true;
      } else {
        return false;
      }
    } else if (filterModalityType === 'web') {
      if (convo.auth !== null) {
        return true;
      } else {
        return false;
      }
    }
  };

  const searchAndUpdate = async (term, wasPreSelect) => {
    setShowSearchResultsTab(true);
    setSelectedButton(searchBtn);
    changeListViewFilter(searchBtn.filter, searchBtn.name);
    setTab(topButtons.length - 1);
    setIsLoading(true);

    let newFilteredConvos;
    let combinedData;

    if (campaignSwitch) {
      loadCampaign(campaignSwitch, false, term);
    } else {
      let searchResp = await messageSearch(term);
      newFilteredConvos = JSON.parse(JSON.stringify(allConvos));

      // Handles Search

      let resp = await API.graphql(
        graphqlOperation(handleDialogues, {
          operation: 'listAllConvos',
          searchTerm: term,
          includeConvos: searchResp,
        })
      );
      combinedData = JSON.parse(resp.data.handleDialogues);

      if (filterModalityType !== null) {
        combinedData = combinedData.filter(modalityTypeFilter);
      }

      newFilteredConvos.sr = { page: 0, total: 0, data: combinedData };

      setAllConvos(newFilteredConvos);

      if (wasPreSelect) {
        setSelectedConvo(combinedData[0]);
        setDialogueOpen(true);
      }

      setIsLoading(false);
    }
  };

  const [selectedButton, setSelectedButton] = useState(topButtons[0]);
  const classes = useStyles();

  const getConvosWithNotes = async () => {
    let resp = await API.graphql(graphqlOperation(listDialogueNotess));
    let result = resp.data.listDialogueNotess.items.map(note => note.convoId);

    setConvosWithNotesById(result);
  };

  const getGroups = async () => {
    let fullResp = await groupsAndUsersHelper.getGroups(tokens);
    await getGroupCount(fullResp.gArr);
    setMyReviewTags(fullResp.myGroupsx);
    setKeyedGroups(fullResp.kG);
    setAllGroups(fullResp.gArr);
  };

  const getGroupCount = async grps => {
    let all = [];

    for (var i = 0; i < grps.length; ++i) {
      all.push({
        tags: ['groupAssigned ' + grps[i].id],
      });
    }

    let resp = await API.graphql(
      graphqlOperation(searchMessages, {
        operation: 'batchGetDialogueTotal',
        payload: JSON.stringify({
          queries: all,
          botType: 'sunny',
        }),
      })
    );

    let groupTotals = JSON.parse(resp.data.searchMessages);

    for (var i = 0; i < grps.length; ++i) {
      grps[i].count = groupTotals[i].count;
    }

    let myCount = await API.graphql(
      graphqlOperation(searchMessages, {
        operation: 'batchGetDialogueTotal',
        payload: JSON.stringify({
          queries: [
            {
              tags: [
                'agentAssigned ' + tokens.asurite,
                'previouslyAssisted ' + tokens.asurite,
              ],
            },
          ],
          botType: 'sunny',
          useOrQuery: true,
        }),
      })
    );

    setAssignedToMeCount(JSON.parse(myCount.data.searchMessages)[0].count);

    return;
  };

  const updateGroups = () => {
    getGroups();
  };

  const fetchCampaignsFromDB = status => {
    let all = [];
    let token = null;
    let retries = 0;
    return new Promise(async function tryagain(resolve, reject) {
      let runPay = {
        status: status,
      };

      if (token) {
        runPay.nextToken = token;
      }

      let currRun = await API.graphql(graphqlOperation(byStatusNumber, runPay));

      let resp = currRun.data.byStatusNumber;
      all = all.concat(resp.items);

      if (resp.nextToken && ++retries < 5) {
        token = resp.nextToken;
        tryagain(resolve, reject);
      } else {
        resolve(all);
      }
    });
  };

  const fetchCampaigns = async () => {
    let campsActive = await fetchCampaignsFromDB(8);
    let campsEnded = await fetchCampaignsFromDB(9);

    let listAllCampaigns = campsActive.concat(campsEnded);

    setAvailableCampaigns(listAllCampaigns);
    setAllCampaigns(listAllCampaigns);
  };

  const getUsers = async () => {
    let fullResp = await groupsAndUsersHelper.getUsers();
    setAllUsers(fullResp.allU);
    setKeyedUsers(fullResp.kU);
    setKeyedUserGroups(fullResp.kUG);
    if (tokens.env !== 'prod') {
      setApprovedUsers(fullResp.tmp);
    }
  };

  let swapProspectiveStatus = {
    'first year': 'OPFIRST',
    graduate: 'OPGRAD',
    transfer: 'OPTRANSFER',
    other: 'OPOTHER',
  };

  const getByTag = async (tag, page) => {
    //add ifs here
    let rolesToGet = [];
    let rolesToGet2 = [];

    let grp = listFilter
      ? listFilter.substring(listFilter.indexOf(' '))
      : 'true';

    if (folderFilter && folderFilter != 'read') {
      tag += '|' + folderFilter + ' ' + grp;
    }

    if (
      convoFilter &&
      convoFilter != 'read' &&
      convoFilter !== 'academicData'
    ) {
      tag += '|' + convoFilter + ' ' + grp;
    }

    if (filterRoles && filterRoles.length > 0) {
      rolesToGet = filterRoles;
    }

    if (filterRoles2 && filterRoles2.length > 0) {
      rolesToGet2 = filterRoles2;
    }

    console.log('!!!p prospective status is', prospectiveStatus);

    // Handles Initial Pull in Inbox

    let operation_new_text = rolesToGet.length > 0 ? 'listAllConvos' : 'pullByTag';
    operation_new_text = filterModalityType ? operation_new_text + "_" + filterModalityType : operation_new_text;

    console.log({
      operation: operation_new_text,
      searchTerm: rolesToGet.length > 0 ? null : tag,
      rolesToGet: rolesToGet,
      rolesToGet2: rolesToGet2,
      newUndergradStatus: undergradStatus,
      newProspectiveStatus: swapProspectiveStatus[prospectiveStatus] || null,
      startFrom: page ? page : 0
    });

    let resp = await API.graphql(
      graphqlOperation(handleDialogues, {
        operation: operation_new_text,
        searchTerm: tag,
        rolesToGet: rolesToGet,
        rolesToGet2: rolesToGet2,
        newUndergradStatus: undergradStatus,
        newProspectiveStatus: swapProspectiveStatus[prospectiveStatus] || null,
        startFrom: page ? page : 0
      })
    );
    let combinedData = JSON.parse(resp.data.handleDialogues);

    if (filterModalityType !== null) {
      combinedData = combinedData.filter(modalityTypeFilter);
    }

    if (
      (convoFilter && convoFilter == 'read') ||
      (folderFilter && folderFilter == 'read')
    ) {
      let unreadTag = 'unseenBy ' + grp;
      combinedData = combinedData.filter(function(item) {
        return !(item.tags && item.tags.includes(unreadTag));
      });
    }

    console.log('DATA: ', tag, combinedData);

    if(convoFilterExclude.length > 0){
      combinedData = combinedData.filter(item => {
        return !convoFilterExclude.some(excludedTag => {
          return item.tags.some(tag => tag.includes(excludedTag));
        });
      });
    }

    return combinedData;
  };

  const getAll = async (page, overrideAll) => {
    //add ifs here
    let resp = null;
    let tag = listFilter ? listFilter : null;
    let grp = listFilter
      ? listFilter.substring(listFilter.indexOf(' '))
      : 'true';

    if (folderFilter && folderFilter != 'read') {
      tag = folderFilter + ' ' + grp;
    }

    if (
      tag == null &&
      convoFilter &&
      convoFilter != 'read' &&
      convoFilter !== 'academicData'
    ) {
      tag = convoFilter + ' ' + grp;
    } else if (convoFilter && convoFilter != 'read') {
      tag += '|' + convoFilter + ' ' + grp;
    }

    // Handles the All Page

    let operation_new_text = 'listAllConvos';
    operation_new_text = filterModalityType ? operation_new_text + "_" + filterModalityType : operation_new_text;

    resp = await API.graphql(
      graphqlOperation(handleDialogues, {
        options: {
          fetchPolicy: 'no-cache',
        },
        operation: operation_new_text,
        searchTerm: tag,
        startFrom: page,
        rolesToGet: filterRoles,
        newUndergradStatus: undergradStatus,
        newProspectiveStatus: swapProspectiveStatus[prospectiveStatus] || null,
        rolesToGet2: filterRoles2
      })
    );

    let combinedData = JSON.parse(resp.data.handleDialogues);

    if (filterModalityType !== null) {
      combinedData = combinedData.filter(modalityTypeFilter);
    }

    if (
      (convoFilter && convoFilter == 'read') ||
      (folderFilter && folderFilter == 'read')
    ) {
      let unreadTag = 'unseenBy ' + grp;
      combinedData = combinedData.filter(function(item) {
        return (item.tags && !item.tags.includes(unreadTag)) || false;
      });
    }

    return combinedData;
  };

  const fetchConversations = async () => {
    console.log('fetch');

    setIsLoading(true);

    setSelectedCampaign(prevState => ({
      ...prevState,
      id: '',
      status: 0,
    }));

    let tag = listFilter ? listFilter : null;
    let grp = listFilter
      ? listFilter.substring(listFilter.indexOf(' '))
      : 'true';

    let [
      inboxConvos,
      needsReviewConvos,
      prevAssisConvos,
      allConvos,
      counts,
    ] = await Promise.all([
      getByTag('unseenBy ' + grp),
      getByTag('needsReview ' + grp),
      getByTag('previouslyAssisted ' + grp),
      tag ? getByTag(tag) : getAll(0),
      batchGetDialogueCount(),
    ]);

    setAllConvos({
      sr: { page: 0, total: 0, data: [] },
      inbox: {
        page: 0,
        total:
          inboxConvos.length > counts.inbox ? inboxConvos.length : counts.inbox,
        allowedToLoadMore: inboxConvos.length > 0,
        data: inboxConvos,
      },
      review: {
        page: 0,
        total:
          needsReviewConvos.length > counts.review
            ? needsReviewConvos.length
            : counts.review,
        allowedToLoadMore: needsReviewConvos.length > 0,
        data: needsReviewConvos,
      },
      assisted: {
        page: 0,
        total:
          prevAssisConvos.length > counts.assisted
            ? prevAssisConvos.length
            : counts.assisted,
        allowedToLoadMore: prevAssisConvos.length > 0,
        data: prevAssisConvos,
      },
      all: {
        page: 0,
        total: counts.all,
        allowedToLoadMore: allConvos.length > 0,
        data: allConvos,
      },
    });

    let preSelectId = null;

    try {
      preSelectId = window.location.href.split('=')[1];
    } catch (e) {}

    if (preSelectId) {
      let fullQ = 'convoId:' + preSelectId;
      setWriteInboxSearch(fullQ);
      searchAndUpdate(fullQ, true);
    }
    if (!tag) setTriageTotal(counts.all);
    setAllowedToLoadMore(true);
    setIsLoading(false);
    setIsConvoListLoading(false);
  };

  const inspectNextPageInQuery = async listName => {
    return new Promise(async function tryagain(resolve, reject) {
      console.log('!!! listName', listName);

      let tagValue = getTagValueForList(listName);
      let listClone = _.clone(allConvos);
      let currData = listClone[selectedButton.id];

      let runningList = [];
      let resp = [];
      let prevLength = 1;

      for (let x = 1; runningList.length < 11; x++) {
        if (listName === 'all') {
          resp = await getAll(x, true);
          if (resp.length === 0) {
            break;
          }
          runningList = runningList.concat(resp);
        } else {
          resp = await getByTag(tagValue, x);
          if (resp.length === 0) {
            break;
          }
          runningList = runningList.concat(resp);
        }

        /*
          Isolate UI list filtering and account for it in this function
        */
      }

      resolve(runningList);
    });
  };

  const getTagValueForList = listName => {
    let tagValue = '';

    let tag = listFilter ? listFilter.substring(listFilter.indexOf(' ')) : null;

    if (listName === 'inbox') {
      tagValue = tag ? `unseenBy ${tag}` : 'unseenBy true';
    } else if (listName === 'review') {
      tagValue = tag ? `needsReview ${tag}` : 'needsReview true';
    } else if (listName === 'assisted') {
      tagValue = tag ? `previouslyAssisted ${tag}` : 'previouslyAssisted true';
    } else if (listName === 'all') {
      tagValue = 'all';
    }

    console.log('!!%! tagValue is', tagValue);

    return tagValue;
  };

  const updateReviewTags = async (type, tag) => {
    console.log('fetch');
    if (!isLoading) {
      let allConvosNew = Object.assign({}, allConvos);

      /*let isConvoInReview = allConvosNew.review.data.filter((convo, index) => {
        if (convo.convoId === selectedConvo.convoId) return true;
      });
      let otherConvos = allConvosNew.review.data.filter((convo, index) => {
        if (convo.convoId !== selectedConvo.convoId) return true;
      });
      let isConvoInAssisted = allConvosNew.assisted.data.filter(
        (convo, index) => {
          if (convo.convoId === selectedConvo.convoId) return true;
        }
      );*/

      let listNames = Object.keys(allConvosNew);

      listNames.forEach(listName => {
        allConvosNew[listName].data = allConvosNew[listName].data.map(
          (convo, index) => {
            if (convo.convoId === selectedConvo.convoId) {
              if (type === 'add') {
                convo.tags.push(tag);
              } else if (type === 'remove') {
                let tagIndex = -1;
                //console.log("All",convo.tags)
                convo.tags.find((tag, index) => {
                  if (tag.includes('needsReview')) {
                    tagIndex = index;
                  }
                });
                if (tagIndex > -1) {
                  convo.tags.splice(tagIndex, 1);
                }
              }
            }

            return convo;
          }
        );
      });

      setAllConvos(allConvosNew);
      setIsLoading(false);
    }
  };

  const updateReviewCounts = async type => {
    console.log('fetch');
    if (!isLoading && msgsInReview.length < 2) {
      /*   setIsLoading(true);


         }
         let tag = listFilter ? listFilter : null;
         let grp = listFilter
           ? listFilter.substring(listFilter.indexOf(" "))
           : "true";

         let [
           needsReviewConvos,
           prevAssistConvos,
           counts,
         ] = await Promise.all([
           getByTag("needsReview " + grp),
           getByTag("previouslyAssisted " + grp),
           batchGetDialogueCount(),
         ]);*/
      let allConvosNew = Object.assign({}, allConvos);
      let isConvoInReview = allConvosNew.review.data.filter((convo, index) => {
        if (convo.convoId === selectedConvo.convoId) return true;
      });
      let otherConvos = allConvosNew.review.data.filter((convo, index) => {
        if (convo.convoId !== selectedConvo.convoId) return true;
      });
      let isConvoInAssisted = allConvosNew.assisted.data.filter(
        (convo, index) => {
          if (convo.convoId === selectedConvo.convoId) return true;
        }
      );

      setAllConvos({
        sr: allConvosNew.sr,
        inbox: allConvosNew.inbox,
        review: Object.assign(allConvosNew.review, {
          ...allConvosNew.review,
          total:
            type === 'add'
              ? allConvosNew.review.total +
                (isConvoInReview.length === 1 ? 0 : 1)
              : allConvosNew.review.total - isConvoInReview.length,
          data:
            type === 'add'
              ? isConvoInReview.length === 0
                ? allConvosNew.review.data.unshift(selectedConvo) &&
                  allConvosNew.review.data
                : allConvosNew.review.data
              : otherConvos,
        }),
        assisted: Object.assign(allConvosNew.assisted, {
          ...allConvosNew.assisted,
          total:
            type === 'remove'
              ? allConvosNew.assisted.total +
                (isConvoInAssisted.length === 1 ? 0 : 1)
              : allConvosNew.assisted.total,
          data:
            type === 'remove'
              ? isConvoInAssisted.length === 0
                ? allConvosNew.assisted.data.unshift(selectedConvo) &&
                  allConvosNew.assisted.data
                : allConvosNew.assisted.data
              : allConvosNew.assisted.data,
        }),
        all: allConvosNew.all,
      });
      setIsLoading(false);
      /*
       assisted: Object.assign(
              allConvosNew.assisted,
              {
                ...allConvosNew.assisted,
                total: allConvosNew.assisted.total+1
              }),

        let preSelectId = null;

        try {
          preSelectId = window.location.href.split("=")[1];
        } catch (e) {}

        if (preSelectId) {
          let fullQ = "convoId:" + preSelectId;
          setWriteInboxSearch(fullQ);
          searchAndUpdate(fullQ, true);
        }
        if (!tag) setTriageTotal(counts.all);
        setAllowedToLoadMore(true);
        setIsLoading(false);
        setIsConvoListLoading(false);
        }
      */
    }
  };

  const createAllConvoData = convos => {
    return {
      sr: { page: 0, total: 0, data: [] },
      inbox: {
        page: 0,
        total: convos.totals.inbox,
        data: convos.data.inbox.data,
        allowedToLoadMore: convos.data.inbox.allowedToLoadMore,
      },
      review: {
        page: 0,
        total: convos.totals.review,
        data: convos.data.review.data,
        allowedToLoadMore: convos.data.review.allowedToLoadMore,
      },
      assisted: {
        page: 0,
        total: convos.totals.assisted,
        data: convos.data.assisted.data,
        allowedToLoadMore: convos.data.assisted.allowedToLoadMore,
      },
      all: {
        page: 0,
        total: convos.totals.all,
        data: convos.data.all.data,
        allowedToLoadMore: convos.data.all.allowedToLoadMore,
      },
    };
  };

  let baseURL = `http://localhost:9750`;

  const loadCampaign = async (campaign, startFrom, searchFor) => {
    setAllowedToLoadMore(false);
    setIsConvoListLoading(true);

    if (!startFrom) {
      setIsLoading(true);
    }

    let tag = '';
    let grp = listFilter
      ? listFilter.substring(listFilter.indexOf(' '))
      : 'true';

    if (folderFilter && folderFilter != 'read') {
      tag += '|' + folderFilter + ' ' + grp;
    }

    if (convoFilter) {
      tag += '|' + convoFilter + ' ' + grp;
    }

    if (!folderFilter && !convoFilter) {
      tag += grp;
    }

    const prodCampaignList = [
      '10100cb6-cf5e-ec87-4407-e4a5d404274a',
      'b495bb93-80bd-4acf-d09f-eba4f94c12d9',
      '8e293ebb-9178-c469-5c25-1c8c16e9afab',
      '99013da7-d3e4-a44a-9a1b-6b73b32662f0',
      'd20c1b99-a2e6-1aae-d3e0-dbe6f600c52f',
      'df5ee942-8784-4b0b-1ef0-350bdcae1d2e',
    ];

    if (prodCampaignList.includes(campaign.id)) {
      let options = `/prodCampaign?id=${
        campaign.id
      }&tag=${tag}&filterModalityType=${filterModalityType}&filterRoles=${filterRoles}&filterRoles2=${filterRoles2}&undergradStatus=${undergradStatus}&prospectiveStatus=${swapProspectiveStatus[
        prospectiveStatus
      ] || null}`;

      if (startFrom) {
        options += `&startFrom=${
          allConvos[selectedButton.id].data.length
        }&list=${selectedButton.id}`;
      }

      if (searchFor) {
        options += `&searchFor=${searchFor}`;
      }

      fetch(baseURL + options)
        .then(response => {
          return response.json();
        })
        .then(function(data) {
          if (startFrom) {
            let listClone = _.clone(allConvos);
            let currData = listClone[selectedButton.id];

            currData.data = currData.data.concat(
              data.data[selectedButton.id].data
            );
            currData.allowedToLoadMore =
              data.data[selectedButton.id].allowedToLoadMore;

            setAllConvos({
              ...listClone,
              [selectedButton.id]: currData,
            });
          } else {
            if (searchFor) {
              let listClone = _.clone(allConvos);

              setAllConvos({
                ...listClone,
                sr: {
                  page: 0,
                  total: data.totals.sr,
                  data: data.data.sr.data,
                  allowedToLoadMore: data.data.sr.allowedToLoadMore,
                },
              });
            } else {
              setAllConvos(createAllConvoData(data));
            }
          }

          setAllowedToLoadMore(true);
          setIsConvoListLoading(false);
          if (!startFrom) {
            setIsLoading(false);
          }
        })
        .catch(err => {
          console.log('Bridge broken:', err);
          setAllowedToLoadMore(true);
          setIsConvoListLoading(false);
          if (!startFrom) {
            setIsLoading(false);
          }
        });
    } else {
      let searchTerm = {
        id: campaign.id,
        tag: tag,
        filterModalityType: filterModalityType,
        filterRoles: filterRoles,
        filterRoles2: filterRoles2,
        undergradStatus: undergradStatus,
        prospectiveStatus: swapProspectiveStatus[prospectiveStatus] || null,
      };

      if (startFrom) {
        searchTerm.startFrom = allConvos[selectedButton.id].data.length;
        searchTerm.list = selectedButton.id;
      }

      if (searchFor) {
        searchTerm.searchFor = searchFor;
      }

      let resp = { data: { handleDialogues: null } };

      try {
        resp = await API.graphql(
          graphqlOperation(handleDialogues, {
            operation: 'pullCampaign',
            searchTerm: JSON.stringify(searchTerm),
          })
        );
      } catch (error) {
        console.log('Campaign Pull Error:', error);
        setAllowedToLoadMore(true);
        setIsConvoListLoading(false);
        if (!startFrom) {
          setIsLoading(false);
        }
      }

      let data = JSON.parse(resp.data.handleDialogues);

      if (data !== null) {
        if(data.campaignData) {
          setSelectedCampaign(prevState => ({
            ...prevState,
            ...data.campaignData,
          }));
        }

        if (startFrom) {
          let listClone = _.clone(allConvos);
          let currData = listClone[selectedButton.id];

          currData.data = currData.data.concat(
            data.data[selectedButton.id].data
          );
          currData.allowedToLoadMore =
            data.data[selectedButton.id].allowedToLoadMore;

          setAllConvos({
            ...listClone,
            [selectedButton.id]: currData,
          });
        } else {
          if (searchFor) {
            let listClone = _.clone(allConvos);

            setAllConvos({
              ...listClone,
              sr: {
                page: 0,
                total: data.totals.sr,
                data: data.data.sr.data,
                allowedToLoadMore: data.data.sr.allowedToLoadMore,
              },
            });
          } else {
            setAllConvos(createAllConvoData(data));
          }
        }

        setAllowedToLoadMore(true);
        setIsConvoListLoading(false);
        if (!startFrom) {
          setIsLoading(false);
        }
      }
    }
  };

  const loadMoreConvos = async () => {
    setAllowedToLoadMore(false);
    setIsConvoListLoading(true);
    let listClone = _.clone(allConvos);
    let currData = listClone[selectedButton.id];

    let resp = [];
    let tag = listFilter ? listFilter.substring(listFilter.indexOf(' ')) : null;

    let tagValue = getTagValueForList(selectedButton.id);

    currData.page = currData.page + 1;

    if (selectedButton.id === 'all') {
      resp = await getAll(currData.page);
    } else {
      resp = await getByTag(tagValue, currData.page);
    }

    if (selectedButton.id === 'inbox') {
      resp = await getByTag(
        tag ? `unseenBy ${tag}` : 'unseenBy true',
        ++currData.page
      );
    } else if (selectedButton.id === 'review') {
      resp = await getByTag(
        tag ? `needsReview ${tag}` : 'needsReview true',
        ++currData.page
      );
      // window.location.reload();
    } else if (selectedButton.id === 'assisted') {
      resp = await getByTag(
        tag ? `previouslyAssisted ${tag}` : 'previouslyAssisted true',
        ++currData.page
      );
    } else if (selectedButton.id === 'all') {
      resp = await getAll(++currData.page);
    }

    currData.allowedToLoadMore = resp.length > 0;

    let keyedConvos = {};
    for (var i = 0; i < currData.data.length; ++i) {
      keyedConvos[currData.data[i].id] = true;
    }

    for (var i = 0; i < resp.length; ++i) {
      if (!keyedConvos[resp[i].id]) {
        currData.data.push(resp[i]);
      }
    }

    setAllConvos({
      ...listClone,
      [selectedButton.id]: currData,
    });

    setAllowedToLoadMore(true);
    setIsConvoListLoading(false);
  };

  const filterConvosByTag = (tagString, excludeTags = []) => {
    handleFilterMenuClose();
    setConvoFilterExclude(excludeTags);
    setConvoFilter(tagString);
    setConvoUIFilter(null);
    setConvoUIFilter2(null);
  };

  const filterConvosByRole = roles => {
    if (roles.includes('ENROLLED')) {
      setConvoUIFilter('currentStudent');
      setProspectiveStatus(null);
    } else {
      setConvoUIFilter('prospectiveStudent');
    }
    if (roles[0] && filterRoles) {
      if (filterRoles.includes(roles[0])) {
        if (roles[1]) {
          if (filterRoles.includes(roles[1])) {
            // don't update
          } else {
            setFilterRoles(roles);
          }
        }
      } else {
        setFilterRoles(roles);
      }
    }
    setConvoUIFilter2(null);
    handleFilterMenuClose3();
    //  handleFilterMenuClose5();
  };

  const filterConvosByRole2 = roles2 => {
    if (roles2.includes('UGS')) {
      setConvoUIFilter('underGraduate');
      setFilterRoles2(roles2);
    } else if (roles2.includes('GS')) {
      setConvoUIFilter('academicData');
      setFilterRoles2(roles2);
      handleFilterMenuClose4();
    } else if (roles2.length < 1) {
      setConvoUIFilter(null);
    }
    setConvoUIFilter2(null);

    handleFilterMenuClose3();
    //   handleFilterMenuClose5();
  };

  const filterConvosByUndergradStatus = string => {
    handleFilterMenuClose5();
    setUndergradStatus(string);
    setConvoUIFilter(null);
    setConvoUIFilter2(null);
  };
  /*
  const filterConvosByProspectiveStatus = roles3 => {
    if (roles3.includes('OPFIRST')) {
      setConvoUIFilter3('freshman');
      setProspectiveStatus(roles3);
    } else if (roles3.includes('OPTRANSFER')) {
      setConvoUIFilter3('transfer');
      setProspectiveStatus(roles3);
    } else if (roles3.includes('OPGRAD')) {
      setConvoUIFilter3('graduate');
      setProspectiveStatus(roles3);
    } else if (roles3.includes('OPTRANSFER')) {
      setConvoUIFilter3('transfer');
      handleFilterMenuClose5();
      setProspectiveStatus(roles3);
    } else if (roles3.length < 1) {
      setConvoUIFilter3(null);
    }
  };
  */

  const filterConvosByProspectiveStatus = string => {
    //allInfo.roles.push('OPGRAD');
    handleFilterMenuClose5();
    setProspectiveStatus(string);
    setConvoUIFilter(null);
    setConvoUIFilter2(null);
    setUndergradStatus(null);
    setFilterRoles2(null);
  };

  const filterConvosByModality = type => {
    setConvoUIFilter(type);
    setConvoUIFilter2(null);
    setFilterModalityType(type);
    handleFilterMenuClose2();
  };

  const filterConvosByLevel = type => {
    setConvoUIFilter(null);
    setFilterModalityType(null);
    setConvoUIFilter2(type);
    handleFilterMenuClose4();
  };

  const fetchEscalations = async () => {
    let escalationNames = await groupsAndUsersHelper.getEscalations(tokens);
    setEscalationData(escalationNames);
  };

  const fetchMessageNotes = async id => {
    let payload = { convoId: id };

    let resp = await API.graphql(
      graphqlOperation(searchMessages, {
        operation: 'listNotesByConvoId',
        payload: JSON.stringify(payload),
      })
    );

    let notes = JSON.parse(resp.data.searchMessages);
    setConvoNotes(notes);
  };

  const getCustomTags = async () => {
    let resp = await API.graphql(
      graphqlOperation(searchMessages, {
        operation: 'queryForTags',
        payload: JSON.stringify({}),
      })
    );

    setCustomTags(JSON.parse(resp.data.searchMessages));
  };

  const modifyActiveConvos = (item, pageName) => {
    dispatchGlobal({
      type: 'setShowRefreshTokenMessage',
      showRefreshTokenMessage: '',
    });

    if (item) {
      console.log('IS ITEM: ', item);
      if (pageName) {
        let ind = 0;
        for (var i = 0; i < topButtons.length; ++i) {
          if (topButtons[i].id === pageName) {
            ind = i;
          }
        }
        handleTabChange(null, ind + '');
      }

      let newConvo = null;

      if (filterModalityType === 'sms') {
        newConvo = {
          ...item,
          ...item['sms'],
          showConvo: 'sms',
        };
      } else if (filterModalityType === 'web') {
        newConvo = {
          ...item,
          ...item['auth'],
          showConvo: 'auth',
        };
      } else if (filterModalityType === 'voice') {
        newConvo = {
          ...item,
          ...item['voice'],
          showConvo: 'voice',
        };
      }

      setDialogueOpen(true);

      if (newConvo === null) {
        setSelectedConvo(item);
      } else {
        setSelectedConvo(newConvo);
      }

      if (newConvo !== null) {

        let convosClone = _.clone(allConvos);
        let foundConvo = null;

        for (var key in convosClone) {
          for (let i = convosClone[key].data.length - 1; i >= 0; i--) {
            if (convosClone[key].data[i].id === item.id) {
              convosClone[key].data[i] = newConvo;
              foundConvo = true;
              break;
            }
          }
          if (foundConvo) break;
        }

        if (foundConvo) {
          setAllConvos(convosClone);
        }
      }

      return;
    } else {
      console.log('IS LOOP');
      let convosClone = _.clone(allConvos);
      let foundConvo = null;

      const fallbackMessageType = 'sms';

      for (const keyName in convosClone) {
        if (!convosClone[keyName].data) {
          // Early return if no data
          continue;
        }

        for (
          let messageIndex = convosClone[keyName].data.length - 1;
          messageIndex >= 0;
          messageIndex--
        ) {
          let messageData = convosClone[keyName].data[messageIndex];
          if (messageData.id === selectedConvo.id) {
            if (messageData['undefined'] && !messageData[fallbackMessageType]) {
              // Patch issue when entering / exit conversations
              messageData[fallbackMessageType] = messageData['undefined'];
            }

            messageData.showConvo =
              messageData.showConvo || fallbackMessageType;

            let showConvoToggleType =
              messageData.showConvo || fallbackMessageType;

            messageData.agentChatting = !messageData.agentChatting;

            messageData[showConvoToggleType] =
              messageData[showConvoToggleType] || {};
            messageData[showConvoToggleType].agentChatting =
              messageData.agentChatting;

            foundConvo = messageData;
            break;
          }
        }

        if (foundConvo) break;
      }

      if (foundConvo) {
        setOtherAgentChatting(false);
        setAllConvos(convosClone);
        setSelectedConvo(foundConvo);
        // setRefreshVariable(!refreshVariable);
        // This is a better practice
        setRefreshVariable(oldRefreshVariable => !oldRefreshVariable);
        if (foundConvo.agentChatting === true) {
          startLiveAgentTimer(foundConvo.convoId);
        } else {
          stopLiveAgentTimer(foundConvo.convoId);
        }
      } else {
        console.log('DID NO FIND CONBO');
      }
    }
  };

  const removeUnseen = async () => {
    if (!(tokens.devAccess && tokens.env === 'prod')) {
      let tag = 'unseenBy ';

      if (!listFilter) {
        tag += 'true';
      } else {
        tag += listFilter.split(' ')[1];
      }

      if (selectedConvo.tags && selectedConvo.tags.indexOf(tag) > -1) {
        let resp = await tagHelper.removeConvoTag(selectedConvo.convoId, tag);
        setPrevSelectedConvo(selectedConvo);
      }
    } else {
      setPrevSelectedConvo(selectedConvo);
    }
  };

  const checkAgentChattingStatus = async convoId => {
    if (convoId) {
      let resp = await API.graphql(
        graphqlOperation(websocketConnectionByConvoId, { convoId: convoId })
      );

      let otherConnections = resp.data.websocketConnectionByConvoId.items;

      if (otherConnections.length > 0) {
        otherConnections.some(connection => {
          if (connection.agentDetails && connection.agentDetails.asurite) {
            if (
              connection.agentChatting === true &&
              tokens.asurite !== connection.agentDetails.asurite
            ) {
              setOtherAgentChatting(true);
              return true;
            } else {
              setOtherAgentChatting(false);
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    if (topButtons[tab].id === 'inbox') {
      let unseenInd = checkIfJustUnseen();
      if (prevSelectedConvo.id !== selectedConvo.id && unseenInd !== -1) {
        let clone = _.clone(allConvos);
        clone.inbox.data.splice(unseenInd, 1);
        setAllConvos(clone);
      }
    } else {
    }
  }, [allConvos, selectedConvo]);

  const acceptTagChange = async (newGroup, oldGroup, newFolder) => {
    let convoId = selectedConvo ? selectedConvo.convoId : null;
    console.log((newGroup, oldGroup, newFolder));
    let requeryFolders = -1;

    if (convoId) {
      for (var i = 0; i < allGroups.length; ++i) {
        if (allGroups[i].id === oldGroup) {
          --allGroups[i].count;
        }
        if (allGroups[i].id === newGroup) {
          ++allGroups[i].count;
        }

        for (var j = 0; j < allGroups[i].folders.length; ++j) {
          if (allGroups[i].folders[j].tagValue === folderFilter) {
            // --allGroups[i].folders[j].count;
            requeryFolders = i;
            --allGroups[i].count;
          }
          if (allGroups[i].folders[j].tagValue === newFolder) {
            ++allGroups[i].count;
          }
        }
      }

      if (requeryFolders > -1) {
        for (var i = 0; i < allGroups[requeryFolders].folders.length; ++i) {
          allGroups[requeryFolders].folders[i].total = await getDialogueCount(
            allGroups[requeryFolders].folders[i].tagValue
          );
        }
      }

      if (oldGroup || newFolder) {
        let convoClone = _.clone(allConvos);
        for (var key in convoClone) {
          for (var i = convoClone[key].data.length - 1; i >= 0; --i) {
            if (convoClone[key].data[i].convoId === convoId) {
              convoClone[key].data.splice(i, 1);
              --convoClone[key].data.total;
            }
          }
        }
        setAllConvos(convoClone);
      }

      setAllGroups(allGroups);

      // remove from boxes
      // add/remove to count
    }
  };

  useEffect(() => {
    // fetchConversations();

    console.log('rerender updated');
  }, [reRenderKey]);

  useEffect(() => {
    if (selectedConvo) {
      fetchMessageNotes(selectedConvo.convoId);
      removeUnseen();
      checkAgentChattingStatus(selectedConvo.convoId);
      fetchUnassignOptions(selectedConvo);
    } else {
      setDialogueOpen(false);
    }
  }, [selectedConvo]);

  useEffect(() => {
    if (!isShowing) {
      setTagsToUnassign({
        userTag: null,
        groupTag: null,
      });
    }
  }, [isShowing]);

  const fetchUnassignOptions = selectedConvo => {
    let { tags } = selectedConvo;

    if (!tags) tags = [];

    const userAssignedTags = tags.filter(tag => tag.includes('agentAssigned'));
    const groupAssignedTags = tags.filter(tag => tag.includes('groupAssigned'));

    if (userAssignedTags.length > 0) {
      setUnassignUserOptions(userAssignedTags);
    }
    if (groupAssignedTags.length > 0) {
      setUnassignGroupOptions(groupAssignedTags);
    }
  };

  const checkIfJustUnseen = () => {
    for (var i = 0; i < allConvos.inbox.data.length; ++i) {
      let convo = allConvos.inbox.data[i];
      if (convo.id === prevSelectedConvo.id) {
        return i;
      }
    }
    return -1;
  };

  const updateUser = user => {
    let allLists = _.clone(allConvos);
    let hitItem = null;

    for (var key in allLists) {
      let tempList = allLists[key].data;
      for (var i = 0; i < tempList.length; ++i) {
        if (tempList[i].sms && tempList[i].sms.userId === user.userId) {
          tempList[i].sms = {
            ...tempList[i].sms,
            ...user,
          };

          allLists[key].data[i] = {
            ...tempList[i],
            ...tempList[i][tempList[i].showConvo],
          };

          hitItem = tempList[i];
          break;
        } else if (
          tempList[i].auth &&
          tempList[i].auth.userId === user.userId
        ) {
          tempList[i].auth = {
            ...tempList[i].auth,
            ...user,
          };

          allLists[key].data[i] = {
            ...tempList[i],
            ...tempList[i][tempList[i].showConvo],
          };
          hitItem = tempList[i];
          break;
        }
      }
    }

    setAllConvos(allLists);

    if (
      hitItem &&
      (hitItem.userId === selectedConvo.userId ||
        hitItem.userId === selectedConvo.id)
    ) {
      setSelectedConvo(hitItem);
    }
  };

  let msg = {
    operations: [
      {
        operation: 'index',
        payload: {
          index: 'messages_prod',
          id: 'a039c731-f017-2248-8e8e-7349d3a7bf99',
          body: {
            messageId: 'a039c731-f017-2248-8e8e-7349d3a7bf99',
            convoId: '043b4b25-45fb-11e8-9c6b-8d0b99f5e1be',
            content: 'Awesomweeeerr',
            latest: 1628627727884,
            sender: '+15204154197',
            asurite: '+15204154197',
            type: 'sms',
            agentAction: 'null',
            agentAsurite: 'null',
            agentName: 'null',
            connectionId: 'mqYwPnUNvvJVDmX',
            tags: [],
          },
        },
      },
      {
        operation: 'index',
        payload: {
          index: 'messages_prod',
          id: 'e0ed8a97-7fbf-3350-a3af-16c1fa5248c7',
          body: {
            messageId: 'e0ed8a97-7fbf-3350-a3af-16c1fa5248c7',
            convoId: '043b4b25-45fb-11e8-9c6b-8d0b99f5e1be',
            content:
              'Thanks for sharing with me 😊 Just one follow-up question!',
            latest: 1628627727886,
            sender: 'ASU-sunnybot',
            asurite: 'ASU-sunnybot',
            type: 'sms',
            image: null,
            agentAction: 'null',
            agentAsurite: 'null',
            agentName: 'null',
            connectionId: 'mqYwPnUNvvJVDmX',
            tags: [],
          },
        },
      },
      {
        operation: 'index',
        payload: {
          index: 'messages_prod',
          id: '0a4b7527-af92-4569-59b1-dca439f18d86',
          body: {
            messageId: '0a4b7527-af92-4569-59b1-dca439f18d86',
            convoId: '043b4b25-45fb-11e8-9c6b-8d0b99f5e1be',
            content:
              "What's the one thing I could tell my humans that would make your ASU experience better? ",
            latest: 1628627727887,
            sender: 'ASU-sunnybot',
            asurite: 'ASU-sunnybot',
            type: 'sms',
            image: null,
            agentAction: 'null',
            agentAsurite: 'null',
            agentName: 'null',
            connectionId: 'mqYwPnUNvvJVDmX',
            tags: [],
          },
        },
      },
    ],
  };

  const setLiveConvo = item => {
    let tempList = _.clone(allConvos);

    for (var key in tempList) {
      let didFind = false;

      let foundConvos = _.remove(tempList[key].data, function(x) {
        return (
          x.id === item.id ||
          (x.sms && x.sms.userId === item.id) ||
          (x.auth && x.auth.userId === item.id)
        );
      });

      if ((key === 'inbox' || key === 'all') && !listFilter) {
        if (foundConvos.length === 0) {
          ++tempList[key].total;
        }
        tempList[key].data.unshift(item);
      }
    }

    setAllConvos(tempList);

    setTimeout(() => {
      checkAgentChattingStatus(selectedConvo.convoId);
    }, 1500);

    setUpdateBanner(item);
  };

  const setWebSocketId = item => {
    let convoListClone = _.clone(allConvos);
    for (var key in allConvos) {
      for (let i = 0; i < convoListClone[key].data.length; i++) {
        if (
          item.asurite === convoListClone[key].data[i].asurite &&
          item.webId !== undefined
        ) {
          convoListClone[key].data[i].webId = item.webId;
          break;
        }
      }
    }

    setAllConvos(convoListClone);
  };

  const renderConversationIcon = (type, modalityType) => {

    var returnValue = 'phone';

    if (type === 'sms') {
      returnValue = 'phone'
    } else if (type === 'auth') {
      returnValue = 'auth'
    } else if (type === 'voice') {
      returnValue = 'voice'
    }

    if (modalityType === 'sms') {
      returnValue = 'phone'
    } else if (modalityType === 'web') {
      returnValue = 'auth'
    } else if (modalityType === 'voice') {
      returnValue = 'voice';
    }

    return returnValue;
  }

  const getAvatarForList = (el, size) => {

    let icon_selection = renderConversationIcon(el.type, filterModalityType);

    if (icon_selection === 'auth') {
      return (
        <Avatar
          src={el.profileImage ? el.profileImage : el.photoUrl}
          name={el.displayName ? el.displayName : el.asurite}
          color="#873450"
          size={size ? `${size}px` : '35px'}
          round
          style={{ marginRight: '5px' }}
        />
      );
    } else if (icon_selection === 'phone') {
      return (
        <AvatarX
          style={{
            width: `${size}px`,
            height: `${size}px`,
            backgroundColor: '#873450',
            color: 'white',
            marginRight: '5px',
          }}
        >
          <PhoneIcon fontSize="small" />
        </AvatarX>
      );
    } else if (icon_selection === 'voice') {
      return (
        <AvatarX
          style={{
            width: `${size}px`,
            height: `${size}px`,
            backgroundColor: '#873450',
            color: 'white',
            marginRight: '5px',
          }}
        >
          <PersonIcon fontSize="small" />
        </AvatarX>
      );
    }
  };

  const getAvatar = (el, size) => {
    if (el.type === 'auth') {
      return (
        <Avatar
          src={el.profileImage ? el.profileImage : el.photoUrl}
          name={el.displayName ? el.displayName : el.asurite}
          color="#873450"
          size={size ? `${size}px` : '35px'}
          round
          style={{ marginRight: '5px' }}
        />
      );
    } else {
      return (
        <AvatarX
          style={{
            width: `${size}px`,
            height: `${size}px`,
            backgroundColor: '#873450',
            color: 'white',
            marginRight: '5px',
          }}
        >
          {el.type === 'sms' ? (
            <PhoneIcon fontSize="small" />
          ) : (
            <PersonIcon fontSize="small" />
          )}
        </AvatarX>
      );
    }
  };

  const toggleConvo = type => {
    // change conversation type and update original array
    let convosClone = _.clone(allConvos);
    let foundConvo = null;

    let newConvo = {
      ...selectedConvo,
      ...selectedConvo[type],
      showConvo: type,
    };

    for (var key in convosClone) {
      for (let i = convosClone[key].data.length - 1; i >= 0; i--) {
        if (convosClone[key].data[i].id === selectedConvo.id) {
          convosClone[key].data[i] = newConvo;
          foundConvo = true;
          break;
        }
      }
      if (foundConvo) break;
    }

    if (foundConvo) {
      setAllConvos(convosClone);
    }

    setSelectedConvo(newConvo);
  };

  const handleMenuOpen = (event, convo) => {
    setClickedConvo(convo);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setClickedConvo(null);
    setAnchorEl(null);
  };

  const openSubMenu = (event, type) => {
    setSubMenuType(type);
    setSubAnchorEl(event.currentTarget);
  };

  const closeSubMenu = () => {
    setSubAnchorEl(null);
    setTimeout(() => {
      setSubMenuType(null);
    }, 200);
  };

  const handleFilterMenuOpen = event => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterMenuOpen2 = event => {
    setFilterAnchorEl2(event.currentTarget);
  };

  const handleFilterMenuOpen3 = event => {
    setFilterAnchorEl3(event.currentTarget);
  };

  const handleFilterMenuOpen4 = event => {
    setFilterAnchorEl4(event.currentTarget);
  };

  const handleFilterMenuOpen5 = event => {
    setFilterAnchorEl5(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterMenuClose2 = () => {
    setFilterAnchorEl2(null);
  };

  const handleFilterMenuClose3 = () => {
    setFilterAnchorEl3(null);
  };

  const handleFilterMenuClose4 = () => {
    setFilterAnchorEl4(null);
  };

  const handleFilterMenuClose5 = () => {
    setFilterAnchorEl5(null);
  };

  const updateConvoTag = msgs => {
    let clonedConvos = _.clone(allConvos);
    for (var key in clonedConvos) {
      clonedConvos[key].data = tagHelper.updateTags(
        msgs,
        clonedConvos[key].data,
        'convoId'
      );
    }
    setAllConvos(clonedConvos);
  };

  const updateConvoTagNew = msgs => {
    let clonedConvos = _.clone(allConvos);

    console.log('HITTING OCNVO UPDATE TAG: ', msgs);

    let flaggedNeedsReview = {};
    let flaggedPrevAssisted = {};

    for (var key in clonedConvos) {
      let info = tagHelper.updateTagsNew(
        msgs,
        clonedConvos[key].data,
        'convoId'
      );

      clonedConvos[key].data = info.arr;

      if (key === 'inbox') {
        flaggedNeedsReview = info.needsReview;
        flaggedPrevAssisted = info.previouslyAssisted;
      }
    }

    let reviewConvos = clonedConvos.review;
    let assistedConvos = clonedConvos.assisted;

    console.log('HERE: ', reviewConvos);

    let keyedCurr = {};

    if (
      flaggedNeedsReview.add.length > 0 ||
      flaggedNeedsReview.remove.length > 0
    ) {
      for (var i = reviewConvos.data.length - 1; i >= 0; --i) {
        keyedCurr[reviewConvos.data[i].id] = true;
        if (flaggedNeedsReview.remove.indexOf(reviewConvos.data[i].id) > -1) {
          reviewConvos.data.splice(i, 1);
        }
      }

      for (var i = flaggedNeedsReview.add.length - 1; i >= 0; --i) {
        console.log(reviewConvos.data);
        if (!keyedCurr[flaggedNeedsReview.add[i].id]) {
          console.log('ADDING:', flaggedNeedsReview.add[i]);
          reviewConvos.data.unshift(flaggedNeedsReview.add[i]);
        } else {
          flaggedNeedsReview.add.splice(i, 1);
        }
      }
    }

    keyedCurr = {};

    if (
      flaggedPrevAssisted.add.length > 0 ||
      flaggedPrevAssisted.remove.length > 0
    ) {
      for (var i = assistedConvos.data.length - 1; i >= 0; --i) {
        keyedCurr[assistedConvos.data[i].id] = true;
        if (
          flaggedPrevAssisted.remove.indexOf(assistedConvos.data[i].id) > -1
        ) {
          assistedConvos.data.splice(i, 1);
        }
      }

      for (var i = flaggedPrevAssisted.add.length - 1; i >= 0; --i) {
        console.log(assistedConvos.data);
        if (!keyedCurr[flaggedPrevAssisted.add[i].id]) {
          console.log('ADDING:', flaggedPrevAssisted.add[i]);
          assistedConvos.data.unshift(flaggedPrevAssisted.add[i]);
        } else {
          flaggedPrevAssisted.add.splice(i, 1);
        }
      }
    }

    setAllConvos({
      ...clonedConvos,
      review: {
        page: 0,
        total: reviewConvos.total + flaggedNeedsReview.add.length,
        allowedToLoadMore: true,
        data: reviewConvos.data,
      },
      assisted: {
        page: 0,
        total: assistedConvos.total + flaggedPrevAssisted.add.length,
        allowedToLoadMore: true,
        data: assistedConvos.data,
      },
    });

    // console.log("CLONED: ", clonedConvos);
    // setAllConvos(clonedConvos);
  };

  useEffect(() => {}, [clickedConvo]);

  const getAssigneeName = (a, g) => {
    return groupsAndUsersHelper.getAssigneeName(a, g, keyedUsers, keyedGroups);
  };

  useEffect(() => {
    if (!campaignSwitch) {
      fetchConversations();
      if (listViewFilter) {
        changeListViewFilter(listViewFilter.split(' ')[0], listViewFilterName);
      }
      setSelectedConvo('');
      if (topButtons[tab].id === 'sr') {
        topButtons.some((button, index) => {
          if (button.id === 'all') {
            setTab(index);
            return true;
          }
        });
      }
    } else {
      //setUserAndConvoMarkers({ inbox: 0, review: 0, assisted: 0, all: 0 });
      setSelectedConvo('');
      setAllConvos({
        inbox: { page: 0, total: 0, data: [] },
        review: { page: 0, total: 0, data: [] },
        assisted: { page: 0, total: 0, data: [] },
        all: { page: 0, total: 0, data: [] },
        sr: { page: 0, total: 0, data: [] },
      });

      //re-load with new filters
      if (selectedButton.id === 'sr') {
        loadCampaign(campaignSwitch, false, inboxSearch);
      } else {
        loadCampaign(campaignSwitch);
      }
    }

    console.log('!!! listFilter is', listFilter);
    console.log('!!! folderFilter is', folderFilter);
    console.log('!!! convoFilter is', convoFilter);
    console.log('!!! filterModalityType is', filterModalityType);
    console.log('!!! filterRoles is', filterRoles);
    console.log('!!! filterRoles2 is', filterRoles2);
    console.log('!!! undergradStatus is', undergradStatus);
  }, [
    listFilter,
    folderFilter,
    convoFilter,
    filterRoles,
    filterRoles2,
    filterModalityType,
    undergradStatus,
    prospectiveStatus,
  ]);

  const changeListViewFilter = (type, name) => {
    if (type) {
      if (listFilter) {
        type += ' ' + listFilter.split(' ')[1];
      } else {
        type += ' true';
      }
    }

    setListViewFilter(type);
    setListViewFilterName(name);
    setSelectedConvo('');
  };

  const startLiveAgentTimer = (convoId, stopTimerResult) => {
    let indexAt = -1;
    let newLiveAgentTimers =
      typeof stopTimerResult !== 'undefined'
        ? [...stopTimerResult]
        : [...liveAgentTimers];

    newLiveAgentTimers.some((timer, index) => {
      if (timer.getLabel() === convoId) {
        indexAt = index;
        return true;
      }
    });

    if (indexAt === -1) {
      newLiveAgentTimers.push(new Timer({ label: convoId }));
      newLiveAgentTimers[newLiveAgentTimers.length - 1].start();
    } else {
      newLiveAgentTimers[indexAt].clear();
      newLiveAgentTimers[indexAt].start();
    }

    setLiveAgentTimers(newLiveAgentTimers);
  };

  const stopLiveAgentTimer = convoId => {
    let indexAt = -1;
    let newLiveAgentTimers = [...liveAgentTimers];

    newLiveAgentTimers.some((timer, index) => {
      if (timer.getLabel() === convoId) {
        timer.stop();
        indexAt = index;
        return true;
      }
    });

    newLiveAgentTimers.splice(indexAt, 1);
    setLiveAgentTimers(newLiveAgentTimers);

    return newLiveAgentTimers;
  };

  const readLiveAgentTimer = convoId => {
    let newValue = '';
    liveAgentTimers.some(timer => {
      if (timer.getLabel() === convoId) {
        let maxTotalInSeconds = liveAgentTimerDuration;
        let currentMinutes = timer.format('%m');
        let currentSeconds = timer.format('%s');
        let currentTotalInSeconds =
          parseInt(currentSeconds) + parseInt(currentMinutes) * 60;
        let currentTimeRemainingInSeconds =
          maxTotalInSeconds - currentTotalInSeconds;

        if (currentTotalInSeconds >= maxTotalInSeconds) {
          newValue = 'CHATBOT RESUMING';
          setTimeout(modifyActiveConvos, 1000);
        } else {
          currentMinutes = Math.floor(currentTimeRemainingInSeconds / 60);
          currentSeconds = currentTimeRemainingInSeconds % 60;
          newValue = `CHATBOT PAUSED: ${currentMinutes}m:${currentSeconds}s`;
        }
        return true;
      }
    });

    return newValue;
  };

  const deleteFolderTags = async info => {
    let convoClone = _.clone(allConvos);

    for (var key in convoClone) {
      for (var i = 0; i < convoClone[key].data.length; ++i) {
        if (
          convoClone[key].data[i].tags &&
          convoClone[key].data[i].tags.indexOf(info.folder.tagValue) > -1
        ) {
          await tagHelper.removeConvoTag(
            convoClone[key].data[i].convoId,
            info.folder.tagValue
          );
        }
      }
    }

    setAllConvos(convoClone);
  };

  const restartLiveAgentTimer = convoId => {
    liveAgentTimers.some(timer => {
      if (timer.getLabel() === convoId) {
        timer.clear();
        timer.start();
      }
    });
  };

  const addRule = () => {
    setTriggeredAddRule(clickedConvo);
    handleMenuClose();
    setTimeout(() => {
      setTriggeredAddRule(null);
    }, 200);
  };

  const handleTabChange = (event, newValue) => {
    let previousTab = selectedButton.id;
    let item = topButtons[parseInt(newValue)];
    setSelectedButton(item);
    changeListViewFilter(item.filter, item.name);
    setTab(newValue);

    if (item.id !== 'sr') {
      setInboxSearch('');
      setWriteInboxSearch('');
      setInboxSearchIgnoreCharMin(true);
      setInboxSearchKeywordMatches(false);
      if (previousTab === 'sr') {
        if (campaignSwitch) {
          loadCampaign(campaignSwitch);
        } else {
          fetchConversations();
        }
      }
    } else {
      // searchInboxRef.current.value = previousSearchTerm;
      setWriteInboxSearch(previousSearchTerm);
      setInboxSearch(previousSearchTerm);

      if (campaignSwitch) {
        loadCampaign(campaignSwitch, false, previousSearchTerm);
      } else {
        fetchConversations();
      }
    }
  };

  const messageSearch = async keyword => {
    let resp;

    if (/^[\d+]+$/g.test(keyword.replace(/[\(\)\-\s]/g, '')) === true) {
      resp = await API.graphql(
        graphqlOperation(searchMessages, {
          operation: 'queryByPhoneNumber',
          payload: JSON.stringify({
            userId: keyword.replace(/[\(\)\-\s]/g, ''),
            botType: 'sunny',
          }),
        })
      );
    } else if (keyword.length > 17 && /\s/g.test(keyword) === false) {
      resp = await API.graphql(
        graphqlOperation(searchMessages, {
          operation: 'queryByCrmId',
          payload: JSON.stringify({ crmId: keyword }),
        })
      );
    } else {
      resp = await API.graphql(
        graphqlOperation(searchMessages, {
          operation: 'queryForMessageKeywords',
          payload: JSON.stringify({ content: keyword }),
        })
      );
    }

    resp = JSON.parse(resp.data.searchMessages);
    let validResp = [];

    if (Array.isArray(resp)) {
      resp = resp.map(message => {
        return message.convoId;
      });

      for (var i = 0; i < resp.length; ++i) {
        if (validResp.indexOf(resp[i]) === -1) {
          validResp.push(resp[i]);
        }
      }
    }

    setInboxSearchKeywordMatches(validResp);
    return validResp;
  };

  const ConvosClickActionToggle = toggleEnable => {
    let ele = document.getElementById('numberOfConvos');
    console.log('HIT: ', toggleEnable);

    if (ele) {
      if (toggleEnable) {
        ele.style.pointerEvents = 'auto';
        ele.style.opacity = '1';
      } else {
        ele.style.pointerEvents = 'none';
        ele.style.opacity = '.5';
      }
    }

    let chatEle = document.getElementById('chatContainerTabView');
    if (chatEle) {
      if (toggleEnable) {
        chatEle.style.pointerEvents = 'auto';
        chatEle.style.opacity = '1';
      } else {
        chatEle.style.pointerEvents = 'none';
        chatEle.style.opacity = '.5';
      }
    }
  };

  const handleConvoScroll = e => {
    const el = e.target;

    const position = el.scrollTop / (el.scrollHeight - el.clientHeight);

    /*if (
      selectedButton.id === "inbox" &&
      allowedToLoadMore &&
      position > 0.98 &&
      allConvos[selectedButton.id]
      //allConvos.all.total > 0
    ) {
      loadMoreConvos();
    }*/

    if (
      allowedToLoadMore &&
      position > 0.98 &&
      allConvos[selectedButton.id].allowedToLoadMore == true
      //allConvos.all.total > 0
    ) {
      if (campaignSwitch) {
        if (selectedButton.id === 'sr') {
          loadCampaign(campaignSwitch, true, inboxSearch);
        } else {
          loadCampaign(campaignSwitch, true);
        }
      } else {
        loadMoreConvos();
      }
    }
  };

  const filterButtonsActive = dataOnly => {
    const filterButtonDataArray = [
      {
        label: 'Read',
        linkedState: convoFilter,
        value: 'read',
        removeFilter: () => {
          setConvoFilter(null);
        },
      },
      {
        label: 'Unread',
        linkedState: convoFilter,
        value: 'unseenBy',
        removeFilter: () => {
          setConvoFilter(null);
        },
      },
      {
        label: 'Needs Review',
        linkedState: convoFilter,
        value: 'needsReview',
        removeFilter: () => {
          setConvoFilter(null);
        },
      },
      {
        label: 'Needs Kendra Review',
        linkedState: convoFilter,
        value: 'Kendra',
        removeFilter: () => {
          setConvoFilter(null);
        },
      },
      {
        label: 'Assigned',
        linkedState: convoFilter,
        value: 'agentAssigned',
        removeFilter: () => {
          setConvoFilter(null);
        },
      },
      {
        label: 'Has alert',
        linkedState: convoFilter,
        value: 'hasAlert',
        removeFilter: () => {
          setConvoFilter(null);
        },
      },
      {
        label: 'SMS',
        linkedState: filterModalityType,
        value: 'sms',
        removeFilter: () => {
          setFilterModalityType(null);
          setConvoUIFilter(null);
        },
      },
      {
        label: 'Web/Auth',
        linkedState: filterModalityType,
        value: 'web',
        removeFilter: () => {
          setFilterModalityType(null);
          setConvoUIFilter(null);
        },
      },
      {
        label: 'Voice',
        linkedState: filterModalityType,
        value: 'voice',
        removeFilter: () => {
          setFilterModalityType(null);
          setConvoUIFilter(null);
        },
      },
      {
        label: 'Current Student',
        linkedState: filterRoles,
        value: ['ENROLLED'],
        removeFilter: () => {
          setFilterRoles([]);
        },
      },
      {
        label: 'Prospective Student',
        linkedState: filterRoles,
        value: ['UAPP', 'GAPP'],
        removeFilter: () => {
          setFilterRoles([]);
          setConvoUIFilter(null);
          setProspectiveStatus(null);
        },
      },
      //Prospective labels
      {
        label: 'First Year',
        linkedState: prospectiveStatus,
        value: 'freshman',
        removeFilter: () => {
          setProspectiveStatus(null);
          setConvoUIFilter3(null);
        },
      },
      {
        label: 'Transfer',
        linkedState: prospectiveStatus,
        value: 'transfer',
        removeFilter: () => {
          setProspectiveStatus(null);
          setConvoUIFilter3(null);
        },
      },
      {
        label: 'Graduate',
        linkedState: prospectiveStatus,
        value: 'graduate',
        removeFilter: () => {
          setProspectiveStatus(null);
          setConvoUIFilter3(null);
        },
      },
      {
        label: 'Other',
        linkedState: prospectiveStatus,
        value: 'other',
        removeFilter: () => {
          setProspectiveStatus(null);
          setConvoUIFilter3(null);
        },
      },
      //Labels
      {
        label: 'Graduate',
        linkedState: filterRoles2,
        value: ['GS'],
        removeFilter: () => {
          setFilterRoles2(null);
          setConvoUIFilter(null);
        },
      },
      {
        label: 'Undergraduate',
        linkedState: filterRoles2,
        value: ['UGS'],
        removeFilter: () => {
          setFilterRoles2(null);
          setConvoUIFilter(null);
        },
      },
      {
        label: 'Freshman',
        linkedState: undergradStatus,
        value: 'freshman',
        removeFilter: () => {
          setUndergradStatus(null);
          setConvoUIFilter2(null);
        },
      },
      {
        label: 'Transfer',
        linkedState: undergradStatus,
        value: 'transfer',
        removeFilter: () => {
          setUndergradStatus(null);
          setConvoUIFilter2(null);
        },
      },
      {
        label: 'Opportunity 1',
        linkedState: filterRoles,
        value: null,
        removeFilter: () => {
          setFilterRoles([]);
        },
      }, //add its own state variable
      {
        label: 'Opportunity 2',
        linkedState: filterRoles,
        value: null,
        removeFilter: () => {
          setFilterRoles([]);
        },
      },
    ];
    return filterButtonDataArray
      .filter(filterButtonData => {
        if (filterButtonData.linkedState && filterButtonData.value) {
          if (
            filterButtonData.linkedState == filterButtonData.value ||
            (filterButtonData.linkedState[0] &&
              filterButtonData.linkedState[0] == filterButtonData.value[0])
          ) {
            return true;
          }
        }
      })
      .map(filterButtonData => {
        if (dataOnly) {
          return filterButtonData.label;
        } else {
          return (
            <div
              className="filterActiveButton"
              onClick={filterButtonData.removeFilter}
            >
              {filterButtonData.label} x
            </div>
          );
        }
      });
  };

  const clearSearch = () => {
    if (selectedButton.id === 'sr') {
      let item = topButtons[0];
      setSelectedButton(item);
      changeListViewFilter(item.filter, item.name);
      setTab(0);
      setInboxSearch('');
      setWriteInboxSearch('');
    }
  };

  const detectCampaignBridge = () => {
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 3000);

    fetch(baseURL, { signal: controller.signal })
      .then(response => {
        clearTimeout(timeout);
        return response.json();
      })
      .then(function(data) {
        if (data.bridge && data.bridge === 'present') {
          setCampaignBridgeDetected(true);
        }
      })
      .catch(err => {
        console.log('Bridge not present:', err);
      });
  };

  const unassignModalContent = () => {
    if (unassignUserOptions.length <= 0 && unassignGroupOptions.length <= 0) {
      return (
        <ModalContent
          title="This conversation has no assigned groups or users!"
          buttonLabel="CLOSE"
          onSubmit={() => toggle()}
        />
      );
    } else {
      const formData = [];

      const optionFormatter = (options, isGroup) => {
        return options.map(tag => {
          return {
            value: tag,
            label: !isGroup
              ? getAssigneeName(tag.split(' ')[1], null)
              : getAssigneeName(null, tag.split(' ')[1]),
          };
        });
      };

      if (unassignUserOptions.length > 0) {
        const userOptions = optionFormatter(unassignUserOptions);

        const userOnChange = tag => {
          setTagsToUnassign({
            userTag: tag,
            groupTag: tagsToUnassign.groupTag,
          });
        };

        formData.push({
          title: 'UNASSIGN USER',
          required: true,
          component: (
            <Select
              name="user"
              placeholder="Select User"
              options={userOptions}
              onChange={userOnChange}
            />
          ),
        });
      }

      if (unassignGroupOptions.length > 0) {
        const groupOptions = optionFormatter(unassignGroupOptions, true); //TODO: generate options { value, label}

        const groupOnChange = tag => {
          setTagsToUnassign({
            groupTag: tag,
            userTag: tagsToUnassign.userTag,
          });
        };

        formData.push({
          title: 'UNASSIGN GROUP',
          required: true,
          component: (
            <Select
              name="group"
              placeholder="Select Group"
              options={groupOptions}
              onChange={groupOnChange}
            />
          ),
        });
      }

      //TODO: adding loading logic after submit

      const unassignTag = async () => {
        try {
          const result = [];

          for (let tag in tagsToUnassign) {
            if (tagsToUnassign[tag]) {
              result.push(
                tagHelper.removeConvoTag(
                  selectedConvo.convoId,
                  tagsToUnassign[tag]
                )
              );
              if (tagsToUnassign[tag].split(' ')[0] === 'agentAssigned') {
                setUnassignUserOptions([]);
              }
              if (tagsToUnassign[tag].split(' ')[0] === 'groupAssigned') {
                setUnassignGroupOptions([]);
              }
            }
          }
          Promise.all(result)
            .then(() => {
              toggle();
            })
            .catch(err => console.log(err));
        } catch (err) {
          console.log('error unassigning tag: ', err);
        }
      };

      return (
        <ModalContent
          form={true}
          formData={formData}
          title="Unassign User or Group"
          onSubmit={unassignTag}
          toggle={toggle}
        />
      );
    }
  };

  const kbNotFoundModalContent = () => {
    return (
      <ModalContent
        title="Knowledge Base article not found"
        buttonLabel="CLOSE"
        onSubmit={() => setShowCustomAlertModal(false)}
      />
    );
  }

  return (
    <div className="main-container">
      <TriageQueue
        groupNum={tokens.groups.length}
        isLoading={isLoading}
        convoList={allConvos}
        triageTotal={triageTotal}
        assignedToMeCount={assignedToMeCount}
        filteredConvoList={allConvos}
        setListFilter={setListFilter}
        setListFilterName={setListFilterName}
        setInboxSearch={setInboxSearch}
        getDialogueCount={getDialogueCount}
        groups={allGroups}
        counts={counts}
        deleteFolderTags={deleteFolderTags}
        updateGroups={updateGroups}
        setFolderFilter={setFolderFilter}
        keyedUserGroups={keyedUserGroups}
        keyedUsers={keyedUsers}
        keyedGroups={keyedGroups}
        triggeredAddRule={triggeredAddRule}
        presentUserGroups={tokens.groups}
      />

      <AgentBanner
        liveAgentTimers={liveAgentTimers}
        liveAgentTimerDuration={liveAgentTimerDuration}
        selectedConvo={selectedConvo}
        allConvosList={allConvos}
        setInboxSearch={setInboxSearch}
        setOtherAgentChatting={setOtherAgentChatting}
        modifyActiveConvos={modifyActiveConvos}
        searchInboxRef={searchInboxRef}
        updateBanner={updateBanner}
        setWriteInboxSearch={setWriteInboxSearch}
        currentButton={selectedButton.id}
      />

      <Paper
        // Main Dialouge UI Section
        className="main-dialogue-container"
      >
        <div
          className="triage-message-selector"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <h3 style={{ margin: '0', fontSize: '1.7em', marginLeft: '15px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {campaignSwitch ? campaignSwitch.title : listFilterName}
              <KeyboardArrowRightIcon fontSize="small" />
              {listViewFilterName}
              <CampaignFilterButton
                campaignSwitch={campaignSwitch}
                setCampaignSwitch={setCampaignSwitch}
                isLoading={isLoading}
                setAllConvos={setAllConvos}
                fetchConversations={fetchConversations}
                setSelectedConvo={setSelectedConvo}
                campaignBridgeDetected={campaignBridgeDetected}
                loadCampaign={loadCampaign}
                clearSearch={clearSearch}
              />
            </div>
          </h3>
        </div>
        <div
          className="triage-message-selector"
          style={{ backgroundColor: 'white', display: 'flex' }}
        >
          <TopNavigation
            topButtons={topButtons}
            isDialogueOpen={isDialogueOpen}
            handleTabChange={handleTabChange}
            selectedConvo={selectedConvo}
            setDialogueOpen={setDialogueOpen}
            filteredConvoList={allConvos}
            counts={counts}
            tab={tab}
            dialogueCount={allDialogueCount}
          />
          <div
            className="dialogue-properties-header"
            style={{
              flex: isDialogueOpen ? 1 : 0,
              width: isDialogueOpen ? 'auto' : 0,
            }}
          >
            <h3>Properties</h3>
          </div>
        </div>
        <div style={{ display: 'flex', height: '73vh' }}>
          <div style={{ flex: 1.91 }}>
            <div
              style={{
                display: 'flex',
                height: '100%',
              }}
            >
              <div
                style={{
                  flex: '1',
                  overflow: 'hidden'
                }}
              >
                <div>
                  <SearchContainer
                    writeInboxSearch={writeInboxSearch}
                    searchInboxRef={searchInboxRef}
                    setInboxSearch={setInboxSearch}
                    setInboxSearchIgnoreCharMin={setInboxSearchIgnoreCharMin}
                    setInboxSearchKeywordMatches={setInboxSearchKeywordMatches}
                    handleFilterMenuOpen={handleFilterMenuOpen}
                    handleFilterMenuOpen2={handleFilterMenuOpen2}
                    handleFilterMenuOpen3={handleFilterMenuOpen3}
                    handleFilterMenuOpen4={handleFilterMenuOpen4}
                    handleFilterMenuOpen5={handleFilterMenuOpen5}
                    filterAnchorEl={filterAnchorEl}
                    filterAnchorEl2={filterAnchorEl2}
                    filterAnchorEl3={filterAnchorEl3}
                    filterAnchorEl4={filterAnchorEl4}
                    filterAnchorEl5={filterAnchorEl5}
                    handleFilterMenuClose={handleFilterMenuClose}
                    handleFilterMenuClose2={handleFilterMenuClose2}
                    handleFilterMenuClose3={handleFilterMenuClose3}
                    handleFilterMenuClose4_Handler={handleFilterMenuClose4}
                    handleFilterMenuClose5={handleFilterMenuClose5}
                    filterConvosByTag={filterConvosByTag}
                    filterConvosByRole={filterConvosByRole}
                    filterConvosByRole2={filterConvosByRole2}
                    filterConvosByModality={filterConvosByModality}
                    filterConvosByLevel={filterConvosByLevel}
                    filterConvosByUndergradStatus={filterConvosByUndergradStatus}
                    filterConvosByProspectiveStatus={
                      filterConvosByProspectiveStatus
                    }
                    currentFilterName={convoFilter}
                    currentUIFilterName={convoUIFilter}
                    currentUIFilterName2={convoUIFilter2}
                    currentUIFilterName3={convoUIFilter3}
                    filterRoles={filterRoles}
                    filterRoles2={filterRoles2}
                    undergradStatus={undergradStatus}
                    prospectiveStatus={prospectiveStatus}
                    filterModalityType={filterModalityType}
                    filterButtonsActive={filterButtonsActive}
                  />
                  {filterButtonsActive().length > 0 && (
                    <div
                      style={{
                        padding: '0.5em 0.5em',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderTop: '0',
                      }}
                    >
                      Current Filters:
                      {filterButtonsActive()}
                    </div>
                  )}
                </div>
                <div
                  id="numberOfConvos"
                  style={{
                    pointerEvents: 'auto',
                    height: '100%',
                    overflowY: 'scroll',
                    transition: 'opacity 250ms ease-in-out',
                  }}
                  onScroll={handleConvoScroll}
                >
                  {isLoading && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <CircularProgress
                        size={props.spinnerSize ? props.spinnerSize : '2rem'}
                      />
                    </div>
                  )}
                  {!isLoading && allConvos[selectedButton.id].data.length === 0 && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <div>No Conversations in {listViewFilterName}</div>
                    </div>
                  )}

                  {!isLoading &&
                    allConvos[selectedButton.id].data.length > 0 &&
                    allConvos[selectedButton.id].data.map(el => {
                      return (
                        <Conversation
                          el={el}
                          convosWithNotesById={convosWithNotesById}
                          selectedConvo={selectedConvo}
                          setOtherAgentChatting={setOtherAgentChatting}
                          modifyActiveConvos={modifyActiveConvos}
                          getAvatar={getAvatarForList}
                          handleMenuOpen={handleMenuOpen}
                          getAssigneeName={getAssigneeName}
                          key={el.id}
                        />
                      );
                    })}
                  {isConvoListLoading && (
                    <div
                      style={{
                        margin: '10px 0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '2rem',
                      }}
                    >
                      <CircularProgress size="1.5rem" />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  borderLeft: '1px solid #d5d5d5',
                }}
              >
                <Messages
                  selectedConvo={selectedConvo}
                  updateConvoTag={updateConvoTagNew}
                  asurite={selectedConvo.asurite}
                  setOtherAgentChatting={setOtherAgentChatting}
                  checkAgentChattingStatus={checkAgentChattingStatus}
                  setLiveConvo={setLiveConvo}
                  setWebSocketId={setWebSocketId}
                  customTags={customTags}
                  setCustomTags={setCustomTags}
                  approvedUsers={approvedUsers}
                  refreshVariable={refreshVariable}
                  getAvatar={getAvatar}
                  setDialogueProperties={setDialogueProperties}
                  liveAgentTimers={liveAgentTimers}
                  readLiveAgentTimer={readLiveAgentTimer}
                  restartLiveAgentTimer={restartLiveAgentTimer}
                  dialogueProperties={dialogueProperties}
                  setUpdateNeedsReview={setUpdateNeedsReview}
                  convoNotes={convoNotes}
                  setConvoNotes={setConvoNotes}
                  updateUser={updateUser}
                  setMsgsInReview={setMsgsInReview}
                  listFilter={listFilter}
                  myReviewTags={myReviewTags}
                  convosWithNotesById={convosWithNotesById}
                  searchForMessage={searchForMessage}
                  setSearchForMessage={setSearchForMessage}
                  lastMessage={props.lastMessage}
                  connectionStatus={props.connectionStatus}
                  sendMessage={props.sendMessage}
                  wssToken={props.wssToken}
                  updateNeedsReview={updateNeedsReview}
                  history={props.history}
                  ConvosClickActionToggle={ConvosClickActionToggle}
                  toggleConvo={toggleConvo}
                  escalationData={escalationData}
                  acceptTagChange={acceptTagChange}
                  updateReviewCounts={updateReviewCounts}
                  updateReviewTags={updateReviewTags}
                  selectedCampaign={selectedCampaign}
                />
              </div>
            </div>
          </div>
          {isDialogueOpen && (
            <DialogueProperties
              updateReviewCounts={updateReviewCounts}
              updateReviewTags={updateReviewTags}
              ConvosClickActionToggle={ConvosClickActionToggle}
              history={props.history}
              selectedConvo={selectedConvo}
              searchForMessage={searchForMessage}
              setSearchForMessage={setSearchForMessage}
              modifyActiveConvos={modifyActiveConvos}
              getAvatar={getAvatar(selectedConvo, 75)}
              otherAgentChatting={otherAgentChatting}
              escalationData={escalationData}
              dialogueProperties={dialogueProperties}
              setUpdateNeedsReview={setUpdateNeedsReview}
              convoNotes={convoNotes}
              setConvoNotes={setConvoNotes}
              allCampaigns={allCampaigns}
              msgsInReview={msgsInReview}
              getAssigneeName={getAssigneeName}
              listFilter={listFilter}
              myReviewTags={myReviewTags}
              acceptTagChange={acceptTagChange}
              availableCampaigns={availableCampaigns}
              setSelectedConvo={setSelectedConvo}
            />
          )}
        </div>
      </Paper>
      <ConvoActionMenu
        anchorEl={anchorEl}
        handleMenuClose={handleMenuClose}
        openSubMenu={openSubMenu}
        addRule={addRule}
        toggleUnassignModal={toggle}
      />
      <ConvoActionSubMenu
        subanchorEl={subanchorEl}
        closeSubMenu={closeSubMenu}
        updateReviewCounts={updateReviewCounts}
        updateReviewTags={updateReviewTags}
        subMenuType={subMenuType}
        acceptTagChange={acceptTagChange}
        allUsers={allUsers}
        allGroups={allGroups}
        selectedConvo={selectedConvo}
        handleMenuClose={handleMenuClose}
        listFilter={listFilter}
        setReRenderKey={setReRenderKey}
        setSelectedConvo={setSelectedConvo}
      />
      <Modal
        isShowing={isShowing}
        style={{ width: '50%' }}
        hide={toggle}
        content={unassignModalContent()}
      />
      <Modal
        isShowing={isShowingCustomAlertModal}
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          height: '180px',
          overflowY: 'hidden !important'
        }}
        onClose={handleCloseCustomAlertModal}
        content={kbNotFoundModalContent()}
      />
    </div>
  );
}

Dialogues.propTypes = {
  navigation: PropTypes.object,
};
