import React, { useState, useEffect, useContext } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { SectionHeader } from 'components/SectionHeader';
import { CreateNewButton, Table } from 'stories';
import PaginationPanel from 'stories/Table/PaginationPanel';
import AuthContext from 'contexts/AuthContext';
import { roleCheck } from 'services/utility/RoleMatrix';
import moment from 'moment';
import { store } from 'contexts/GlobalStore';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import { listImages } from 'graphql/queries';
import { updateImage } from 'graphql/mutations';
import './ImageGallery.css';
import { parseS3KeyFromImageId } from 'services/utility/parseS3KeyFromImageId';

Storage.configure({ level: 'public' });

export default function ImageGallery(props) {
  const theme = useTheme();
  const tokens = useContext(AuthContext);

  const GlobalState = useContext(store);
  const { dispatch } = GlobalState;
  const {
    tableSort,
    spliceTableData,
    clearNextTokens,
    tableDataEmpty,
  } = GlobalState.state;

  const [imagesData, setImagesData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('All');

  const onClickCreateNew = () => { };

  useEffect(() => {
    // listImages();
    fetchImages();
    dispatch({ type: 'updateEditInfo', editInfo: { empty: true } });
    // console.log(fetchImages());
  }, []);

  useEffect(() => {
    if (searchActive) {
      console.log(searchKeyword);
      let searchResults = initialData.filter(
        img =>
          img.title.includes(searchKeyword) ||
          img.wide_message.includes(searchKeyword) ||
          img.submittedBy.includes(searchKeyword)
      );
      // console.log(searchKeyword, searchResults)
      setImagesData(searchResults);
      setSearchActive(false);
      setSearchKeyword('');
    }
  }, [searchActive]);

  const fetchImages = async () => {
    let filterQuery = {
      deleted: { eq: false },
    };

    if (tokens.role === 'approver' || tokens.role === 'content creator') {
      filterQuery.or = [];
      tokens.groups.forEach(element => {
        filterQuery.or.push({ group: { eq: element } });
      });
    }

    let resp = await API.graphql(
      graphqlOperation(listImages, {
        filter: filterQuery,
      })
    );

    let items = resp.data.listImages.items;

    let dataWithUrl = items.map(async image => {
      const presignedUrl = await Storage.get(
        parseS3KeyFromImageId(image.imageId),
        { bucket: process.env.REACT_APP_S3_DASHBOARD_ASSETS }
      );
      return { ...image, url: presignedUrl };
    });

    let imagesData = await Promise.all(dataWithUrl);

    let formattedData = formatData(imagesData);

    setInitialData(formattedData);
    setImagesData(formattedData);
    setRawData(imagesData);
  };

  const formatData = data => {
    const permissionsCheck = isInGroup => {
      if (tokens.role === 'admin' || tokens.role === 'publisher') return true;
      else if (isInGroup) return true;
      else return false;
    };

    return data.map(image => {
      // console.log(image);
      let isInGroup = tokens.groups.includes(image.group);

      return {
        imgPreview: image.url,
        title: image.title,
        wide_message: image.description,
        group: image.group,
        formattedCreatedAt: moment(image.createdAt).format('MM-DD-YYYY'),
        submittedBy: image.author,
        id: image.id,
        actions: {
          edit: permissionsCheck(isInGroup),
          delete: permissionsCheck(isInGroup),
        },
      };
    });
  };

  const onClickEdit = el => {
    console.log(el);
    console.log(rawData);

    rawData.forEach(data => {
      // console.log(el.id, data.id)
      if (el.id === data.id) {
        // console.log("match!", data);
        dispatch({
          type: 'updateEditInfo',
          editInfo: data,
        });
      }
    });

    props.history.push({
      pathname: '/add-image',
    });
  };

  const onSubmitDelete = async el => {
    console.log(el.id);

    await API.graphql(
      graphqlOperation(updateImage, {
        input: {
          id: el.id,
          deleted: true,
          deletedBy: { asurite: tokens.asurite, timestamp: moment().valueOf() },
        },
      })
    )
      .then(res => {
        console.log('image deleted: ', res);
        //ideally, let's remove the image from state rather than refetch
        fetchImages();
      })
      .catch(err => console.log('Error deleting image: ', err));
  };

  const tableLabels = [
    { title: 'Preview' },
    { title: 'Title' },
    { title: 'Description', wide: true },
    { title: 'Group' },
    { title: 'Created' },
    { title: 'Submitted By' },
    { title: 'Actions', alignRight: true, notClickable: true },
  ];

  const onClickSearch = () => {
    if (searchKeyword === '') {
      return;
    } else {
      // setNextTokenList([]);
      setSearchActive(true);
      // if (currentPage === 1) {
      //   setRerenderKey(Date.now());
      // } else {
      //   setCurrentPage(1);
      // }
    }
  };

  const onClickReset = () => {
    // setSearchActive(false);
    setImagesData(initialData);
    setSearchKeyword('');
  };

  const onChangeSearch = value => {
    setSearchKeyword(value);
  };

  const onSelectFilter = value => {
    setCurrentPage(1);
    dispatch({
      type: 'clearNextTokens',
      clearNextTokens: true,
    });
    if (value === filter || !value) {
      setFilter('All');
      dispatch({ type: 'changeTableFilter', tableFilter: 'All' });
    } else {
      setFilter(value);
      dispatch({ type: 'changeTableFilter', tableFilter: value });
    }
  };

  return (
    <div
      style={theme.components.pageContainer}
    // key={rerenderKey}
    >
      <div className="SectionHeader">
        <SectionHeader
          onChangeSearch={onChangeSearch}
          onClickSearch={onClickSearch}
          onClickReset={onClickReset}
          onSelectFilter={onSelectFilter}
          noSort
          noModality
          noFilter
          noGroup
          rightView={
            roleCheck(tokens.role, 'images', 'create') && (
              <div onClick={onClickCreateNew}>
                <button class="AddImage">
                  <CreateNewButton to="/add-image" label="Add New Image" />
                </button>
              </div>
            )
          }
        />
      </div>
      <div className="TablePage">
        <Paper style={theme.components.paper}>
          <div>
            <Table
              title="Image Gallery"
              labels={tableLabels}
              data={imagesData}
              onClickEdit={onClickEdit}
              onSubmitDelete={onSubmitDelete}
              onClickSearch={onClickSearch}
              onClickReset={onClickReset}
              onChangeSearch={onChangeSearch}
              onSelectFilter={() => { }}
              noSort={true}
              noFilter={true}
              imageTable
              noSearch
            />
            {/* <PaginationPanel
              theme={theme}
              currentPage={currentPage}
              pageCount={Math.ceil(totalResults / pageLimit)}
              onClickFetchMore={onClickFetchMore}
              onClickFetchLess={onClickFetchLess}
              jumpToPageOptions={jumpToPageOptions}
              onChangeJumpToPage={onChangeJumpToPage}
            /> */}
          </div>
        </Paper>
      </div>
    </div>
  );
}
