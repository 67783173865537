import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as mutations from "graphql/mutations";
import * as queries from "graphql/queries";

import { Button } from "stories";

import "./Campaign.scss";
import "./CampaignTabs.scss";

import AutorenewIcon from "@material-ui/icons/Autorenew";
import GetAppIcon from "@material-ui/icons/GetApp";

import { CSVLink, CSVDownload } from "react-csv";
import { readString } from "react-papaparse";

import ImportSummaryX from "./TabSummary";
import {
  CampaignAnalyticsFailures,
  CampaignAnalyticsOptOut,
  CampaignAnalyticsOverview,
} from "./TabAnalytics";

Storage.configure({ level: "public" });

export default function CampaignAnalyticsWithTabs(props) {
  let { file, editInfo } = props;

  const theme = useTheme();

  const [titleTabSelection, setTitleTabSelection] = useState(0);
  const [subTabSelection, setSubTabSelection] = useState([0, 0, 0]);
  const [didInitReactiveGoldBar, setDidInitReactiveGoldBar] = useState(false);
  const [errorMessageCache, setErrorMessageCache] = useState([]);
  const [rawFileData, setRawFileData] = useState([]);
  const [CSVFileStorage, setCSVFileStorage] = useState(null);
  const [data, setData] = useState({});
  const [tabDataCache, setTabDataCache] = useState({});

  const analyticsArr = [
    <CampaignAnalyticsOverview />,
    <CampaignAnalyticsFailures />,
    <CampaignAnalyticsOptOut />,
  ];
  const importSummaryArr = [
    <ImportSummaryX
      importSummaryData={props.importSummaryData}
      fileName={props.updateFileLoad}
      reloadCsv={props.updateCsv}
    />,
    <ImportSummaryX
      type="error"
      importSummaryData={props.importSummaryData}
      fileName={props.updateFileLoad}
      reloadCsv={props.updateCsv}
    />,
  ];

  if (props.importVariables) {
    importSummaryArr.push(
      <ImportSummaryX
        type="vars"
        importSummaryData={props.importSummaryData}
        fileName={props.updateFileLoad}
        reloadCsv={props.updateCsv}
        importVariables={props.importVariables}
      />
    );
  }

  const titleTabs = props.id
    ? ["Campaign Analytics", "Import Summary"]
    : ["Import Summary"];
  const subTabs = props.id
    ? [
        ["Overview", "Failures", "Opt-Out"],
        ["Summary", "Errors"],
      ]
    : [["Summary", "Errors"]];

  if (props.importVariables) {
    subTabs[subTabs.length - 1].push("Variables");
  }

  const componentReference = props.id
    ? [analyticsArr, importSummaryArr]
    : [importSummaryArr];

  const subTabRefs = [
    [useRef(null), useRef(null), useRef(null)],
    [useRef(null), useRef(null), useRef(null)],
    [],
  ];
  const reactiveGoldBar = useRef(null);
  const subTabContainerRef = useRef(null);

  const moveReactiveGoldBar = (titleIndex, subIndex) => {
    if (
      typeof subTabRefs[titleIndex][subIndex] !== "undefined" &&
      subTabRefs[titleIndex][subIndex].current !== null
    ) {
      reactiveGoldBar.current.style.transform =
        "translateX(" +
        parseInt(
          subTabRefs[titleIndex][subIndex].current.getBoundingClientRect().x -
            subTabContainerRef.current.getBoundingClientRect().x
        ) +
        "px)";
      reactiveGoldBar.current.style.width =
        parseInt(
          subTabRefs[titleIndex][subIndex].current.getBoundingClientRect().width
        ) + "px";
    }
  };

  useEffect(() => {
    if (!didInitReactiveGoldBar) {
      moveReactiveGoldBar(0, 0);
      setDidInitReactiveGoldBar(true);
    }
  }, []);

  //window resize for reactiveGoldBar
  useEffect(() => {
    if (window.onresize === null) {
      window.onresize = function() {
        moveReactiveGoldBar(
          titleTabSelection,
          subTabSelection[titleTabSelection]
        );
      };
    }

    // component unmount
    return () => {
      if (window.onresize !== null) {
        window.onresize = null;
      }
    };
  }, [titleTabSelection, subTabSelection]);

  const titleTabSelect = (index) => {
    setTitleTabSelection(index);
    setTimeout(() => {
      moveReactiveGoldBar(index, subTabSelection[index]);
    }, 30);
  };

  const subTabSelect = (index) => {
    let newSubTabSelection = [...subTabSelection];
    newSubTabSelection[titleTabSelection] = index;
    setSubTabSelection(newSubTabSelection);
    moveReactiveGoldBar(titleTabSelection, index);
  };

  const renderTitleTabs = () => {
    return titleTabs.map((string, index) => {
      return (
        <div
          className="titleTab"
          style={
            titleTabSelection === index
              ? { color: "rgb(255, 198, 39)" }
              : { color: "white" }
          }
          onClick={() => {
            titleTabSelect(index);
          }}
        >
          <div className="titleTab__text">{string}</div>
        </div>
      );
    });
  };

  const renderSubTabs = () => {
    if (subTabs[titleTabSelection].length > 0) {
      return (
        <div className="subTab__container" ref={subTabContainerRef}>
          {subTabs[titleTabSelection].map((string, index) => {
            return (
              <div
                className="subTab"
                onClick={() => {
                  subTabSelect(index);
                }}
                ref={subTabRefs[titleTabSelection][index]}
                onMouseEnter={() => {
                  //don't move on mouse enter
                  /*moveReactiveGoldBar(titleTabSelection, index);*/
                }}
                onMouseLeave={() => {
                  //don't move back on mouse leave
                  /*moveReactiveGoldBar(titleTabSelection, subTabSelection[titleTabSelection]);*/
                }}
              >
                <div className="subTab__text">{string}</div>
              </div>
            );
          })}
          <div className="reactiveGoldBar" ref={reactiveGoldBar}></div>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderSelectedPanelSections = () => {
    let panelTitle =
      subTabs[titleTabSelection][subTabSelection[titleTabSelection]];

    try {
      let comp =
        componentReference[titleTabSelection][
          subTabSelection[titleTabSelection]
        ];
      if (comp) {
        return React.cloneElement(
          comp,
          {
            id: props.id,
            uploadId: props.uploadId,
            editInfo: props.editInfo,
            panelTitle: panelTitle,
            importSummaryData: props.importSummaryData,
            tabDataCache: tabDataCache,
            setTabDataCache: setTabDataCache,
            canPublish: props.canPublish,
            showBlueBanner: props.showBlueBanner,
            isSendingIntroMessage: props.isSendingIntroMessage,
            showProcessingMessage: props.showProcessingMessage,
            estSendMinutes: props.estSendMinutes,
            // Add more props here if needed
          },
          null
        );
      } else {
        return null;
      }
    } catch (e) {
      console.log("ERRORRED: ", e);
      return null;
    }
  };

  return (
    <div className="rightSideTabs">
      <div className="titleTab__container">{renderTitleTabs()}</div>
      {renderSubTabs()}
      <div className="panelSection">{renderSelectedPanelSections()}</div>
    </div>
  );
}

CampaignAnalyticsWithTabs.propTypes = {
  navigation: PropTypes.object,
};
