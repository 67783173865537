import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import _ from 'lodash';

import useForm, { FormContext } from 'react-hook-form';
import { Element } from 'react-scroll';

import SendIcon from '@material-ui/icons/Send';
import ImageIcon from '@material-ui/icons/Image';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as AlertIcon } from '../../assets/icons/icon-alert-2.svg';
import { default as IconAlert2 } from '../../components/Icons/icon-alert-2';
import { ReactComponent as NeedsReviewIcon } from '../../assets/icons/icon-needs-review.svg';
import { ReactComponent as NeedsKendraReviewIcon } from '../../assets/icons/icon-needs-kendra-review.svg';
import { ReactComponent as EscalationIcon } from '../../assets/icons/maroon-arrow.svg';
import { ReactComponent as UnreadIcon } from '../../assets/icons/icon-unread.svg';
import { ReactComponent as WebIcon } from '../../assets/icons/icon-web.svg';
import { ReactComponent as SMSIcon } from '../../assets/icons/icon-sms.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/icon-phone.svg';
import { ReactComponent as IgnoreIcon } from '../../assets/icons/icon-ignore.svg';
import { ReactComponent as IgnoreActiveIcon } from '../../assets/icons/icon-ignore-active.svg';
import { ReactComponent as ThumbsDownIcon } from '../../assets/icons/icon-match.svg';
import { ReactComponent as ThumbsDownActiveIcon } from '../../assets/icons/icon-match-active.svg';
import { ReactComponent as ThumbsUpIcon } from '../../assets/icons/icon-no-match.svg';
import { ReactComponent as ThumbsUpActiveIcon } from '../../assets/icons/icon-no-match-active.svg';
import { ReactComponent as HasNoteIcon } from '../../assets/icons/icon-has-note.svg';
import SvgIcon from '@material-ui/core/SvgIcon';

import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Truncate from 'react-truncate';
import moment from 'moment';
import AddTagModal from './AddTagModal';
import { useModal } from 'hooks';
import {
  Textarea,
  FieldExtras,
  Modal,
  ModalContent,
  Input,
  ButtonSelect,
  Select,
} from 'stories';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { store } from 'contexts/GlobalStore';

import { idGenerator } from 'services/utility/util';
import * as tagHelper from './Helpers/tagHelper.js';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import {
  handleDialogues,
  tagByMessageId,
  convoTagByConvoId,
  tagByConvoId,
  escalateConvo,
  websocketConnectionByConvoId,
  getKnowledgeBase,
  getEscalation,
  getCriticalEscalation,
  getCampaignCompleted,
  getCampaignRunning,
} from 'graphql/queries';
import {
  createChatbotMessageTag,
  deleteChatbotMessageTag,
  createDialogueNotes,
  updateChatbotMessageTag,
  createChatbotConvoTag,
  updateChatbotConvoTag,
  createEscalationHistory,
} from 'graphql/mutations';

import { environments } from 'services';

import AuthContext from 'contexts/AuthContext';
import './Messages.scss';
import 'components/Sunny/Sunny.css';
import { graphql } from 'graphql';

Storage.configure({ level: 'public' });

const NEEDSREIVEW_TAG = 'needsReview ';
const PREVIOUSLYASSISTED_TAG = 'previouslyAssisted ';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      //height: "7rem",
      borderRadius: '0px',
      fontSize: '0.9rem',
      // borderBottom: "1px solid #d5d5d5",
    },
  },
}));
const useOutlinedStyles = makeStyles(theme => ({
  root: {
    '& $notchedOutline': {
      borderWidth: 0,
    },
    flexDirection: 'column',
    height: '7em',
    // display: "inline"
  },
  focused: {},
  notchedOutline: {},
}));

// This function renders a different icon and tag for each type
const renderIconType = (type) => {
  const iconTypes = {
    auth: WebIcon,
    sms: SMSIcon,
    voice: PhoneIcon
  }

  const textTypes = {
    auth: 'WEB',
    sms: 'SMS',
    voice: 'PHONE'
  }

  return (
    <>
      <SvgIcon style={{ transform: 'scale(0.8)' }}
        viewBox="-5 -1 15 15"
        component={iconTypes[type]}
      />
      {textTypes[type]}
    </>)
}

export default function Messages(props) {
  const tokens = useContext(AuthContext);
  const classes = useStyles();
  const GlobalState = useContext(store);
  const dispatchGlobal = GlobalState.dispatch;
  const outlinedClasses = useOutlinedStyles();
  const { isShowing, toggle } = useModal();

  const { showRefreshTokenMessage } = GlobalState.state;

  const TAG_ENDER =
    'needsReview ' +
    (props.listFilter ? props.listFilter.split(' ')[1] : 'true');

  const methods = useForm();

  const messagesEndRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [allowedToLoadMore, setAllowedToLoadMore] = useState(true);
  const [count, setCount] = useState(0);
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(0);
  const [sendingMsgs, setSendingMsgs] = useState([]);
  const [showAddTag, setShowAddTag] = useState(false);
  const [tagUserInfo, setTagUserInfo] = useState({});
  const [currentTextField, setCurrentTextField] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [textFile, setTextFile] = useState(null);
  const [imageSignedFile, setImageSignedFile] = useState(null);
  const [textSignedFile, setTextSignedFile] = useState(null);
  const [resetFiles, setResetFiles] = useState(new Date().getTime());
  const [resetFile, setResetFile] = useState(null);
  const [liveAgentTimerReadout, setLiveAgentTimerReadout] = useState(
    'CHATBOT PAUSED'
  );
  const [liveAgentTimerInterval, setLiveAgentTimerInterval] = useState(false);
  const [doClearTimer, setDoClearTimer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [clickedMessage, setClickedMessage] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [customTagValue, setCustomTagValue] = useState(null);
  // const [myReviewTags, setMyReviewTags] = useState(["needsReview true"]);

  const [charCountUIEnabled, setCharCountUIEnabled] = useState(false);
  const [charCountSelectedIds, setCharCountSelectedIds] = useState([]);
  const [charCountSelectedMessages, setCharCountSelectedMessages] = useState(
    []
  );

  const [isReviewPending, setIsReviewPending] = useState(false);
  const [manualEscalationId, setManaulEscalationId] = useState({});

  const [
    reviewPendingMessageIdAndType,
    setReviewPendingMessageIdAndType,
  ] = useState(['', '']);

  const logCampaign = e => {
    if (e.ctrlKey && e.key === 'i') console.log(props);
  };

  useEffect(() => {
    document.addEventListener('keydown', logCampaign);
    return () => {
      document.removeEventListener('keydown', logCampaign);
    };
  }, [props.selectedConvo]);

  useEffect(() => {
    if (props.searchForMessage) {
      searchForMsg();
    }
  }, [props.searchForMessage]);

  useEffect(() => {
    console.log('!!! refreshTokenMessage is', showRefreshTokenMessage);
  }, [showRefreshTokenMessage]);

  useEffect(() => {
    //console.log("!!!a checking re-sent enter message");

    if (props.connectionStatus === 'Open') {
      props.sendMessage(
        JSON.stringify({
          action: 'addConvo',
          convoId: props.selectedConvo.convoId,
          agentChatting: false,
          asurite: 'SELF',
          phoneNumber: 'NONE',
          token: props.wssToken,
          type: 'BASIC',
          agentDetails: {
            asurite: tokens.asurite,
            displayName: tokens.displayName,
          },
        })
      );

      setTimeout(() => {
        props.sendMessage(
          JSON.stringify({
            action: 'addConvo',
            convoId: props.selectedConvo.convoId,
            agentChatting: props.selectedConvo.agentChatting,
            asurite: props.asurite,
            phoneNumber: props.selectedConvo.phoneNumber,
            token: props.wssToken,
            type: props.selectedConvo.type,
            agentDetails: {
              asurite: tokens.asurite,
              displayName: tokens.displayName,
            },
          })
        );
      }, 3000);

    }
  }, [props.connectionStatus]);

  // Every time that selectedConvo or refreshVariable states changed, the below function will be called
  useEffect(() => {
    setMessages([]);
    setPage(1);
    // This is the important function
    fetchMessages(true);
    fetchConvoConnections();
    getNeedsReview();

    // console.log("!!! Refresh", refreshVariable)

    if (props.currentConvo) {
      props.checkAgentChattingStatus(props.currentConvo.convoId);
    }

    let intervalID;

    if (
      typeof props.selectedConvo.agentChatting !== 'undefined' &&
      props.selectedConvo.agentChatting === true
    ) {
      intervalID = setInterval(getTimerReadout, 332);
      setLiveAgentTimerInterval(intervalID);
      setLiveAgentTimerReadout('CHATBOT PAUSED');

      dispatchGlobal({
        type: 'setShowRefreshTokenMessage',
        showRefreshTokenMessage: props.selectedConvo.convoId,
      });

      //find table value update
      setTimeout(function () {
        let resp = API.graphql(
          graphqlOperation(websocketConnectionByConvoId, {
            convoId: props.selectedConvo.convoId,
          })
        ).then(resp => {
          console.log('websockets are', resp);
          if (resp.data.websocketConnectionByConvoId.items.length === 0) {
            console.log('setting', props.selectedConvo.convoId);
          }
        });
      }, 3000);
    } else if (
      typeof props.selectedConvo.agentChatting !== 'undefined' &&
      props.selectedConvo.agentChatting === false
    ) {
      if (liveAgentTimerInterval !== false) {
        // console.log("timer clearing interval", liveAgentTimerInterval);
        clearInterval(liveAgentTimerInterval);
        setLiveAgentTimerInterval(false);
      }
    }

    return () => {
      if (typeof intervalID !== 'undefined') {
        // console.log("timer clearing interval", intervalID);
        clearInterval(intervalID);
        setLiveAgentTimerInterval(false);
      }
    };
  }, [props.selectedConvo, props.refreshVariable]);

  const getNeedsReview = async () => {
    if (props.selectedConvo && props.selectedConvo.convoId) {
      let resp = await API.graphql(
        graphqlOperation(handleDialogues, {
          operation: 'getNeedsReview',
          convoId: props.selectedConvo.convoId,
        })
      );

      let result = JSON.parse(resp.data.handleDialogues);
      props.setMsgsInReview(result);
    } else {
      console.log('could not query');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getNeedsReview();
    }, 2000);
  }, [props.updateNeedsReview]);

  const searchForMsg = async () => {
    let payload = {
      user: props.selectedConvo.asurite,
      page: page,
      convoId: props.selectedConvo.convoId,
      messageId: props.searchForMessage,
    };

    let resp = await API.graphql(
      graphqlOperation(handleDialogues, {
        operation: 'searchForMessage',
        ...payload,
      })
    );

    let result = JSON.parse(resp.data.handleDialogues);

    let messages = JSON.parse(result.messages);

    messages = messages.sort(function (a, b) {
      return a.latest - b.latest;
    });

    setMessages(prev => {
      let all = messages.concat(prev);
      let have = {};
      for (var i = all.length - 1; i >= 0; --i) {
        if (!have[all[i].messageId]) {
          have[all[i].messageId] = true;
        } else {
          all.splice(i, 1);
        }
      }
      return all;
    });
    setPage(result.page + 1);
    props.setSearchForMessage(null);
  };

  const getTimerReadout = () => {
    let readout = props.readLiveAgentTimer(props.selectedConvo.convoId);

    setLiveAgentTimerReadout(readout);

    if (readout.includes('RESUMING')) {
      setLiveAgentTimerReadout(readout);
      setDoClearTimer(true);
    }
  };

  useEffect(() => {
    if (doClearTimer === true) {
      clearInterval(liveAgentTimerInterval);
      setLiveAgentTimerInterval(false);
      setDoClearTimer(false);
    }
  }, [doClearTimer]);

  const approveUser = data => {
    // This check needs to be left in until
    // next prod push, after can be removed
    // completely

    let sender = data.convo.asurite
      ? data.convo.asurite
      : data.convo.phoneNumber;

    return (
      (props.approvedUsers &&
        props.approvedUsers.indexOf(data.convo.asurite) > -1) ||
      (sender && sender.indexOf('-' + tokens.env + '-') > -1) ||
      data.convo.phoneNumber !== undefined
    );
  };

  const confirmAddTag = () => {
    setShowAddTag(false);
    setTagUserInfo({});
  };

  const handleIncomingMessage = async data => {
    let foundInd = sendingMsgs.indexOf(data.messageId);
    let msgsClone = _.clone(messages);

    if (data.image) {
      data.image = await getUrl(data.image);
    }

    if (foundInd > -1) {
      setSendingMsgs(prev => prev.splice(foundInd, 1));

      for (var i = msgsClone.length - 1; i >= 0; --i) {
        if (msgsClone[i].messageId === data.messageId) {
          data.image = msgsClone[i].image;
          msgsClone[i] = data;
          break;
        }
      }
    } else {
      msgsClone.push(data);
      setCount(prev => prev + 1);
    }

    msgsClone = msgsClone.sort(function (a, b) {
      return a.latest - b.latest;
    });

    setMessages(msgsClone);

    if (data.agentAsurite !== tokens.asurite) {
      if (data.agentAction === 'enter') {
        props.setOtherAgentChatting(true);
      } else if (data.agentAction === 'leave') {
        props.setOtherAgentChatting(false);
      }
    }
  };

  const handleAgentTimers = data => {
    if (props.liveAgentTimers.length === 0) {
      data.convo.agentChatting = props.selectedConvo.agentChatting;
    } else {
      let indexAt = -1;

      props.liveAgentTimers.some((timer, index) => {
        if (timer.getLabel() === data.convo.convoId) {
          let maxTotalInSeconds = 300;
          let currentMinutes = timer.format('%m');
          let currentSeconds = timer.format('%s');
          let currentTotalInSeconds =
            parseInt(currentSeconds) + parseInt(currentMinutes) * 60;

          if (currentTotalInSeconds >= maxTotalInSeconds) {
            indexAt = -1;
          } else {
            indexAt = index;
          }
          return true;
        }
      });

      if (indexAt !== -1) {
        data.convo.agentChatting = true;
      }
    }
  };

  useEffect(() => {
    async function checkLastMessageChanges() {
      try {
        console.log("[Messages.js] lastMessage updated", props.lastMessage);
        if (props.lastMessage !== null && props.lastMessage.data && typeof JSON.parse(props.lastMessage.data).cms_identifier === "undefined") {
          console.log("[Messages.js] is Dialogue message because of", typeof JSON.parse(props.lastMessage.data).cms_identifier);

          let data = JSON.parse(props.lastMessage.data);

          console.log('INCOMING: ', data);

          console.log('checkLastMessageChanges on selectedCampaign:', props.selectedCampaign);

          if (props.selectedCampaign && props.selectedCampaign.id) {

            let userdIdArray = [];

            if(data.type === 'webSocketUpdate'){
              userdIdArray = [data.asurite];
            }

            if(data.type === 'convoUpdate'){
              userdIdArray = [data.convo.asurite, data.convo.phoneNumber];
            }

            if(data.type === 'userUpdate'){
              userdIdArray = [data.user.asurite, data.user.phoneNumber];
            }

            let queryArray = [],
              graphqlOperationSelected = '';

            if (props.selectedCampaign && props.selectedCampaign.status === 8) {
              queryArray = userdIdArray.map((userId) =>
                API.graphql(
                  graphqlOperation(getCampaignRunning, {
                    userId: userId,
                  }),
                ),
              );
              graphqlOperationSelected = 'getCampaignRunning';
            }

            if (props.selectedCampaign && props.selectedCampaign.status === 9) {
              queryArray = userdIdArray.map((userId) =>
                API.graphql(
                  graphqlOperation(getCampaignCompleted, {
                    userId: userId,
                    campaignId: props.selectedCampaign.id,
                  }),
                ),
              );
              graphqlOperationSelected = 'getCampaignCompleted';
            }

            if (props.selectedCampaign && props.selectedCampaign.id) {
              const queryArrayResult = await Promise.allSettled(queryArray);
              console.log('queryArrayResult', queryArrayResult);

              const queryArrayData = queryArrayResult
                .filter(
                  (r) => r.value && r.value.data && r.value.data[graphqlOperationSelected],
                )
                .map((r) => r.value.data[graphqlOperationSelected]);

              if (!queryArrayData.find((r) => r.campaignId === props.selectedCampaign.id)) {
                return false;
              }
            }
          }

          if (
            data.messageId &&
            data.convoId &&
            data.convoId === props.selectedConvo.convoId
          ) {
            handleIncomingMessage(data);
          } else if (data.type && data.type === 'convoUpdate') {
            dispatchGlobal({
              type: 'setShowRefreshTokenMessage',
              showRefreshTokenMessage: '',
            });
            handleAgentTimers(data);
            props.setLiveConvo(data.convo, data.msg);
          } else if (data.type && data.type === 'userUpdate') {
            props.updateUser(data.user);
          } else if (data.type && data.type === 'webSocketUpdate') {
            props.setWebSocketId(data);
          } else if (data.type && data.type === 'tagUpdate') {
            let msgs = data.data;

            console.log('HITTING TAG UPDATE: ', msgs);

            // firing acceptTagChange for rerendering triageQueue on 2nd miss
            for (let msg of msgs) {
              if (
                msg.tag === 'groupAssigned pro_off1536177925092' ||
                msg.tag === 'groupAssigned enr_ser1536177655900'
              ) {
                props.acceptTagChange(
                  msg.tag.split(' ')[1],
                  null,
                  null,
                  msg.convoId
                );
              }
            }

            let upds = {
              convoId: {},
              messageId: {},
            };

            for (var i = 0; i < msgs.length; ++i) {
              upds[msgs[i].type][msgs[i][msgs[i].type]] = msgs[i];
            }

            if (Object.keys(upds.messageId).length) {
              updateMessage(upds.messageId);
            }

            console.log(upds);

            if (Object.keys(upds.convoId).length) {
              console.log('upds.convoId has length');
              props.updateConvoTag(upds.convoId);
            }
          } else {
            console.log('UNKNOWN SOKET PUSH: ', data);
          }
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    checkLastMessageChanges();
  }, [props.lastMessage]);

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(scrollToBottom, [count]);

  const getUrl = async url => {
    let signedUrl = null;

    if (url) {
      if (url.indexOf('|') > -1) {
        try {
          let arr = url.split('|');
          signedUrl = await Storage.get(arr[1], { bucket: arr[0] });
        } catch (e) { }
      } else {
        signedUrl = url;
      }
    }

    return signedUrl;
  };

  const fetchMessages = async isInitialLoad => {
    if (
      props.selectedConvo &&
      props.selectedConvo.convoId &&
      props.selectedConvo.convoId !== ''
    ) {
      let result = [];

      let payload = {
        user: props.selectedConvo.asurite,
        phoneNumber: props.selectedConvo.phoneNumber,
        page: isInitialLoad ? 1 : page,
        type: props.selectedConvo.type,
        convoId: props.selectedConvo.convoId,
      };

      let [resp, resp2] = await Promise.all([
        API.graphql(
          graphqlOperation(handleDialogues, {
            options: {
              fetchPolicy: 'no-cache',
            },
            operation:'pullActiveConvo',
          ...payload,
          })
        ),
        API.graphql(
          graphqlOperation(handleDialogues, {
            options: {
              fetchPolicy: 'no-cache',
            },
            operation:'pullActiveConvoTags',
          ...payload,
          })
        )
      ]);

      result = JSON.parse(resp.data.handleDialogues);
      let dialoguesProp = !result.dialogueProperties
        ? {}
        : result.dialogueProperties;

      props.setDialogueProperties(dialoguesProp);

      let messages = JSON.parse(result.messages);

      let proms = [];

      let result2 = [];
      result2 = JSON.parse(resp2.data.handleDialogues);
      let messageTags = JSON.parse(result2.messageTags);

      const convoIdToTags = {};
      for (const { messageId, tag } of messageTags) {
        if (tag) {
          if (typeof convoIdToTags[messageId] === "undefined") {
            convoIdToTags[messageId] = [];
          }
          convoIdToTags[messageId].push(tag);
        }
      }

      for (var i = 0; i < messages.length; ++i) {
        if (messages[i].image) {
          messages[i].image = await getUrl(messages[i].image);
        }
        //add tags from resp2
        if (typeof convoIdToTags[messages[i].messageId] !== "undefined") {
          messages[i].tags = convoIdToTags[messages[i].messageId];
        }
      }

      messages = messages.sort(function (a, b) {
        return a.latest - b.latest;
      });

      // setMessages((prev) => messages.concat(prev));
      setMessages(prev => {
        let all = messages.concat(prev);
        let have = {};
        for (var i = all.length - 1; i >= 0; --i) {
          if (!have[all[i].messageId]) {
            have[all[i].messageId] = true;
          } else {
            all.splice(i, 1);
          }
        }
        console.log('MESSAGES: ', all)
        return all
      });

      if (isInitialLoad) {
        setLoading(false);
        // props.ConvosClickActionToggle(true);
        if (props.connectionStatus === 'Open') {
          props.sendMessage(
            JSON.stringify({
              action: 'addConvo',
              convoId: props.selectedConvo.convoId,
              agentChatting: props.selectedConvo.agentChatting,
              asurite: props.asurite,
              phoneNumber: props.selectedConvo.phoneNumber,
              token: props.wssToken,
              type: props.selectedConvo.type,
              agentDetails: {
                asurite: tokens.asurite,
                displayName: tokens.displayName,
              },
            })
          );
        }
        if (messagesEndRef && messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView();
        }
      }
    }

    if (!props.selectedConvo) {
      setLoading(false);
      props.ConvosClickActionToggle(true);
    }
    setAllowedToLoadMore(true);
  };

  const fetchConvoConnections = async () => {
    if (props.selectedConvo && props.selectedConvo.convoId) {
      const result = await axios.get(
        process.env.REACT_APP_APIGATEWAY_CHATBOT +
        '/convo-websockets?convoId=' +
        props.selectedConvo.convoId
      );
      let activeSockets = result.data;
      for (let i = 0; i < activeSockets.length; i++) {
        if (activeSockets[i].agentDetails.asurite !== tokens.asurite) {
          props.setOtherAgentChatting(true);
          break;
        }
      }
    }
  };

  const handleScroll = e => {
    const el = e.target;
    if (allowedToLoadMore && el.scrollTop < 200 && messages.length >= 50) {
      setAllowedToLoadMore(false);
      setPage(prev => prev + 1);
      fetchMessages(false);
    }
  };

  const handleChange = prop => event => {
    setMessage(event.target.value);
  };

  const handleSend = async () => {
    if (message !== '') {
      let sendId = idGenerator();
      setSendingMsgs(prev => prev.concat(sendId));

      let signedImage = await getUrl(imageFile);

      let payload = {
        action: 'sendMessage',
        convoId: props.selectedConvo.convoId,
        message: message,
        image: imageFile,
        textFile: textFile,
        token: props.wssToken,
        sendId: sendId,
        webSocketId: props.selectedConvo.webSocketId,
        phoneNumber: props.selectedConvo.phoneNumber,
        type: props.selectedConvo.type,
        asurite: props.asurite,
        agentDetails: {
          asurite: tokens.asurite,
          displayName: tokens.displayName,
        },
      };

      setResetFiles(new Date().getTime());
      setImageFile(null);
      setTextFile(null);

      setImageSignedFile(null);
      setTextSignedFile(null);

      let tmpMsg = {
        message,
        convoId: props.selectedConvo.convoId,
        messageId: sendId,
        image: signedImage,
        content: message,
        sending: true,
        phoneNumber: '+14243734261',
      };

      setMessages(prev => prev.concat(tmpMsg));
      setCount(prev => prev + 1);

      props.sendMessage(JSON.stringify(payload));
      setMessage('');
      document.getElementById('messageInput').focus();
    }
  };

  const downloadFile = async file => {
    try {
      let arr = file.split('|');
      let url = await Storage.get(arr[1], { bucket: arr[0] });

      var a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      a.setAttribute('target', '_blank');
      document.body.appendChild(a);
      a.href = url;
      a.click();
      a.remove();
    } catch (e) { }
  };

  const handleBtnOptnClick = v => {
    setCustomTagValue(v);
  };

  const getModalContent = () => {
    if (modalType === 'note') {
      let formData = [
        {
          title: 'MESSAGE',
          required: true,
          component: <Textarea form required name="message" />,
        },
      ];

      return (
        <ModalContent
          form={true}
          formData={formData}
          title={`Add Note`}
          onSubmit={addMessageNoteOnSubmit}
          toggle={toggle}
        />
      );
    } else if (modalType === 'tag') {
      let tagsToUse = props.customTags.slice(0, 15);

      let formData = [
        {
          title: 'TAG',
          required: true,
          component: (
            <Input
              name="tag"
              value={customTagValue}
              form="true"
              required
              placeholder="Tag Value"
              maxlength={20}
            />
          ),
        },
        {
          title: '',
          component: (
            <ButtonSelect
              name="btns"
              acceptClick={handleBtnOptnClick}
              options={tagsToUse}
            />
          ),
        },
      ];

      return (
        <ModalContent
          form={true}
          formData={formData}
          title={`Add Tag`}
          onSubmit={tagOnSubmit}
          toggle={toggle}
        />
      );
    } else if (modalType === 'escalate') {
      let formData = [
        {
          subtext: 'Select appropriate escalation below',
          // bold: false,
          component: (
            <Select
              name="escalation"
              onChange={val => setManaulEscalationId(val)}
              options={props.escalationData}
            />
          ),
        },
      ];

      return (
        <ModalContent
          form={true}
          lessTitleMargin
          formData={formData}
          title="Escalation"
          text={
            !GlobalState.state.loadingFormSubmission ? 'SEND NOW' : 'SENDING'
          }
          onSubmit={escalateMessageOnSubmit}
          toggle={toggle}
        />
      );
    } else if (modalType === 'resolveEscalation') {
      return (
        <ModalContent
          title="Resolve Escalation?"
          buttonLabel={
            !GlobalState.state.loadingFormSubmission ? 'RESOLVE' : 'RESOLVING'
          }
          onSubmit={resolveEscalationTag} // resolve Escalation function
          toggle={toggle}
        />
      );
    }
  };

  useEffect(() => { }, [props.customTags]);

  const tagOnSubmit = async d => {
    try {
      if (d.tag) {
        let postData = {};
        // console.log(d);
        let payload = {
          convoId: clickedMessage.convoId,
          messageId: clickedMessage.messageId,
          tag: 'customTag ' + d.tag,
        };

        await tagHelper.addTag(payload, createChatbotMessageTag);

        setClickedMessage(null);
        setModalType(null);
        toggle();
        if (props.customTags.indexOf(d.tag) === -1) {
          let tagsCp = _.clone(props.customTags);
          tagsCp.unshift(d.tag);
          props.setCustomTags(tagsCp);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //TODO: make generateUID a universal helper
  const generateUID = () => {
    let firstPart = (Math.random() * 46656) | 0;
    let secondPart = (Math.random() * 46656) | 0;
    firstPart = ('000' + firstPart.toString(36)).slice(-3);
    secondPart = ('000' + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  };

  const resolveEscalationTag = async () => {
    const { messageId, tags } = clickedMessage;
    const escalationTag = tags.filter(tag => {
      if (tag.includes('escalation')) {
        return tag;
      }
    })[0];

    try {
      let resp = await tagHelper.removeMessageTag(messageId, escalationTag);

      const escalationId = escalationTag.split(' ')[1];

      const escalationResp = await API.graphql(
        graphqlOperation(getEscalation, { id: escalationId })
      );

      const escalation = escalationResp.data.getEscalation;
      //add to escalation history table
      const createEscalationHistoryResp = await API.graphql(
        graphqlOperation(createEscalationHistory, {
          input: {
            id: generateUID(),
            escalationId: escalation.id,
            escalationName: `RESOLVED: ${escalation.escalationName}`,
            escalationType: escalation.escalationType,
            convoId: clickedMessage.convoId,
            group: escalation.group,
            user: tokens.asurite,
            timestamp: new Date().getTime() + '',
            messageId: clickedMessage.messageId,
            message: clickedMessage.content,
          },
        })
      );

      handleMenuClose();
      toggle();
    } catch (err) {
      console.log('Error Resolving Escalation: ', err);
    }
  };

  const tagOnReview = async (messageObject, type) => {
    setIsReviewPending(true);
    setReviewPendingMessageIdAndType([messageObject.messageId, type]);

    try {
      /*
        get previous message from the sender by starting with the current message
        and scanning each previous message for an asurite that is not ASU-sunnybot

        We also modify the message in the local state to update the UI
      */

      let currentMessageId = messageObject.messageId;
      let currentMessageIndex;
      let previousMessage = { asurite: 'ASU-sunnybot' }; //default so that the loop keeps checking
      let previousMessageIndex;

      let iterations = messages.length; //hard limit of iterations so that the do while loop will break after checking all messages

      do {
        messages.some((message, index) => {
          if (message.messageId === currentMessageId) {
            currentMessageIndex = index;
            previousMessageIndex = index - 1;
            return true;
          }
        });
        if (previousMessageIndex > -1) {
          previousMessage = messages[previousMessageIndex];
          currentMessageId = messages[previousMessageIndex].messageId; //set the current messageId to the previous one to keep scanning upwards
        }

        iterations--;
      } while (previousMessage.asurite === 'ASU-sunnybot' && iterations > 0);

      let payload = {
        convoId: messageObject.convoId,
        messageId: messageObject.messageId,
        tag: 'msgReview' + type,
      };

      if (previousMessageIndex > -1) {
        payload.tag += ' ' + previousMessage.messageId;
      }

      //console.log("msgReview+ clicked tag was:", payload.tag);

      /*
         if the new tag we adding is the same as the current tag we
         toggle to a state where no review result is selected, namely we:
         - remove all tags from the UI state,
         - delete the tag on the backend,
         - bypass the addTag function
      */

      let toggle = false;
      let newMessages = [...messages];
      let originalMessageTags = [...messageObject.tags];

      if (
        newMessages[currentMessageIndex].tags[
        newMessages[currentMessageIndex].tags.length - 1
        ] === payload.tag
      ) {
        toggle = true;
      }

      //console.log("msgReview+ toggle is:", toggle);

      //update local state for UI

      /*console.log(
        "msgReview+ message tags were:",
        JSON.stringify(
          newMessages[currentMessageIndex].tags.map((string) => {
            return string.split(" ")[0];
          })
        )
      );*/

      if (toggle === false) {
        //newMessages[currentMessageIndex].tags.push(payload.tag);
      } else if (toggle === true) {
        let msgReviewTagsToRemove = [];

        newMessages[currentMessageIndex].tags.forEach((tag, index) => {
          if (tag.includes('msgReview')) {
            msgReviewTagsToRemove.push(index);
          }
        });

        msgReviewTagsToRemove.reverse();

        msgReviewTagsToRemove.forEach(index => {
          newMessages[currentMessageIndex].tags.splice(index, 1);
        });
      }
      /*console.log(
        "msgReview+ message tags are now:",
        JSON.stringify(
          newMessages[currentMessageIndex].tags.map((string) => {
            return string.split(" ")[0];
          })
        )
      );*/

      setMessages(newMessages);

      /* if this message already has a msgReview tag we call tagHelper.removeMessageTag to delete the tag */

      let update = false;
      let oldMsgReviewTagContent = 'msgReview';

      originalMessageTags.forEach(tag => {
        if (tag.includes('msgReview')) {
          update = true;
          oldMsgReviewTagContent = tag;
        }
      });

      if (update === true) {
        //console.log("msgReview+ removing tag:", oldMsgReviewTagContent);
        await tagHelper.removeMessageTag(
          messageObject.messageId,
          oldMsgReviewTagContent
        );
      }

      if (toggle === false) {
        //console.log("msgReview+ adding tag:", payload.tag);
        await tagHelper.addTag(payload, createChatbotMessageTag);
      }

      setTimeout(function () {
        setIsReviewPending(false);
        setReviewPendingMessageIdAndType(['', '']);
      }, 1000);
    } catch (err) {
      setTimeout(function () {
        setIsReviewPending(false);
        setReviewPendingMessageIdAndType(['', '']);
      }, 1000);
    }
  };

  const addMessageNoteOnSubmit = async data => {
    try {
      let payload = {
        messageId: clickedMessage.messageId,
        convoId: clickedMessage.convoId,
        content: data.target[0].value,
        author: tokens.asurite,
        botType: 'sunny',
        deleted: false,
      };

      let messageNoteOperation = await API.graphql(
        graphqlOperation(createDialogueNotes, {
          input: payload,
        })
      );

      let newNote = messageNoteOperation.data.createDialogueNotes;
      newNote.date = new Date(newNote.updatedAt).valueOf();

      props.setConvoNotes([newNote, ...props.convoNotes]);
      setClickedMessage(null);
      setModalType(null);
      toggle();
    } catch (err) {
      console.log(err);
    }
  };

  const escalateMessageOnSubmit = async data => {
    let convo = props.selectedConvo;

    let escalationPayload = {
      id: manualEscalationId,
      user: convo.type === 'auth' ? convo.asurite : convo.phoneNumber,
      convoId: convo.convoId,
      isVisitor: convo.type === 'visitor',
      escalator: tokens.asurite,
      hitType: 'Message',
      message: clickedMessage.content,
      messageId: clickedMessage.messageId,
    };

    let tagPayload = {
      convoId: clickedMessage.convoId,
      messageId: clickedMessage.messageId,
      tag: 'escalation ' + manualEscalationId,
    };
    try {
      dispatchGlobal({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: true,
      });
      if (data.target) {
        // console.log(manualEscalationId, clickedMessage);
        const escalatingConvo = await API.graphql(
          graphqlOperation(escalateConvo, JSON.stringify(escalationPayload))
        );

        await tagHelper.addTag(tagPayload, createChatbotMessageTag);

        let payload2 = {
          convoId: clickedMessage.convoId,
          tag: 'escalation true',
        };

        await tagHelper.addTag(payload2, createChatbotConvoTag);

        setManaulEscalationId({});
        dispatchGlobal({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: false,
        });
        toggle();
        handleMenuClose();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleMenuOpen = (event, message) => {
    setClickedMessage(message);
    setAnchorEl(event.currentTarget);
  };

  const getTagValue = tagStarter => {
    console.log(props.myReviewTags);
    let tag = tagStarter + ' ';

    if (props.listFilter) {
      let listSplit = props.listFilter.split(' ');
      if (listSplit[0] === 'groupAssigned') {
        tag += listSplit[1];
      } else {
        tag += tokens.asurite;
        // props.myReviewTags[1].split(" ")[1];
      }
    } else {
      tag += 'true';
    }

    return tag;
  };

  const addNeedsReview = async (mId, cId) => {
    let tag = getTagValue('needsReview');

    let payload = {
      convoId: cId,
      messageId: mId,
      tag: tag,
    };

    await tagHelper.addTag(payload, createChatbotMessageTag);

    let payload2 = {
      convoId: cId,
      tag: tag,
    };

    await tagHelper.addTag(payload2, createChatbotConvoTag);

    handleMenuClose();
    props.setUpdateNeedsReview(prev => prev + 1);
    props.updateReviewCounts('add');
    //props.updateReviewTags("add", tag);
  };

  const addPrevAssistedTag = async (mId, cId, tag) => {
    let payload = {
      convoId: cId,
      tag: tag,
    };
    await tagHelper.addTag(payload, createChatbotConvoTag);
  };

  const updateMessage = msgs => {
    let mClone = tagHelper.updateTags(msgs, messages, 'messageId');
    setMessages(mClone);
  };

  const resolveNeedsReview = async (mId, cId) => {
    tagHelper.resolveNeedsReview(
      mId,
      cId,
      props.listFilter,
      props.myReviewTags
    );

    handleMenuClose();
    props.setUpdateNeedsReview(prev => prev + 1);
    props.updateReviewCounts('remove');
    props.updateReviewTags('remove');
  };

  const handleMenuClose = () => {
    if (clickedMessage) {
      setAnchorEl(null);
    } else {
      setClickedMessage(null);
      setAnchorEl(null);
    }
  };

  const checkForEscalationTags = () => {
    let tags =
      clickedMessage && clickedMessage.tags
        ? clickedMessage.tags.map(tag =>
          tag.includes('escalation') ? tag.slice(0, 10) : tag
        )
        : [];

    let compArry = ['escalation'];

    if (props.listFilter) {
      if (props.listFilter.indexOf('agentAssigned') > -1) {
        compArry = props.myReviewTags;
      } else {
        let tmpTag = getTagValue('escalation');
        compArry.push(tmpTag);
      }
    }

    const filteredArray = tags.filter(value => compArry.includes(value));
    console.log('filteredArray', filteredArray);
    return filteredArray.length > 0;
  };

  const checkForReviewTag = () => {
    let tags = clickedMessage && clickedMessage.tags ? clickedMessage.tags : [];

    let compArry = ['needsReview true'];

    if (props.listFilter) {
      if (props.listFilter.indexOf('agentAssigned') > -1) {
        compArry = props.myReviewTags;
      } else {
        let tmpTag = getTagValue('needsReview');
        compArry.push(tmpTag);
      }
    }

    const filteredArray = tags.filter(value => compArry.includes(value));
    return filteredArray.length > 0;
  };

  const modifyCharCountSelectedMessages = (messageId, content, force) => {
    let indexAt = charCountSelectedIds.indexOf(messageId);
    let newMessageIds = [...charCountSelectedIds];
    let newMessages = [...charCountSelectedMessages];

    if (indexAt === -1) {
      newMessageIds.push(messageId);
      newMessages.push(content);
    } else if (force !== true) {
      newMessageIds.splice(indexAt, 1);
      newMessages.splice(indexAt, 1);
    }

    setCharCountSelectedIds(newMessageIds);
    setCharCountSelectedMessages(newMessages);
  };

  const clearCharCountData = () => {
    setCharCountSelectedIds([]);
    setCharCountSelectedMessages([]);
  };

  const ellipsisMenu = () => {
    let hasNeedsReview = checkForReviewTag();
    let hasActiveEscalation = checkForEscalationTags();

    return (
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            setModalType('note');
            toggle();
            handleMenuClose();
          }}
        >
          Add Note
        </MenuItem>
        <MenuItem
          onClick={() => {
            setModalType('tag');
            toggle();
            handleMenuClose();
          }}
        >
          Add Tag
        </MenuItem>
        <MenuItem
          onClick={() => {
            //ACTIVE: Open Character Count
            setCharCountUIEnabled(!charCountUIEnabled);
            if (charCountUIEnabled) {
              //clearCharCountData();
            } else {
              let force = true;
              modifyCharCountSelectedMessages(
                clickedMessage.messageId,
                clickedMessage.content,
                force
              );
            }
            handleMenuClose();
          }}
        >
          {!charCountUIEnabled ? 'Character Count' : 'Close Character Count'}
        </MenuItem>
        {hasNeedsReview ? (
          <MenuItem
            onClick={() =>
              resolveNeedsReview(
                clickedMessage.messageId + '',
                clickedMessage.convoId + ''
              )
            }
          >
            Resolve
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() =>
              addNeedsReview(
                clickedMessage.messageId + '',
                clickedMessage.convoId + ''
              )
            }
          >
            Needs Review
          </MenuItem>
        )}
        {hasActiveEscalation ? (
          <MenuItem
            onClick={() => {
              setModalType('resolveEscalation');
              toggle();
              handleMenuClose();
            }}
          >
            Resolve Escalation
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              setModalType('escalate');
              toggle();
              handleMenuClose();
            }}
          >
            Escalate
          </MenuItem>
        )}
      </Menu>
    );
  };

  const getIcons = (type, messageIcons, m) => {
    const { isNeedReview, isNeedKendraReview, isError, isEscalation, hasNote } = messageIcons;

    return (
      <div style={{ margin: '0 10px', position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: isError ? '-20px' : '-30px',
            [type === 'self' ? 'right' : 'left']: '0px',
            display: 'flex',
          }}
        >
          {isNeedReview && !isNeedKendraReview? (
            <SvgIcon
              viewBox={'-4 -6 20 20'}
              style={{
                zIndex: '999',
              }}
              component={NeedsReviewIcon}
            />
          ) : null}
          {isNeedKendraReview ? (
            <SvgIcon
              viewBox={'-4 -6 20 20'}
              style={{
                zIndex: '999',
              }}
              component={NeedsKendraReviewIcon}
            />
          ) : null}
          {isError ? (
            /*<SvgIcon
              viewBox={"-4 -6 20 20"}
              style={{
                zIndex: "999",
              }}
              component={AlertIcon}
            />*/
            <IconAlert2 tooltip={getEndingMessage(m)} />
          ) : null}
          {isEscalation ? (
            <SvgIcon
              viewBox={'-4 -12 30 30'}
              style={{
                zIndex: '999',
              }}
              component={EscalationIcon}
            />
          ) : null}
          {/* {hasNote ? (
            <SvgIcon
              viewBox={"-4 -12 30 30"}
              style={{
                zIndex: "999",
              }}
              component={HasNoteIcon}
            />
          ) : null} */}
        </div>
        <FontAwesomeIcon
          style={{
            marginBottom: isError || isNeedReview ? '4px' : null,
          }}
          size="lg"
          icon={faEllipsisV}
          onClick={e => handleMenuOpen(e, m)}
        />
      </div>
    );
  };

  const goToKb = async id => {
    let resp = await API.graphql(
      graphqlOperation(getKnowledgeBase, {
        id: id,
      })
    );

    let item = resp.data.getKnowledgeBase;

    dispatchGlobal({
      type: 'updateEditInfo',
      editInfo: item,
    });

    // TODO: Fix navigations when click View KB
    props.history.push({
      pathname: '/knowledge-base/add-FAQ',
    });
  };

  const goToAlert = async id => {
    let resp = await API.graphql(
      graphqlOperation(getCriticalEscalation, {
        id: id,
      })
    );

    let item = resp.data.getCriticalEscalation;

    if(!item || (item && item.status === 6)){
      props.handleOpenCustomAlertModal();
      return;
    }

    dispatchGlobal({
      type: 'updateEditInfo',
      editInfo: item,
    });

    // TODO: Fix navigations when click View KB
    props.history.push({
      pathname: '/alert-add',
    });
  };

  const getFirstSourceUrl = message => {
    const [content, sources] = message.split('\n');
    const firstSource = sources ? sources.split(')')[0] + ')' : '';
    return `${content}\n${firstSource}`;
  };

  let currentAgentChar = 'L';
  const renderMessage = (m, ind) => {
    if (typeof m.agentAsurite !== 'undefined' && m.agentAsurite !== 'null') {
      if (typeof m.content !== 'undefined') {
        if (m.content.includes('has entered the conversation')) {
          currentAgentChar = m.content.charAt(5).toUpperCase();
        } else if (m.content.includes('has left the conversation')) {
          currentAgentChar = 'L';
        }
      }
    }

    if (m.sourceUrl && m.sourceUrl.length) {
      m.content = getFirstSourceUrl(m.content);
    }

    let image = null;

    let isNewSession = false;

    let agentName = '';

    try {
      isNewSession = messages[ind - 1].connectionId !== m.connectionId;
    } catch (e) { }

    try {
      if (m.file && JSON.parse(m.file).image) {
        image = JSON.parse(m.file).image;
      }
    } catch (error) { }

    let notSentClass = '';

    let bubbleClass =
      m.asurite === 'ASU-sunnybot' || m.phoneNumber === '+14243734261'
        ? 'selfDialogueMessage'
        : 'otherDialogueMessage';

    let bubbleClassDate =
      m.asurite === 'ASU-sunnybot' || m.phoneNumber === '+14243734261'
        ? 'selfDialogueMessageDate'
        : 'otherDialogueMessageDate';

    if (m.agentAsurite !== 'null') {
      bubbleClass = 'selfDialogueMessage agent';
    }

    if (m.agentAction === 'enter' || m.agentAction === 'leave') {
      bubbleClass = 'chatbotBannerMessage';
    }

    if (m.sending) {
      bubbleClass += ' notSent flash';
    }

    let isHighlighted = false;

    let tagsString = '';

    if (m.tags) {
      for (var i = 0; i < m.tags.length; ++i) {
        try {
          let tag = m.tags[i];
          if (tag.indexOf('customTag') > -1) {
            let val = tag.split('customTag ')[1];
            tagsString +=
              val && val !== 'undefined'
                ? (i > 0 ? ', ' : '') + tag.split('customTag ')[1]
                : '';
          }
        } catch (e) {
          console.log('FALED', e);
        }
      }
    }

    if (props.selectedConvo.highlightConnections) {
      let timeComp = moment(m.latest).unix();
      for (
        var i = 0;
        i < props.selectedConvo.highlightConnections.length;
        ++i
      ) {
        let conn = props.selectedConvo.highlightConnections[i];
        if (timeComp >= conn.scrollStart && timeComp <= conn.scrollEnd) {
          isHighlighted = true;
          break;
        }
      }
    }

    if (m.agentAsurite !== 'null') {
      agentName = currentAgentChar;
    }

    let messageIcons = {
      isError: false,
      isNeedReview: false,
      isNeedKendraReview: false,
      isEscalation: false,
      hasNote: false,
    };

    let msgReviewTag = '';
    let kbInfo = null;
    let alertInfo = null;

    if (m.tags) {
      for (let tag of m.tags) {
        if (tag.includes('error')) {
          messageIcons.isError = true;
        }
        if (
          (tag.includes('needsReview true') && !props.listFilter) ||
          (tag.includes('needsReview') && props.listFilter)
        ) {
          messageIcons.isNeedReview = true;
        }
        if (
          (tag.includes('needsReview Kendra') && !props.listFilter)
        ) {
          messageIcons.isNeedKendraReview = true;
        }
        if (tag.includes('escalation')) {
          messageIcons.isEscalation = true;
        }
        if (tag.includes('msgReview')) {
          msgReviewTag = tag;
        }
        if (tag.includes('kbId ')) {
          kbInfo = tag.split(' ')[1];
        }
        if (tag.includes('CRITICAL')) {
          alertInfo = 'ALERT';
        }
        /*
        if (tag.includes("needsReview true")) {
          messageIcons.isNeedReview = true;
        }
        */
      }
    }

    alertInfo = alertInfo && kbInfo ? kbInfo : null;

    return (
      <Element name={m.messageId}>
        <div>
          <div
            key={m.messageId}
            style={{ backgroundColor: isHighlighted ? '#d6d6d6' : 'white' }}
          >
            <div
              className="msgDiv"
              style={{
                padding: '5px',
                width: '100%',
                justifyContent:
                  bubbleClass.indexOf('selfDialogueMessage') > -1
                    ? 'flex-start'
                    : 'flex-end',
              }}
            >
              {bubbleClass.indexOf('selfDialogueMessage') > -1 &&
                charCountUIEnabled ? (
                <div
                  className="char-count-icon-wrapper"
                  onClick={() =>
                    modifyCharCountSelectedMessages(m.messageId, m.content)
                  }
                >
                  {charCountSelectedIds.indexOf(m.messageId) !== -1 ? (
                    <SvgIcon
                      viewBox={'0 -2.5 19 19'}
                      style={{
                        zIndex: '999',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      component={NeedsReviewIcon}
                    />
                  ) : (
                    <SvgIcon
                      viewBox={'0 -2.5 21 21'}
                      style={{
                        zIndex: '999',
                      }}
                      component={UnreadIcon}
                    />
                  )}
                </div>
              ) : null}
              {bubbleClass.indexOf('selfDialogueMessage') > -1 &&
                (agentName === '' ? (
                  <div
                    className="sunnyIcon"
                    style={{ marginRight: '5px' }}
                  ></div>
                ) : (
                  <div className="dashboardIcon">{agentName}</div>
                ))}
              <div
                id={m.messageId}
                tabIndex="-1"
                className={bubbleClass}
                style={{
                  marginRight: '5px',
                  display: 'flex',
                  alignItems: 'self-start',
                  position: 'relative',
                }}
              >
                {bubbleClass == 'otherDialogueMessage' &&
                  getIcons('other', messageIcons, m)}

                <div
                  style={{
                    justifyContent:
                      bubbleClass.indexOf('selfDialogueMessage') > -1
                        ? 'flex-start'
                        : 'flex-end',
                  }}
                >
                  {m.image && (
                    <div>
                      <img
                        src={m.image}
                        style={{ maxWidth: '15rem', maxHeight: '15rem' }}
                        alt="dialogue-image"
                      />
                    </div>
                  )}
                  <Truncate lines={20} ellipsis={<span>... </span>} width={320}>
                    {m.content}
                  </Truncate>
                </div>

                {bubbleClass.includes('selfDialogueMessage') &&
                  getIcons('self', messageIcons, m)}

                {m.textFile && (
                  <div
                    onClick={() => downloadFile(m.textFile)}
                    className="fileAttachmentInMsg"
                  >
                    <div>
                      <AttachFileIcon
                        style={{ width: '18px', height: '18px' }}
                      />
                    </div>
                    <div>File Attached</div>
                  </div>
                )}
              </div>

              {bubbleClass === 'otherDialogueMessage' &&
                props.getAvatar(props.selectedConvo, 30)}

              {bubbleClass.indexOf('selfDialogueMessage') === -1 &&
                bubbleClass.indexOf('chatbotBannerMessage') === -1 &&
                charCountUIEnabled ? (
                <div
                  className="char-count-icon-wrapper"
                  onClick={() =>
                    modifyCharCountSelectedMessages(m.messageId, m.content)
                  }
                >
                  {charCountSelectedIds.indexOf(m.messageId) !== -1 ? (
                    <SvgIcon
                      viewBox={'0 -2.5 19 19'}
                      style={{
                        zIndex: '999',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      component={NeedsReviewIcon}
                    />
                  ) : (
                    <SvgIcon
                      viewBox={'0 -2.5 21 21'}
                      style={{
                        zIndex: '999',
                      }}
                      component={UnreadIcon}
                    />
                  )}
                </div>
              ) : null}
            </div>

            <div className={bubbleClassDate}>
              {m.sending ? 'Sending' : getEndingMessage(m)}
              {' -'}
              {/* {m.type === 'auth' ? (
                <SvgIcon
                  style={{ transform: 'scale(0.8)' }}
                  viewBox="-5 -1 15 15"
                  component={WebIcon}
                />
              ) : (
                <SvgIcon
                  style={{ transform: 'scale(0.8)' }}
                  viewBox="-3 -1 15 15"
                  component={SMSIcon}
                />
              )}
              {m.type === 'auth' ? ' WEB' : ' SMS'} */}

              {
                renderIconType(m.type)
              }

              {kbInfo && !alertInfo && (
                <div
                  onClick={() => goToKb(kbInfo)}
                  className={bubbleClassDate}
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    paddingRight: '0px',
                    paddingLeft: '7px',
                  }}
                >
                  View KB
                </div>
              )}
              {alertInfo && (
                <div
                  onClick={() => goToAlert(alertInfo)}
                  className={bubbleClassDate}
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    paddingRight: '0px',
                    paddingLeft: '7px',
                  }}
                >
                  View Alert
                </div>
              )}

              {m.asurite === 'ASU-sunnybot' &&
                bubbleClass.indexOf('selfDialogueMessage') > -1 &&
                agentName === '' ? (
                <span
                  style={{
                    display: 'inline-flex',
                    marginLeft: '0.5em',
                    width: '5em',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    cursor: 'pointer',
                  }}
                >
                  <SvgIcon
                    style={{ width: '0.6em', height: '0.6em' }}
                    viewBox="0 0 14 14"
                    component={
                      msgReviewTag.includes('Bad')
                        ? ThumbsDownActiveIcon
                        : ThumbsDownIcon
                    }
                    className={
                      reviewPendingMessageIdAndType[0] === m.messageId &&
                        reviewPendingMessageIdAndType[1] !== 'Bad'
                        ? 'message-review-pending'
                        : 'message-review'
                    }
                    onClick={() => {
                      if (isReviewPending === false) {
                        let type = 'Bad';
                        tagOnReview(m, type);
                      }
                    }}
                  />
                  <SvgIcon
                    style={{ width: '0.6em', height: '0.6em' }}
                    viewBox="0 0 14 14"
                    className={
                      reviewPendingMessageIdAndType[0] === m.messageId &&
                        reviewPendingMessageIdAndType[1] !== 'Good'
                        ? 'message-review-pending'
                        : 'message-review'
                    }
                    component={
                      msgReviewTag.includes('Good')
                        ? ThumbsUpActiveIcon
                        : ThumbsUpIcon
                    }
                    onClick={() => {
                      if (isReviewPending === false) {
                        let type = 'Good';
                        tagOnReview(m, type);
                      }
                    }}
                  />
                  <SvgIcon
                    style={{ width: '0.6em', height: '0.6em' }}
                    viewBox="0 0 14 14"
                    className={
                      reviewPendingMessageIdAndType[0] === m.messageId &&
                        reviewPendingMessageIdAndType[1] !== 'Ignore'
                        ? 'message-review-pending'
                        : 'message-review'
                    }
                    component={
                      msgReviewTag.includes('Ignore')
                        ? IgnoreActiveIcon
                        : IgnoreIcon
                    }
                    onClick={() => {
                      if (isReviewPending === false) {
                        let type = 'Ignore';
                        tagOnReview(m, type);
                      }
                    }}
                  />
                </span>
              ) : null}
            </div>

            {tagsString !== '' && (
              <div className={bubbleClassDate}>Tags: {tagsString}</div>
            )}
          </div>
        </div>
      </Element>
    );
  };

  const getEndingMessage = m => {
    let em = moment(m.latest).format('MM/DD/YY, h:mm a');

    if (!m.tags) m.tags = [];

    for (var i = 0; i < m.tags.length; ++i) {
      try {
        let tag = m.tags[i];
        if (tag.indexOf('error') > -1) {
          em = 'Failed: ' + tag.substring(tag.indexOf(' ') + 1);
        }
      } catch (e) { }
    }

    return em;
  };

  const acceptFile = async (e, type) => {
    let signed = await getUrl(e);

    if (type === 'image') {
      setImageFile(e);
      setImageSignedFile(signed);
    } else {
      setTextFile(e);
      setTextSignedFile(signed);
    }
  };

  const handleInputKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };

  const removeItem = type => {
    setResetFile(type + '-' + new Date().getTime());
    if (type === 'image') {
      setImageFile(null);
      setImageSignedFile(null);
    } else {
      setTextFile(null);
      setTextSignedFile(null);
    }
  };

  const showAttachment = (type, url, base) => {
    return (
      <div className="filePreviewAtachment">
        <div style={{ position: 'relative' }}>
          <div className="removeAttachment" onClick={() => removeItem(type)}>
            <CloseIcon style={{ width: '18px', height: '18px' }} />
          </div>
          {base === 'loading' && (
            <CircularProgress
              style={{
                width: '1.5rem',
                height: '1.5rem',
                position: 'absolute',
                left: '8px',
                top: '8px',
              }}
            />
          )}

          {type === 'file' && (
            <AttachFileIcon
              style={{
                width: '40px',
                height: '40px',
                color: '#656565',
                marginRight: '10px',
              }}
            />
          )}

          {type === 'image' && base !== 'loading' && (
            <img src={url} style={{ maxWidth: '50px', maxHeight: '50px' }} />
          )}

          {type === 'image' && base === 'loading' && (
            <ImageIcon
              style={{
                width: '40px',
                height: '40px',
                color: '#656565',
                marginRight: '10px',
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const getAttachments = () => {
    return (
      <div style={{ display: 'flex' }}>
        {imageFile ? showAttachment('image', imageSignedFile, imageFile) : null}
        {textFile ? showAttachment('file', textSignedFile, textFile) : null}
      </div>
    );
  };

  const viewStyles = {
    smsView: {
      flex: 1,
      cursor:
        props.selectedConvo.sms &&
          props.selectedConvo.auth &&
          props.selectedConvo.showConvo !== 'sms'
          ? 'pointer'
          : 'default',
      transition: 'ease 0.5s',
      textAlign: 'center',
      paddingTop: '0.5rem',
      fontSize: '0.9rem',
      fontWeight: 400,
      backgroundColor:
        props.selectedConvo.showConvo === 'sms' ? '#404040' : null,
      color: props.selectedConvo.showConvo === 'sms' ? 'white' : 'black',
    },
    authView: {
      flex: 1,
      cursor:
        props.selectedConvo.sms &&
          props.selectedConvo.auth &&
          props.selectedConvo.showConvo !== 'auth'
          ? 'pointer'
          : 'default',
      transition: 'ease 0.5s',
      textAlign: 'center',
      paddingTop: '0.5rem',
      fontSize: '0.9rem',
      fontWeight: 400,
      backgroundColor:
        props.selectedConvo.showConvo === 'auth' ? '#404040' : null,
      color: props.selectedConvo.showConvo === 'auth' ? 'white' : 'black',
    },
    voiceView: {
      flex: 1,
      cursor:
        props.selectedConvo.sms &&
          props.selectedConvo.auth &&
          props.selectedConvo.showConvo !== 'voice'
          ? 'pointer'
          : 'default',
      transition: 'ease 0.5s',
      textAlign: 'center',
      paddingTop: '0.5rem',
      fontSize: '0.9rem',
      fontWeight: 400,
      backgroundColor:
        props.selectedConvo.showConvo === 'voice' ? '#404040' : null,
      color: props.selectedConvo.showConvo === 'voice' ? 'white' : 'black',
    },
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress
          size={props.spinnerSize ? props.spinnerSize : '2rem'}
        />
        <div ref={messagesEndRef} />
      </div>
    );
  } else if (!props.selectedConvo) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div>No Conversation Selected</div>
        <div ref={messagesEndRef} />
      </div>
    );
  } else {
    return (
      <div
        id="chatContainer"
        style={{
          height: props.selectedConvo.agentChatting ? '100%' : '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          id="chatContainerTabView"
          style={{
            display: 'flex',
            width: '100%',
            height: '2.5rem',
            borderBottom: '1px solid #d5d5d5',
          }}
        >
          {props.selectedConvo.sms && (
            <div
              style={viewStyles.smsView}
              onClick={() => {
                if (props.selectedConvo.showConvo !== 'sms') {
                  props.toggleConvo('sms');
                }
              }}
            >
              SMS View
            </div>
          )}
          {props.selectedConvo.auth && (
            <div
              style={viewStyles.authView}
              onClick={() => {
                if (props.selectedConvo.showConvo !== 'auth') {
                  props.toggleConvo('auth');
                }
              }}
            >
              Web/Auth View
            </div>
          )}
          {/* If tag phone exists, it shows a new tab */}
          {props.selectedConvo.voice && (
            <div
              style={viewStyles.voiceView}
              onClick={() => {
                if (props.selectedConvo.showConvo !== 'voice') {
                  props.toggleConvo('voice');
                  // When the selectedConvo is toggled, the useEffect function hooked with selectedConvo state will be called (line 271)
                }
              }}
            >
              Phone View
            </div>
          )}
        </div>
        {charCountUIEnabled ? (
          <div className="char-count-banner">
            <strong>Total:</strong> {charCountSelectedIds.length} msg,{' '}
            {charCountSelectedMessages.reduce((totalChars, currentMessage) => {
              totalChars += currentMessage.length;
              return totalChars;
            }, 0)}{' '}
            char
            <div
              className="char-count-banner__close-button"
              onClick={() => {
                setCharCountUIEnabled(!charCountUIEnabled);
                //clearCharCountData();
              }}
            >
              CLOSE
            </div>
          </div>
        ) : null}
        <div
          id="messageContainer"
          onScroll={handleScroll}
          style={{
            height: '100%',
            overflowY: 'scroll',
            // padding: "0px 10px" // add back
          }}
        >
          {/* Here messages are rendered (the messages are updated when the convo is toggled) */}
          {_.uniqBy(messages, 'messageId').map((m, ind) => {
            // As messages are received from graphql with the same scheme, its render should be also the same
            return renderMessage(m, ind);
          })}
          <div ref={messagesEndRef} />
        </div>
        {ellipsisMenu()}
        {!props.selectedConvo.agentChatting ? null : (
          <FormContext {...methods}>
            <form
              className={classes.root}
              noValidate
              autoComplete="off"
              style={{
                width: '100%',
                borderTop: '1px solid #d5d5d5',
                position: 'relative',
                marginTop: '4em',
              }}
            >
              <div className={'chatbotPausedButtonWrapper'}>
                <div
                  className={'chatbotPausedButton'}
                  onClick={() => {
                    props.restartLiveAgentTimer(props.selectedConvo.convoId);
                  }}
                >
                  {liveAgentTimerReadout}
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  margin: 0,
                  justifyContent: 'space-between',
                  color: 'grey',
                  height: 'fit-content',
                }}
              >
                {!textFile && !imageFile ? null : getAttachments()}
              </div>

              {showRefreshTokenMessage !== '' ? (
                <div
                  style={{
                    width: '100%',
                    height: '7em',
                    paddingTop: '2.5em',
                    margin: 0,
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  Establishing connection...
                  {/*<a href={"./dialogues?convoId=" + showRefreshTokenMessage} style={{color: "rgb(135, 52, 80)", textDecoration: "underline", fontWeight: "bold"}}>Click here to re-authorize.</a>*/}
                </div>
              ) : (
                <OutlinedInput
                  id="messageInput"
                  classes={outlinedClasses}
                  notched={true}
                  ref={e => {
                    setCurrentTextField(
                      document.getElementById('messageInput')
                    );
                  }}
                  style={{ width: '100%', margin: 0 }}
                  placeholder="Enter your message here"
                  // autoFocus
                  value={message}
                  onChange={handleChange()}
                  onKeyPress={handleInputKeyPress}
                  multiline={true}
                />
              )}

              <div
                style={{
                  display: 'flex',
                  height: '2rem',
                  width: '100%',
                  margin: 0,
                  justifyContent: 'space-between',
                  color: 'grey',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '1rem',
                  }}
                >
                  {
                    <FieldExtras
                      acceptFile={acceptFile}
                      type="dialogues"
                      resetFile={resetFile}
                      imageName={'image'}
                      value={message}
                      setValue={setMessage}
                      allowextras={'image,emoji,file,imgGallery'}
                      currentTextField={currentTextField}
                      resetFiles={resetFiles}
                    />
                  }
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '1rem',
                    color: message.length > 160 ? 'red' : 'grey',
                  }}
                >
                  {`${message.length}/160`}{' '}
                  {message.length > 160 &&
                    `- ${Math.floor(message.length / 160) + 1} texts`}
                  <IconButton onClick={handleSend} edge="end">
                    <SendIcon />
                  </IconButton>
                </div>
              </div>
            </form>
          </FormContext>
        )}

        {/* <AddTagModal
          show={showAddTag}
          type={"session"}
          tagInfo={tagUserInfo}
          confirmAddTag={confirmAddTag}
        /> */}
        <Modal
          noOverflowY
          isShowing={isShowing}
          hide={toggle}
          content={getModalContent()}
        />
      </div>
    );
  }
}

Messages.propTypes = {
  navigation: PropTypes.object,
};
