import React, { useState, useEffect, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import { Route, Switch, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTheme } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';

import AuthContext from 'contexts/AuthContext';
import { store as KBStore } from 'contexts/KBStore';
import { store as GlobalStore } from 'contexts/GlobalStore';
import { SectionHeader } from 'components/SectionHeader';
import { OpenSunny } from 'components/Sunny';
import NodeProperties from '../Flows/FlowDiagram/Edit/NodeProperties.js';
import KBForm from './KBForm';
import Categories from './Categories';
import CategoryInfo from './CategoryInfo';
import FAQs from 'components/knowledgeBase/FAQs';
import { combineData } from './utility';
import {
  listKnowledgeBaseCategorys,
  searchKnowledgeBases,
} from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import { CreateNewButton, PanelButton } from 'stories';
import { roleCheck } from 'services/utility/RoleMatrix';

import { getUserGroups } from 'hooks';

import { audiences } from '../FlowMessages/audiences';

// import { ReactComponent as SunnySmile } from "./assets/sunnysmile.svg";
// import { ReactComponent as SunnySurprised } from "./assets/sunnysurprised.svg";

import './KnowledgeBase.css';

export default function KnowledgeBase(props) {
  const { keyedNames, selectListGroups, getGroup } = getUserGroups();

  const theme = useTheme();
  const location = useLocation();
  const queryStringValues = queryString.parse(location.search.replace(/\s\&\s/g, " %26 "));
  const tokens = useContext(AuthContext);

  const KBState = useContext(KBStore);
  const dispatchKB = KBState.dispatch;
  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;
  const { spliceTableData } = GlobalState.state;

  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubcategory, setActiveSubcategory] = useState(null);
  const [activeFAQ, setActiveFAQ] = useState(null);
  const [FAQData, setFAQData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [rerenderKey, setRerenderKey] = useState(Date.now());
  const [showProperties, setShowProperties] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [filter, setFilter] = useState('All');
  const [searchActive, setSearchActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const role = tokens.role;

  useEffect(() => {
    if (categories.length && queryStringValues.category) {
      let categoryInfo = {};
      categories.forEach(el => {
        if (el.title === queryStringValues.category) {
          categoryInfo = el;
        }
      });
      const categoryData = { title: queryStringValues.category, categoryInfo };
      setActiveCategory(categoryData);
      if (queryStringValues.subcategory) {
        setActiveSubcategory(queryStringValues.subcategory);
      }
      if (queryStringValues.FAQ) {
        setActiveFAQ(queryStringValues.FAQ);
      }
    }
  }, [rerenderKey, categories]);

  useEffect(() => {
    setTimeout(() => {
      fetchData();
      releaseEdit();
    }, 550);
  }, [rerenderKey, spliceTableData]);

  const getAllKbs = () => {
    let allData = [];
    let token = null;
    return new Promise(async function tryagain(resolve, reject) {
      let pay = {
        limit: 1000,
        filter: createFilterQuery(),
      };

      if (token) {
        pay.nextToken = token;
      }

      const KBData = await API.graphql(
        graphqlOperation(searchKnowledgeBases, pay)
      );

      token = KBData.data.searchKnowledgeBases.nextToken;
      const knowledgeBase = KBData.data.searchKnowledgeBases.items;
      allData = allData.concat(knowledgeBase);

      if (token) {
        tryagain(resolve, reject);
      } else {
        resolve(allData);
      }
    });
  };

  const fetchData = async () => {
    try {
      const categories = await API.graphql(
        graphqlOperation(listKnowledgeBaseCategorys, {
          limit: 1000,
          filter: {
            deleted: { eq: false },
          },
        })
      );

      const knowledgeBase = await getAllKbs();
      const categoriesData = categories.data.listKnowledgeBaseCategorys.items;

      if (spliceTableData) {
        const { type } = spliceTableData;
        let loadedDeleteUpdate = false;
        let loadedNewFAQ = false;
        let loadedEditUpdate = false;
        knowledgeBase.forEach((el, index) => {
          if (type === 'deleteFAQ') {
            loadedNewFAQ = true;
            loadedEditUpdate = true;
            spliceTableData.FAQs.forEach(FAQ => {
              if (el.id === FAQ.id) {
                if (el.status.statusID === 6) {
                  loadedDeleteUpdate = true;
                }
                //  else {
                //   return setRerenderKey(Date.now());
                // }
              }
            });
          } else if (type === 'addFAQ') {
            loadedEditUpdate = true;
            loadedDeleteUpdate = true;
            if (el.id === spliceTableData.FAQs[0].id) {
              loadedNewFAQ = true;
            }
          } else if (type === 'editFAQ') {
            spliceTableData.FAQs.forEach(FAQ => {
              if (el.id === FAQ.id) {
                knowledgeBase[index] = FAQ;
                loadedEditUpdate = true;
              }
            });
          }
        });

        if (!loadedDeleteUpdate || !loadedNewFAQ || !loadedEditUpdate) {
          return setRerenderKey(Date.now());
        }
      }

      let FAQData = combineData(knowledgeBase, categoriesData);

      let groupCheck = (group, arr) => {
        for (let i of arr) {
          if (i === group) {
            return true;
          }
        }
        return false;
      };

      if (role === 'approver' || role === 'content creator') {
        FAQData = FAQData.filter(el => groupCheck(el.group, tokens.groups));
      }
      setFAQData(FAQData);
      setCategories(categoriesData);
    } catch (error) {
      console.log('fetchData error: ', error);
    }
  };

  const createFilterQuery = () => {
    const filterConditions = [];

    filterConditions.push({ group: { ne: 'Benji_1593621826' } });

    if (!roleCheck(tokens.role, 'knowledgeBase', 'viewAllContent')) {
      tokens.groups.forEach(el => {
        filterConditions.push({ group: { eq: el } });
      });
      filterConditions.push({ submittedBy: { eq: tokens.asurite } });
      filterConditions.push({ status: { eq: getStatusKey('Active') } });
      filterConditions.push({ status: { eq: getStatusKey('Approved') } });
    }

    let filterQuery = {
      deleted: { eq: false },
    };

    filterQuery.and = [
      {
        or: [...filterConditions],
      },
    ];

    return filterQuery;
  };

  const releaseEdit = () => {
    let wasEditingId = localStorage.getItem('editInfoId');

    if (wasEditingId != 'null') {
      localStorage.setItem('editInfoId', null);

      let payload = {
        id: wasEditingId,
        editing: null,
      };

      API.graphql(
        graphqlOperation(mutations.updateKnowledgeBase, {
          input: payload,
        })
      );
    }
  };

  const getStatusKey = name => {
    if (name === 'Pending') {
      return 1;
    } else if (name === 'Unapproved') {
      return 2;
    } else if (name === 'Approved') {
      return 3;
    } else if (name === 'Active') {
      return 4;
    } else {
      return null;
    }
  };

  const onClickCreate = () => {
    dispatchGlobal({ type: 'updateEditInfo', editInfo: { empty: true } });
    dispatchGlobal({
      type: 'changeTableSort',
      tableSort: null,
    });
    dispatchGlobal({ type: 'changeTableFilter', tableFilter: 'All' });
    setRerenderKey(Date.now());
  };

  const closeRunBot = () => {
    setShowProperties(false);
  };

  const openRunBot = () => {
    setShowProperties(true);
  };

  const botInfo = {
    type: 'runBot',
  };

  const onChangeSearch = value => {
    setSearchKeyword(value);
  };

  const onClickSearch = () => {
    if (searchKeyword === '') {
      return;
    } else {
      setSearchActive(true);
      if (currentPage === 1) {
        setRerenderKey(Date.now());
      } else {
        setCurrentPage(1);
      }
    }
  };

  const onClickReset = () => {
    setSearchActive(false);
    setRerenderKey(Date.now());
    dispatchGlobal({
      type: 'changeTableSort',
      tableSort: null,
    });

    if (currentPage === 1) {
      setRerenderKey(Date.now());
    } else {
      setCurrentPage(1);
    }
  };

  const onSelectFilter = value => {
    setCurrentPage(1);
    if (value === filter || !value) {
      setFilter('All');
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: 'All' });
    } else {
      setFilter(value);
      dispatchGlobal({ type: 'changeTableFilter', tableFilter: value });
    }
    dispatchGlobal({ type: 'setRefetchKey', refetchKey: Date.now() });
    dispatchGlobal({ type: 'setCurrentPage', currentPage: 1 });
  };

  const filterOptions = [
    { value: 'Approved', label: 'Approved' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Published', label: 'Published' },
    { value: 'Denied', label: 'Denied' },
    { value: 'In Progress', label: 'In Progress' },
  ];

  const buildCSVData = FAQData => {
    let audienceObj = {};

    audiences.forEach(audience => {
      audienceObj[audience.flag] = audience.label;
    });

    const getAudienceText = audience => {
      if (audience in audienceObj) return audienceObj[audience];
    };

    const getStatusText = num => {
      if (num === 1) {
        return 'Pending';
      } else if (num === 2) {
        return 'Unapproved';
      } else if (num === 3) {
        return 'Approved';
      } else if (num === 4) {
        return 'Published';
      } else if (num === 5) {
        return 'Disabled';
      } else if (num === 7) {
        return 'Sending';
      } else if (num === 8) {
        return 'Active';
      } else if (num === 9) {
        return 'Finished';
      } else {
        return 'Unknown - Status Number: ' + num;
      }
    };

    let csvData = [];

    // console.log(FAQData);

    const parseModality = modality => {
      if (modality === null) {
        return ''; // Return an empty string if modality is null
      }
      if (modality.trim() === '') {
        return modality; // Return the modality as-is if it is an empty string
      }

      // Custom parsing logic to replace commas with forward slashes
      // And replace " with empty string
      return modality
        .replace(/,/g, '/')
        .replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/\]/g, '');
    };

    FAQData.forEach(FAQ => {
      // let audiences =
      //   FAQ.answerData.length === 1
      //     ? FAQ.answerData[0].answer
      //     : FAQ.answerData
      //         .map((answer, ind) => `#${ind + 1}: ${answer.answer}`)
      //         .join(", ");

      const modality = parseModality(FAQ.modality);

      let extractedModality = "";
      FAQ.answerData.forEach((answer) => {
        extractedModality = extractedModality === "" ? answer.type : extractedModality + "/" + answer.type;
      })

      const data = [
        extractedModality,
        getGroup(FAQ.group).label,
        FAQ.categoryName,
        FAQ.subcategoryNames[0],
        FAQ.answerData.length === 1
          ? getAudienceText(FAQ.answerData[0].audience[0])
          : FAQ.answerData
              .map(answer => getAudienceText(answer.audience[0]))
              .join(', '),
        FAQ.question.replace(/"/g, "'"),
        FAQ.answerData.length === 1
          ? `${getAudienceText(FAQ.answerData[0].audience[0])}: ${
              FAQ.answerData[0].answer.replace(/\n/g, " ").replace(/"/g, "'")
            }`
          : FAQ.answerData
              .map(
                answer =>
                  `${getAudienceText(answer.audience[0])}: ${answer.answer.replace(/\n/g, " ").replace(/"/g, "'")}`
              )
              .join(', '),
        getStatusText(FAQ.status),
        moment(parseInt(FAQ.lastEdit)).format('MM/DD/YY, h:mm a'),
        FAQ.editedBy.length === 1
          ? FAQ.editedBy[0].asurite
          : FAQ.editedBy.map(person => person.asurite).join(', '),
        moment(parseInt(FAQ.submittedOn)).format('MM/DD/YY, h:mm a'),
        FAQ.submittedBy,
        FAQ.answerData.length === 1
          ? getAudienceText(FAQ.answerData[0].audience[0])
          : FAQ.answerData
              .map(answer => getAudienceText(answer.audience[0]))
              .join(', '),
        FAQ.variations.length === 1
          ? FAQ.variations[0]
          : FAQ.variations.map(variation => variation).join(' | ').replace(/\n/g, " ").replace(/"/g, "'"),
      ];

      csvData.push(data);
    });

    if (csvData.length > 0) {
      csvData.unshift([
        'modality',
        'group',
        'section',
        'sectionType',
        'audiences',
        'question',
        'answer',
        'status',
        'dateEdited',
        'editedByUser',
        'dateSubmitted',
        'submittedByUser',
        'audience',
        'variation',
      ]);
    }
    return csvData;
  };

  return (
    <div style={{ ...theme.components.pageContainer }} key={rerenderKey}>
      <SectionHeader
        noSearch
        noSort
        rightView={
          tokens.role !== 'chat agent' && (
            <div style={{ display: 'flex' }}>
              <div className="ExportButton">
                <PanelButton
                  type="csv"
                  csvData={buildCSVData(FAQData)}
                  csvFileName={`knowledgeBase-${moment().format()}.csv`}
                  label="Export Data"
                  disabled={false}
                />
              </div>
              <div onClick={onClickCreate}>
                <button className="CreateFAQ">
                  <CreateNewButton
                    to="/knowledge-base/add-FAQ"
                    label="Create New FAQ"
                    noWrap={true}
                  />
                </button>
              </div>
            </div>
          )
        }
      />

      <NodeProperties
        show={showProperties}
        node={botInfo}
        close={closeRunBot}
      />

      <OpenSunny onClick={openRunBot} />

      <Paper
        style={{
          ...theme.components.paper,
          display: 'flex',
        }}
      >
        <Categories
          FAQData={FAQData}
          setFAQData={setFAQData}
          categories={categories}
          setCategories={setCategories}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          setActiveFAQ={setActiveFAQ}
          activeSubcategory={activeSubcategory}
          setActiveSubcategory={setActiveSubcategory}
          setRerenderKey={setRerenderKey}
          rerenderKey={rerenderKey}
        />

        <Switch>
          <Route
            exact
            path="/knowledge-base"
            render={() => (
              <FAQs
                setRerenderKey={setRerenderKey}
                setActiveCategory={setActiveCategory}              
                lastMessage={props.lastMessage}
              />
            )}
          />
          <Route
            exact
            path="/knowledge-base/add-FAQ"
            onLeave={releaseEdit}
            render={() => (
              <KBForm
                rerenderKey={rerenderKey}
                setRerenderKey={setRerenderKey}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/knowledge-base/category"
            render={() => (
              <CategoryInfo
                categories={categories}
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
                activeSubcategory={activeSubcategory}
                activeFAQ={activeFAQ}
                setActiveFAQ={setActiveFAQ}
                FAQData={FAQData}
                setRerenderKey={setRerenderKey}
                rerenderKey={rerenderKey}
              />
            )}
          />
        </Switch>
      </Paper>
    </div>
  );
}
