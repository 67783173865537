import React, { useState, useContext, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
// import ImportExportIcon from "@material-ui/icons/ImportExport";
import { useTheme, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';

import _ from 'lodash';
import axios from 'axios';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { CampaignAnalyticsWithTabs } from './CampaignAnalyticsContainer/index.js';
import AuthContext from 'contexts/AuthContext';
import { store as GlobalStore } from 'contexts/GlobalStore';
import { SectionHeader } from 'components/SectionHeader';
import {
  byStatusEndTime,
  processMixedFileNew as processMixedFile,
  customVarByCampId,
  campaignQueueUsersForProcessingNew as campaignQueueUsersForProcessing,
} from 'graphql/queries';
import { useModal, getUserGroups } from 'hooks';
import { roleCheck } from 'services/utility/RoleMatrix';
import { readString } from 'react-papaparse';

import {
  Form,
  Input,
  Textarea,
  UploadFile,
  DateTime,
  Select,
  CreateNewButton,
  FlowSelect,
  CampaignSelect,
  Modal,
  ModalContent,
  H6,
} from 'stories';

Storage.configure({ level: 'public' });

const SLICE_LIMIT_SINGLERUN = 0;
const SLICE_LIMIT = 10;
const CSV_LIMIT = 500000;

const TOO_LARGE =
  'CSV is too large to be processed, please upload a smaller csv.';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CampaignForm(props) {
  const theme = useTheme();
  const tokens = useContext(AuthContext);
  const { isShowing, toggle } = useModal();
  const { keyedNames, selectListGroups, getGroup } = getUserGroups();
  const history = useHistory();

  const getType = (t, i) => {
    try {
      return JSON.parse(t)[i];
    } catch (e) {
      return t;
    }
  };

  const GlobalState = useContext(GlobalStore);
  const dispatchGlobal = GlobalState.dispatch;
  const { editInfo, formTitle, viewOnly } = GlobalState.state;

  const [rerenderKey, setRerenderKey] = useState(Date.now());
  const [rerenderUploadFileKey, setRerenderUploadFileKey] = useState(
    Date.now()
  );
  const [formValues, setFormValues] = useState(null);
  const [uploadId, setUploadId] = useState(null);
  const [group, setGroup] = useState(
    tokens.role === 'publisher' && !editInfo.group
      ? getGroup(tokens.groups[0])
      : getGroup(editInfo.group)
      ? getGroup(editInfo.group)
      : null
  );
  const [modalContentType, setModalContentType] = useState('');
  const [updateFileLoad, setUpdateFileLoad] = useState(
    editInfo.id ? editInfo.audienceFileName : false
  );
  const [formSuccessful, setFormSuccessful] = useState(false);
  const [canPublish, setCanPublish] = useState(false);
  const [showBlueBanner, setShowBlueBanner] = useState(false);
  const [showProcessingMessage, setShowProcessingMessage] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [heldCsvData, setHeldCsvData] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [openError, setOpenError] = useState(null);
  const [isDryRun, setIsDryRun] = useState(editInfo.dryRun ? true : false);
  const [batchFileNames, setFileNames] = useState([]);
  const [importVariables, setImportVariables] = useState(null);
  const [importSummaryData, setImportSummaryData] = useState({
    errors: [],
    newNums: [],
    found: [],
    sentNums: [],
  });
  const [finishedCampaigns, setFinishedCampaigns] = useState([]);
  const [campaignId, setCampaignId] = useState();
  const [CSVFileStorage, setCSVFileStorage] = useState([]);
  const [originalCSVData, setOriginalCSVData] = useState('');
  const [audienceTargetType, setAudienceTargetType] = useState(
    editInfo.audienceTargetType ? JSON.parse(editInfo.audienceTargetType) : null
  );
  const [isPaused, setPaused] = useState(
    !editInfo.empty ? editInfo.paused : false
  );

  const [type, setType] = useState(
    editInfo.type ? getType(editInfo.type, 'value') : null
  );

  let time = new Date().getTime();

  useEffect(() => {
    // console.log(editInfo);
    if (editInfo.id && !editInfo.isDuplicate) {
      saveCsv(null, editInfo.id);
      getUploadedVars(editInfo.id);
    }

    queryForFinishedCampaigns();
    document.addEventListener('keydown', logCampaign);

    return () => {
      document.removeEventListener('keydown', logCampaign);
    };
  }, []);

  useEffect(() => {
    if (editInfo.isDuplicate) {
      setImportSummaryData({
        errors: [],
        newNums: [],
        found: [],
        sentNums: [],
      });
      setImportVariables(null);
      setUpdateFileLoad(false);
      setShowProcessingMessage(false);
    }
  }, [editInfo]);

  const logCampaign = e => {
    if (e.ctrlKey && e.key === 'i') console.log(editInfo);
  };

  console.log('EDIT INFO: ', editInfo);

  const startUpProcessing = async (idToWriteS3, group) => {
    if (heldCsvData) {
      await csvFileAdded(heldCsvData, idToWriteS3);
      await API.graphql(
        graphqlOperation(campaignQueueUsersForProcessing, {
          campaignId: idToWriteS3,
          group: group,
        })
      );
      props.history.push({
        pathname: '/campaigns',
      });
    } else {
      setFormSuccessful(false);
    }
  };

  const queryForFinishedCampaigns = async () => {
    let resp = await API.graphql(
      graphqlOperation(byStatusEndTime, {
        status: 9,
        sortDirection: 'DESC',
      })
    );

    let items = resp.data.byStatusEndTime.items;
    setFinishedCampaigns(items);
  };

  const onSubmit = async (data, e) => {
    if (data.type !== 'submit') {
      try {
        dispatchGlobal({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: true,
        });

        // parse data
        const campaignDetails = {};
        const dataArray = Object.keys(data);
        dataArray.forEach(el => {
          if (el === 'campaignName') {
            campaignDetails.title = data[el];
            campaignDetails.titleKey = data[el] + '-' + generateUID();
          } else if (el === 'description') {
            campaignDetails.description = data[el];
          } else if (el === 'type') {
            campaignDetails.type = data[el];
          } else if (el === 'audienceFileContent') {
            campaignDetails.audienceFileContent = data[el];
          } else if (el === 'audienceFileName') {
            campaignDetails.audienceFileName = data[el];
          } else if (el === 'audience') {
            campaignDetails.audience = data[el];
          } else if (el === 'startDate') {
            campaignDetails.startTime = data[el];
            campaignDetails.startTimeKey = data[el] + '-' + generateUID();
          } else if (el === 'endDate') {
            campaignDetails.endTime = data[el];
            campaignDetails.endTimeKey = data[el] + '-' + generateUID();
          } else if (el === 'flow') {
            campaignDetails.flow = data[el];
          } else if (el === 'flowVersion') {
            campaignDetails.flowVersion = data[el];
          } else if (el === 'status') {
            campaignDetails.status = getStatusKey(data[el]);
            campaignDetails.statusKey =
              getStatusKey(data[el]) + '-' + generateUID();
          } else if (el === 'group') {
            campaignDetails.group = JSON.parse(data[el]).value;
            campaignDetails.groupKey =
              JSON.parse(data[el]).label + '-' + generateUID();
          } else if (el === 'campaignTagAnswer') {
            campaignDetails.campaignTagAnswer = data[el];
          } else if (el === 'campaignSelect') {
            campaignDetails.campaignSelect = data[el];
          } else if (el === 'audienceTargetType') {
            campaignDetails.audienceTargetType = data[el];
          }
        });

        campaignDetails.dryRun = isDryRun;

        // not test values:
        campaignDetails.deleted = false;
        campaignDetails.paused = isPaused;
        campaignDetails.timestamp = moment().valueOf();
        campaignDetails.timestampKey = moment().valueOf() + '-' + generateUID();

        let user = {
          asurite: tokens.asurite,
          timestamp: moment().valueOf(),
        };

        if (campaignDetails.status === 4) {
          campaignDetails.approvedBy = user;
        }

        let idToWriteS3 = null;

        if (
          (editInfo && editInfo.empty) ||
          !editInfo ||
          typeof editInfo.id === 'undefined'
        ) {
          let id = idGenerator();
          idToWriteS3 = id;
          campaignDetails.id = idToWriteS3;
        } else {
          idToWriteS3 = editInfo.id;
        }

        checkForCSVUpload(campaignDetails, idToWriteS3);

        if (heldCsvData) {
          campaignDetails.fileUploadedBy = tokens.asurite;
          campaignDetails.numberProcessed = 0;
          campaignDetails.numberToProcess = heldCsvData.data.length;
          campaignDetails.processing = true;
          campaignDetails.processingError = false;
        }

        console.log('CAMP DETS: ', campaignDetails);

        // mutate backend data
        if (editInfo && !editInfo.empty && !editInfo.isDuplicate) {
          campaignDetails.editedBy = editInfo.editedBy
            ? editInfo.editedBy.concat(user)
            : [user];

          const updateCampaign = await API.graphql(
            graphqlOperation(mutations.updateCampaign, {
              input: { id: editInfo.id, ...campaignDetails },
            })
          );
          if (!updateCampaign.errors) {
            dispatchGlobal({
              type: 'setSpliceTableData',
              spliceTableData: { item: campaignDetails, type: 'editCampaign' },
            });
            if (heldCsvData) {
              await startUpProcessing(idToWriteS3, group);
            } else {
              props.history.push({
                pathname: '/campaigns',
              });
            }

            // setFormSuccessful(true);
          } else {
            alert('An error occurred, please try again.');
          }
        } else {
          if (heldCsvData) {
            campaignDetails.createdBy = tokens.asurite;

            const newCampaign = await API.graphql(
              graphqlOperation(mutations.createCampaign, {
                input: campaignDetails,
              })
            );

            if (!newCampaign.errors) {
              dispatchGlobal({
                type: 'setSpliceTableData',
                spliceTableData: {
                  item: {
                    ...campaignDetails,
                    id: newCampaign.data.createCampaign.id,
                  },
                  type: 'addCampaign',
                },
              });
              await startUpProcessing(idToWriteS3, group);
              // setFormSuccessful(true);
            } else {
              alert('An error occurred, please try again.');
            }
          } else {
            alert('No CSV Data');
            dispatchGlobal({
              type: 'setLoadingFormSubmission',
              loadingFormSubmission: false,
            });
          }
        }
      } catch (error) {
        console.log('campaign form submission error: ', error);
      } finally {
        dispatchGlobal({
          type: 'setLoadingFormSubmission',
          loadingFormSubmission: false,
        });
      }
    }
  };

  const idGenerator = () => {
    var S4 = function() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  };

  const checkForCSVUpload = (campaignDetails, id) => {
    if (
      campaignDetails.audienceFileContent &&
      campaignDetails.audienceFileContent.indexOf('uploaded') === -1
    ) {
      campaignDetails.audienceFileContent = 'uploaded_' + id;
    }

    return;
  };

  const queryForNewUser = async (user, type) => {
    let op = null;
    let pay = null;
    let opTxt = null;

    //console.log("!!! queryForNewUser", user);

    if (type === 'asurite') {
      op = queries.chatbotUserByAsurite;
      opTxt = 'chatbotUserByAsurite';
      pay = {
        asurite: user.user,
        filter: {
          botType: {
            eq: 'sunny',
          },
        },
      };
    } else {
      op = queries.chatbotUserByPhone;
      opTxt = 'chatbotUserByPhone';
      pay = {
        phoneNumber: user.user,
        filter: {
          botType: {
            eq: 'sunny',
          },
        },
      };
    }

    let resp = await API.graphql(graphqlOperation(op, pay));

    if (resp.data[opTxt].items.length === 0) {
      user.newUser = true;
    }
  };

  const uploadCSVToProcessingBucket = async (csvFile, id, bucket) => {
    let cpFile = JSON.parse(csvFile + '');
    let str = '';
    let timestmp = new Date().getTime();
    let users = [];

    console.log('FILE: ', csvFile);
    setOriginalCSVData(csvFile);
    let fileData = cpFile.data;

    let promises = [];

    let customKeys = cpFile.customFields;

    setCampaignId(id);

    for (var i = 0; i < fileData.length; ++i) {
      if (fileData[i].value && fileData[i].value !== '') {
        let extraFields = {};
        for (var j = 0; j < customKeys.length; ++j) {
          extraFields[customKeys[j]] = fileData[i][customKeys[j]]
            ? fileData[i][customKeys[j]]
            : '';
        }
        users.push({
          ...extraFields,
          user: fileData[i].value,
          type: fileData[i].type,
          conversionType: type,
        });
      }
    }

    str += 'value,type,conversionType';
    for (var j = 0; j < customKeys.length; ++j) {
      str += ',' + customKeys[j].toLowerCase().replace(' ', '');
    }
    str += '\n';
    for (var i = 0; i < users.length; ++i) {
      str += users[i].user + ',' + users[i].type + ',' + type;
      for (var j = 0; j < customKeys.length; ++j) {
        str += ',' + users[i][customKeys[j]];
      }
      str += '\n';
    }

    await Storage.put(`${tokens.env}/${bucket}/${id}.csv`, str, {
      bucket: process.env.REACT_APP_S3_DASHBOARD_ASSETS,
      contentType: 'text/csv',
      cacheControl: 'no-cache',
    });

    return [];
  };

  const runProcessingFn = async (id, fileName) => {
    let resp = await API.graphql(
      graphqlOperation(processMixedFile, {
        id: id,
        group: group,
      })
    );
    let res = JSON.parse(resp.data.processMixedFile);
    getUploadedVars(id);
    saveCsv(res);
    setUpdateFileLoad(fileName);
  };

  const getUploadedVars = async id => {
    let resp = await API.graphql(
      graphqlOperation(customVarByCampId, {
        campaignId: id,
      })
    );

    setImportVariables(resp.data.customVarByCampId.items);
  };

  const saveCsv = async (data, id) => {
    console.log('GETTING MY CSV');
    let parsedData = {
      errors: [],
      newNums: [],
      found: [],
      sentNums: [],
    };

    let dataInfo = null;

    console.log('MY CSV 1');

    if (id) {
      console.log('MY CSV 2');
      const cdata = await Storage.get(
        `${tokens.env}/campaigns/${id}.csv`,
        {
          download: true,
          bucket: process.env.REACT_APP_S3_DASHBOARD_ASSETS,
          cacheControl: 'no-cache',
        }
      );
      console.log('MY CSV 3');
      try {
        console.log('MY CSV 4');
        let str = new Buffer.from(cdata.Body).toString();
        let jsonData = readString(str, { header: true });
        dataInfo = jsonData.data;
      } catch (e) {
        console.log('MY CSV 5');
        console.log('MY CSV FAILED: ', e);
      }
    } else {
      console.log('MY CSV No Id');
      dataInfo = data;
    }

    console.log('MY CSV DAT: ', dataInfo);
    console.log('MY CSV 16');

    for (var i = 0; i < dataInfo.length; ++i) {
      if (dataInfo[i].timestamp == null) {
        continue;
      }
      if (
        dataInfo[i].isValid == '' ||
        dataInfo[i].isValid === false ||
        dataInfo[i].isValid === 'false'
      ) {
        parsedData.errors.push(dataInfo[i]);
      } else if (
        dataInfo[i].newUser === true ||
        dataInfo[i].newUser === 'true'
      ) {
        parsedData.newNums.push(dataInfo[i]);

        if (dataInfo[i].messageId) {
          parsedData.sentNums.push(dataInfo[i]);
        }
      } else if (dataInfo[i].user !== '') {
        parsedData.found.push(dataInfo[i]);
      }
    }

    let nLen = parsedData.newNums.length;
    let snLen = parsedData.sentNums.length;
    let eLen = parsedData.errors.length;
    let fLen = parsedData.found.length;

    if (nLen && !snLen && !fLen) {
      setCanPublish(false);
    } else {
      setCanPublish(true);
    }

    if (nLen && !snLen) {
      setShowBlueBanner(true);
    } else {
      setShowBlueBanner(false);
    }

    dispatchGlobal({
      type: 'setLoadingFormSubmission',
      loadingFormSubmission: false,
    });

    setCSVFileStorage(dataInfo);
    setImportSummaryData(parsedData);
  };

  const holdCsvData = csvData => {
    console.log('HELD: ', csvData);
    let msg = 'CSV saved to campaign';
    if (csvData.data.length >= CSV_LIMIT) {
      msg = TOO_LARGE;
      setHeldCsvData(null);
      setUpdateFileLoad(false);
      setShowProcessingMessage(false);
    } else {
      let estTime =
        Math.ceil(
          Math.floor(csvData.data.length / SLICE_LIMIT / 150) * 45 + 120
        ) / 60;
      msg =
        'CSV has been prepped for processing. Please save to continue. Estimated time: ' +
        estTime +
        ' minutes';
      setHeldCsvData(csvData);
    }

    setOpenError(msg);
    setCanPublish(false);
  };

  const csvFileAdded = async (csvData, idToWriteS3) => {
    let msg = 'CSV saved to campaign';

    if (idToWriteS3) {
      await Storage.put(
        `${tokens.env}/campaign-originals/${idToWriteS3}.csv`,
        originalCSVData,
        {
          bucket: process.env.REACT_APP_S3_DASHBOARD_ASSETS,
          contentType: 'text/csv',
          cacheControl: 'no-cache',
        }
      );
    }

    if (csvData.data.length >= CSV_LIMIT) {
      msg = 'CSV is too large to be processed, please upload a smaller csv.';
      dispatchGlobal({
        type: 'setLoadingFormSubmission',
        loadingFormSubmission: false,
      });
    } else {
      await splitForProcessing(csvData, idToWriteS3);
    }

    setOpenError(msg);
  };

  const saveOriginalCSV = async csvData => {
    setOriginalCSVData(csvData);
  };

  const splitForProcessing = async (csvData, id) => {
    setShowProcessingMessage(true);
    setUpdateFileLoad(csvData.fileName);

    let slices = [];
    let runningTotoal = 0;
    let fileCount = 0;
    let fileNames = [];

    let uploadResp = await uploadCSVToProcessingBucket(
      JSON.stringify(csvData),
      id,
      'campaigns-processing'
    );

    await Promise.all(slices);

    return;
  };

  const onSubmitUnapproval = data => {
    if (data.type !== 'submit') {
      axios
        .put(
          process.env.REACT_APP_APIGATEWAY_CHATBOT +
          '/chatbot-unapproval-email',
          {
            asurite: tokens.asurite,
            submittedBy: editInfo.createdBy,
            editedBy: editInfo.editedBy ? editInfo.editedBy : null,
            emailBody:
              'reason: ' +
              data.reasonForRejection +
              ' message: ' +
              data.message,
            details: "campaign name '" + formValues.campaignName + "'",
          }
        )
        .then(res => {
          console.log('onSubmitUnapproval res: ', res);
        })
        .catch(e => {
          console.log('onSubmitUnapproval error: ', e);
        });
      onSubmit(formValues);
    }
  };

  const createOnSubmit = data => {
    if (data.type !== 'submit') {
      if (
        JSON.parse(data.status).value === 'Unapproved' &&
        !editInfo.empty &&
        editInfo.status !== 2
      ) {
        setFormValues(data);
        setModalContentType('unapproval');
        toggle();
      } else {
        onSubmit(data);
      }
    }
  };

  const generateUID = () => {
    let firstPart = (Math.random() * 46656) | 0;
    let secondPart = (Math.random() * 46656) | 0;
    firstPart = ('000' + firstPart.toString(36)).slice(-3);
    secondPart = ('000' + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  };

  const getStatusKey = input => {
    const name = JSON.parse(input).value;
    if (name === 'Pending') {
      return 1;
    } else if (name === 'Unapproved') {
      return 2;
    } else if (name === 'Approved') {
      return 3;
    } else if (name === 'Published') {
      return 4;
    } else if (name === 'Disabled') {
      return 5;
    } else {
      return null;
    }
  };

  const getStatusName = key => {
    if (key === 1) {
      return 'Pending';
    } else if (key === 2) {
      return 'Unapproved';
    } else if (key === 3) {
      return 'Approved';
    } else if (key === 4) {
      return 'Published';
    } else if (key === 5) {
      return 'Disabled';
    } else {
      return null;
    }
  };

  const getStatusStateValue = () => {
    return 'Pending';
    // if (tokens.role === "content creator") {
    //   return "Pending";
    // } else if (
    //   tokens.role === "approver" &&
    //   editInfo &&
    //   editInfo.status === 4
    // ) {
    //   return "Approved";
    // } else if (editInfo.status) {
    //   return getStatusName(editInfo.status);
    // } else {
    //   return null;
    // }
  };

  const types = () => {
    return [
      { label: 'SMS', value: 'sms' },
      // { label: "Chat", value: "chat" },
    ];
  };

  const audienceTargetTypes = () => {
    return [
      { label: 'Query', value: 'query' },
      { label: 'Upload CSV', value: 'uploadCsv' },
    ];
  };

  const createStatusOptions = () => {
    let statusOptions = [{ value: 'Pending', label: 'Pending' }];

    if (roleCheck(tokens.role, 'campaigns', 'approveDenyContent')) {
      if (editInfo.id) {
        statusOptions.push({ value: 'Unapproved', label: 'Unapproved' });
      }
      statusOptions.push({ value: 'Approved', label: 'Approved' });
    }
    if (roleCheck(tokens.role, 'campaigns', 'publish') && canPublish) {
      statusOptions.push({ value: 'Published', label: 'Published' });
    }

    if (roleCheck(tokens.role, 'campaigns', 'disable')) {
      statusOptions.push({ value: 'Disabled', label: 'Disabled' });
    }

    return statusOptions;
  };

  const acceptType = t => {
    setRerenderUploadFileKey(Date.now());
    setType(t.value);
  };

  const updateFileKey = () => {
    setRerenderUploadFileKey(Date.now());
  };

  const acceptAudienceTargetType = t => {
    setAudienceTargetType(t);
  };

  const handleStartChange = c => {
    setStartTime(c);
  };

  const handleEndChange = c => {
    setEndTime(c);
  };

  const groupItems = () => {
    return tokens.groups.map(el => {
      return { label: el, value: el };
    });
  };

  const modalContent = () => {
    if (modalContentType === 'unapproval') {
      return (
        <ModalContent
          confirmation={null}
          form={true}
          formData={unapprovalFormData()}
          title="Unapproval reason"
          onSubmit={onSubmitUnapproval}
          toggle={toggle}
        />
      );
    } else {
      return null;
    }
  };

  const PauseSlider = withStyles(theme => ({
    root: {
      width: 45,
      height: 23,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(20px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
        // border: 'none',
      },
    },
    thumb: {
      marginTop: 1,
      marginLeft: 2,
      width: 19,
      height: 19,
    },
    track: {
      borderRadius: 23 / 2,
      // border: `1px solid ${theme.palette.grey[400]}`,
      border: `none`,
      // backgroundColor: theme.palette.grey[50],
      backgroundColor: '#D6D5D4',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <H6
          style={{
            fontWeight: 900,
            margin: '0px',
            fontSize: '0.875rem',
            marginRight: '10px',
          }}
        >
          {props.title ? props.title : 'PAUSE CAMPAIGN'}
        </H6>
        <Switch
          // checked={props.checked}
          // onChange={props.onChange}
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          {...props}
        />
      </div>
    );
  });

  const unapprovalFormData = () => {
    let emailList = [];
    emailList.push(tokens.asurite + '@asu.edu');
    emailList.push(editInfo.createdBy + '@asu.edu');
    editInfo.editedBy &&
      editInfo.editedBy.forEach(el => {
        emailList.push(el.asurite + '@asu.edu');
      });

    const uniqueEmailList = Array.from(new Set(emailList));

    return [
      {
        component: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontWeight: 400,
                marginLeft: '0.25rem',
              }}
            >
              <div>Message will be emailed to: </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {uniqueEmailList.map((el, index) => {
                  return (
                    <div
                      style={{
                        marginLeft: '0.25rem',
                      }}
                    >
                      {el}
                      {index !== uniqueEmailList.length - 1 && ','}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'REASON FOR REJECTION',
        required: true,
        component: <Input form name="reasonForRejection" required />,
      },
      {
        title: 'MESSAGE',
        required: true,
        component: (
          <Textarea
            form
            required
            name="message"
            // stateValue={editInfo.emailBody ? editInfo.emailBody : ""}
          />
        ),
      },
    ];
  };

  var formData = [
    {
      title: 'GROUP',
      required: true,
      component: (
        <Select
          form
          name="group"
          options={selectListGroups}
          onChange={e => setGroup(e.value)}
          required
          updateStateValue={
            tokens.role === 'publisher' && !editInfo.group
              ? getGroup(tokens.groups[0])
              : getGroup(editInfo.group)
              ? getGroup(editInfo.group)
              : null
          }
        />
      ),
    },
    {
      title: 'CAMPAIGN NAME',
      required: true,
      component: (
        <Textarea
          form
          required
          name="campaignName"
          stateValue={editInfo ? editInfo.title : null}
        />
      ),
    },
    {
      title: 'SHORT DESCRIPTION',
      maxlength: 250,
      required: true,
      component: (
        <Textarea
          form
          required
          name="description"
          // maxlength="250"
          stateValue={editInfo ? editInfo.description : null}
        />
      ),
    },
    {
      title: 'TYPE',
      required: true,
      component: (
        <Select
          form
          name="type"
          placeholder=" "
          options={types()}
          onChange={acceptType}
          required
          stateValue={editInfo.type ? getType(editInfo.type, 'label') : null}
        />
      ),
    },
    {
      title: 'AUDIENCE DESCRIPTION',
      required: true,
      component: (
        <Input
          form
          required
          charLimit="50"
          name="audience"
          stateValue={
            editInfo.isDuplicate ? null : editInfo ? editInfo.audience : null
          }
          isDuplicate={editInfo.isDuplicate}
        />
      ),
    },
    {
      title: 'TARGET AUDIENCE',
      required: true,
      component: (
        <UploadFile
          form
          required
          csvUpload={true}
          name="targetAudience"
          placeholder="Upload a CSV"
          campaigntype={type}
          group={group}
          accept=".csv"
          campaignId={campaignId}
          fileName={
            editInfo && !showProcessingMessage && !editInfo.isDuplicate
              ? editInfo.audienceFileName
              : null
          }
          fileContent={
            editInfo && !showProcessingMessage
              ? editInfo.audienceFileContent
              : null
          }
          isDuplicate={editInfo.isDuplicate}
          holdCsvData={holdCsvData}
          saveOriginalCSV={saveOriginalCSV}
          didReset={e => {
            setUpdateFileLoad(false);
            setShowProcessingMessage(false);
          }}
        />
      ),
    },
    {
      title: 'START DATE',
      required: true,
      component: (
        <DateTime
          form
          required
          minDate={time}
          maxDate={endTime ? endTime : null}
          onChange={handleStartChange}
          name="start"
          stateValue={editInfo ? editInfo.startTime : null}
        />
      ),
    },
    {
      title: 'END DATE',
      required: true,
      component: (
        <DateTime
          form
          required
          minDate={startTime ? startTime : time}
          onChange={handleEndChange}
          name="end"
          stateValue={editInfo ? editInfo.endTime : null}
        />
      ),
    },
    {
      title: 'FLOW TO EXECUTE',
      required: true,
      component: (
        <FlowSelect
          form
          required
          menuPlacement="top"
          name="flow"
          editInfo={editInfo}
        />
      ),
    },
    // {
    //     title: "UPLOAD CSV FILE",
    //     required: false,
    //     component: (
    //       <UploadFile
    //         form
    //         name="targetAudience"
    //         placeholder="Upload a CSV"
    //         campaigntype={type}
    //         accept=".csv"
    //         fileName={editInfo ? editInfo.audienceFileName : null}
    //         fileContent={editInfo ? editInfo.audienceFileContent : null}
    //         key={rerenderUploadFileKey}
    //       />
    //     )
    // }
    {
      title: 'SELECT CAMPAIGN AND TAG',
      required: false,
      component: (
        <CampaignSelect
          form
          menuPlacement="top"
          name="queryData"
          selectType="autoTag"
          campaigns={finishedCampaigns}
          editInfo={editInfo}
        />
      ),
    },
    {
      component: tokens.devAccess && (
        <PauseSlider
          checked={isDryRun}
          title="DRY RUN"
          onChange={() => setIsDryRun(!isDryRun)}
        />
      ),
    },
    {
      component: editInfo.active && (
        <PauseSlider checked={isPaused} onChange={() => setPaused(!isPaused)} />
      ),
    },
    {
      title: 'STATUS',
      required: true,
      component: (
        <Select
          form
          name="status"
          required
          placeholder="-- Select a Status --"
          isDisabled={tokens.role === 'content creator' ? true : false}
          options={createStatusOptions()}
          stateValue={getStatusStateValue()}
        />
      ),
    },
  ];

  const toDuplicateOnClick = () => {
    dispatchGlobal({ type: 'setFormTitle', formTitle: 'DUPLICATE CAMPAIGN' });
    let campaignToEdit = {
      // audienceFileContent: editInfo.audienceFileContent,
      // audienceFileName: editInfo.audienceFileName,
      description: editInfo.description,
      flow: editInfo.flow,
      flowVersion: editInfo.flowVersion,
      group: editInfo.group,
      groupKey: editInfo.groupKey,
      title: editInfo.title,
      type: editInfo.type,
      isDuplicate: true,
    };

    dispatchGlobal({
      type: 'updateEditInfo',
      editInfo: { ...campaignToEdit },
    });
  };

  const isEditCampaign = {
    isEditTrue: !editInfo.empty === true && editInfo.status !== 9,
    backHandler: () => history.push('/campaigns'),
    saveOnSubmit: createOnSubmit,
  };
  // !editInfo.empty === true && editInfo.status !== 9;

  const updatePauseCampaign = () => {
    if (!isPaused) {
      pauseCamp();
    } else {
      restartCamp();
    }
  };

  const pauseCamp = async () => {
    let payload = {
      operation: 'pauseCampaign',
      campaign: {
        id: editInfo.id,
      },
    };

    await API.graphql(graphqlOperation(queries.runCampaign, payload))
      .then(resp => {
        console.log('DONE PAUSING');
      })
      .catch(err => {
        console.log('Errored pausing', err);
      });

    const pauseCampaign = await API.graphql(
      graphqlOperation(mutations.updateCampaign, {
        input: { id: editInfo.id, paused: true },
      })
    );
    setPaused(true);
  };

  const restartCamp = async () => {
    let payload = {
      operation: 'restartCampaign',
      campaign: {
        id: editInfo.id,
      },
    };

    await API.graphql(graphqlOperation(queries.runCampaign, payload))
      .then(resp => {
        console.log('DONE START');
      })
      .catch(err => {
        console.log('Errored START', err);
      });

    const pauseCampaign = await API.graphql(
      graphqlOperation(mutations.updateCampaign, {
        input: { id: editInfo.id, paused: false },
      })
    );
    setPaused(false);
  };

  const rightViewContent = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
        onClick={onClickCreate}
      >
        <CreateNewButton
          to="campaign-form"
          label="Create New Campaign"
          isCapitalized={true}
          isNotBold={true}
          labelFontSize="1.15em"
        />
      </div>
    );
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(null);
  };

  const onClickCreate = () => {
    dispatchGlobal({ type: 'updateEditInfo', editInfo: { empty: true } });
    dispatchGlobal({ type: 'setFormTitle', formTitle: 'ADD CAMPAIGN' });
    dispatchGlobal({
      type: 'changeTableSort',
      tableSort: null,
    });
    dispatchGlobal({ type: 'changeTableFilter', tableFilter: 'All' });
    setRerenderKey(Date.now());
  };

  return (
    <div style={theme.components.pageContainer} key={rerenderKey}>
      <SectionHeader
        noSort
        noModality
        noSearch
        noFilter
        title={editInfo.empty ? 'NEW CAMPAIGN' : editInfo.title.toUpperCase()}
      />
      <Paper style={theme.components.paper}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            {isPaused ? (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  backgroundColor: '#3e88df',
                  color: 'white',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '1rem',
                  'max-height': '48px',
                }}
              >
                {'Campaign Paused'}
              </div>
            ) : null}

            {editInfo.processingError ? (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  backgroundColor: '#df1b1b',
                  color: 'white',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '1rem',
                  'max-height': '48px',
                }}
              >
                {
                  'Campaign has issues uploading file. Please retry upload, or contact development team if problem persists'
                }
              </div>
            ) : null}

            <div
              style={{
                flex: 1,
                padding: '2rem 4rem',
              }}
            >
              {/* Campain Currently Paused Banner - need to redefine isInitialPaused*/}
              {/* {isInitialPaused && (
              {/* Campain Currently Paused Banner */}
              <div
                style={{
                  pointerEvents: viewOnly ? 'none' : null,
                }}
              >
                <Form
                  title={formTitle}
                  data={formData}
                  onSubmit={createOnSubmit}
                  viewOnly={viewOnly}
                  duplicateHandler={toDuplicateOnClick}
                  isEditable={!editInfo.empty === true && editInfo.status !== 9}
                  editHandlerObj={isEditCampaign}
                  isDuplicate={editInfo.isDuplicate}
                />
              </div>

              {viewOnly ? (
                <PauseSlider
                  checked={isPaused}
                  onChange={() => updatePauseCampaign()}
                />
              ) : null}

              <Modal
                isShowing={isShowing}
                hide={toggle}
                content={modalContent()}
              />
            </div>
          </div>

          <CampaignAnalyticsWithTabs
            id={editInfo.id ? editInfo.id : null}
            isSendingIntroMessage={editInfo.isSendingIntroMessage}
            estSendMinutes={editInfo.estSendMinutes}
            uploadId={uploadId}
            viewOnly={viewOnly}
            file={editInfo ? editInfo.audienceFileContent : null}
            editInfo={editInfo}
            CSVFileStorage={CSVFileStorage}
            importSummaryData={importSummaryData}
            showProcessingMessage={showProcessingMessage}
            updateFileLoad={updateFileLoad}
            canPublish={canPublish}
            importVariables={importVariables}
            showBlueBanner={showBlueBanner}
            updateCsv={() =>
              saveCsv(null, editInfo.id ? editInfo.id : uploadId)
            }
          ></CampaignAnalyticsWithTabs>
        </div>
      </Paper>
      <Snackbar
        open={openError !== null}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={openError === TOO_LARGE ? 'error' : 'success'}
        >
          {openError}
        </Alert>
      </Snackbar>
    </div>
  );
}
