export function tableUpdate(item, previousArray) {
    let foundId = false;
    
    for (var i = 0; i < previousArray.length; ++i) {
      if (previousArray[i].id === item.id) {
        foundId = true;
        for (let key in item) {
          if (key === 'editedBy') {
            previousArray[i].editedBy = [];
            if (!item.editedBy) item.editedBy = [];
            for (var j = 0; j < item.editedBy.length; ++j) {
              previousArray[i].editedBy.push({
                asurite: item.editedBy[j].asurite,
                timestamp: item.editedBy[j].timestamp,
              });
            }
          } else {
            previousArray[i][key] = item[key];
          }
        }
        break;
      }
    }
  
    return {
      newArray: previousArray,
      foundId: foundId
    }
  };