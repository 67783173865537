import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { roleCheck } from 'services/utility/RoleMatrix';
import PaginationPanel from 'stories/Table/PaginationPanel';
import { SectionHeader } from 'components/SectionHeader';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import AuthContext from 'contexts/AuthContext';
import { store } from 'contexts/GlobalStore';
import { onUpdateChatbotIntroMessage } from 'graphql/subscriptions';
import { CreateNewButton, Table, PanelButton } from 'stories';
import NodeProperties from 'pages/Flows/FlowDiagram/Edit/NodeProperties.js';
import './CustomIntroductions.css';
import { useModal, getUserGroups } from 'hooks';
import { tableUpdate } from 'services/utility/tableUpdate';

import moment from 'moment-timezone';

const pageLimit = 7;

let subscription = null;
let rawData = [];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Alerts(props) {
  const tokens = useContext(AuthContext);

  const theme = useTheme();
  const GlobalState = useContext(store);
  const { dispatch } = GlobalState;
  const {
    tableSort,
    spliceTableData,
    clearNextTokens,
    tableDataEmpty,
    globalTableState,
  } = GlobalState.state;

  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [filter, setFilter] = useState('All');
  const [totalResults, setTotalResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPageClicked, setPrevPageClicked] = useState(false);
  const [nextTokenList, setNextTokenList] = useState([]);
  const [rerenderKey, setRerenderKey] = useState(Date.now());
  const [refetchKey, setRefetchKey] = useState(Date.now());
  const [editClicked, setEditClicked] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [showProperties, setShowProperties] = useState(false);
  const { getGroup } = getUserGroups();

  const [loadedTableState, setLoadedTableState] = useState(false);
  const [loadedTableSearchState, setLoadedTableSearchState] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'setTableDataEmpty',
      tableDataEmpty: false,
    });
  }, []);

  useEffect(() => {
    if (clearNextTokens) {
      setNextTokenList([]);
      dispatch({
        type: 'clearNextTokens',
        clearNextTokens: false,
      });

      if (currentPage === 1) {
        setRefetchKey(Date.now());
      } else {
        setCurrentPage(1);
      }
    }
  }, [clearNextTokens]);

  useEffect(() => {
    createFetchData();
  }, [rerenderKey, refetchKey, searchActive, currentPage, filter, tableSort]);

  useEffect(() => {
    if (tableDataEmpty) {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else if (!tableDataEmpty && !loadedTableState) {
      const pathName = window.location.pathname.replace('/', '');
      let storedPage =
        globalTableState &&
        globalTableState[pathName] &&
        globalTableState[pathName].pageNumber;
      if (storedPage) {
        setCurrentPage(storedPage);
        setLoadedTableState(true);
      }
    }
  }, [tableDataEmpty]);

  useEffect(() => {
    const pathName = window.location.pathname.replace('/', '');
    let newGlobalTableState = Object.assign({}, globalTableState);
    if (!newGlobalTableState[pathName]) {
      newGlobalTableState[pathName] = {};
    }
    newGlobalTableState[pathName].pageNumber = currentPage;
    dispatch({
      type: 'setGlobalTableState',
      globalTableState: newGlobalTableState,
    });
  }, [currentPage]);

  const fetchData = async (searchText, filter, sort) => {
    if (tableDataEmpty) {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: false,
      });
    } else {
      dispatch({
        type: 'setLoadingTableData',
        loadingTableData: true,
      });
    }
    try {
      let sortQuery = { field: 'timestamp', direction: 'desc' };
      if (sort) {
        sortQuery = { field: sort.field, direction: sort.direction };
      }

      let tokenIndex = currentPage - 2;
      if (currentPage > nextTokenList.length) {
        tokenIndex = nextTokenList.length - 1;
      }

      let results;

      //if (searchActive && filter) {

      let statusesToLookFor = filter
        ? [getStatusKey(filter)]
        : ['4', '3', '2', '1', '5'];

      results = statusesToLookFor.map(statusNumber => {
        return API.graphql(
          graphqlOperation(queries.introMessageByStatus, {
            status: statusNumber,
            filter: createFilterQuery(searchText, filter),
          })
        );
      });

      let resultItems = [];

      /*API.graphql(
        graphqlOperation(queries.listChatbotIntroMessages, {
          limit: pageLimit,
          sort: sortQuery,
          nextToken: nextTokenList[tokenIndex],
        })
      );*/

      Promise.all(results).then(resultArray => {
        if (filter) {
          resultItems = resultArray[0].data.introMessageByStatus.items;
        } else {
          resultItems = resultArray[0].data.introMessageByStatus.items.concat(
            resultArray[1].data.introMessageByStatus.items
          );
          resultItems = resultItems.concat(
            resultArray[2].data.introMessageByStatus.items
          );
          resultItems = resultItems.concat(
            resultArray[3].data.introMessageByStatus.items
          );
          resultItems = resultItems.concat(
            resultArray[4].data.introMessageByStatus.items
          );
        }

        const parsedData = parseData(resultItems);
        setData(
          parsedData.slice(
            (currentPage - 1) * pageLimit,
            pageLimit * currentPage
          )
        );
        rawData = resultItems;
        //console.log(parsedData);

        //console.log(resultItems);
        setTotalResults(resultItems.length);

        dispatch({
          type: 'setLoadingTableData',
          loadingTableData: false,
        });
      });
      //}
    } catch (error) {
      console.log('custom intros fetch data error: ', error);
    } finally {
      tableDataEmpty &&
        dispatch({
          type: 'setLoadingTableData',
          loadingTableData: false,
        });
    }
  };

  const createFetchData = () => {
    //restore previous search
    const pathName = window.location.pathname.replace('/', '');
    let localSearchActive = searchActive;
    let localSearchKeyword = searchKeyword;
    if (
      !loadedTableSearchState &&
      globalTableState &&
      globalTableState[pathName] &&
      globalTableState[pathName].search !== ''
    ) {
      localSearchActive = true;
      localSearchKeyword = globalTableState[pathName].search;
      setSearchActive(true);
      setSearchKeyword(globalTableState[pathName].search);
    }
    setLoadedTableSearchState(true);

    if (localSearchActive && filter !== 'All' && tableSort) {
      fetchData(localSearchKeyword, filter, tableSort);
    } else if (localSearchActive && filter !== 'All' && !tableSort) {
      fetchData(localSearchKeyword, filter, null);
    } else if (localSearchActive && filter === 'All' && tableSort) {
      fetchData(localSearchKeyword, null, tableSort);
    } else if (localSearchKeyword === '' && filter !== 'All' && tableSort) {
      fetchData(null, filter, tableSort);
    } else if (localSearchActive) {
      fetchData(localSearchKeyword);
    } else if (filter !== 'All') {
      fetchData(null, filter);
    } else if (tableSort) {
      fetchData(null, null, tableSort);
    } else {
      fetchData();
    }
  };

  const createFilterQuery = (searchText, filter) => {
    const filterConditions = [];

    let groupCheck = [{ group: { ne: 'Benji_1593621826' } }];

    /*if (!roleCheck(tokens.role, 'alerts', 'viewAllContent')) {
      filterConditions.push({ createdBy: { eq: tokens.asurite } });
      filterConditions.push({ status: { eq: getStatusKey('Published') } });
      filterConditions.push({ status: { eq: getStatusKey('Approved') } });
    }*/

    let filterQuery = {
      deleted: { eq: false },
    };

    filterQuery.and = [
      {
        and: [...groupCheck],
      },
    ];

    if (filterConditions.length > 0) {
      filterQuery.and.push({
        or: [...filterConditions],
      });
    }

    if (searchText && filter) {
      filterQuery.searchKey = { contains: searchText.toLowerCase() };
      //filterQuery.status = { eq: getStatusKey(filter) };
    } else if (filter) {
      //filterQuery.status = { eq: getStatusKey(filter) };
    } else if (searchText) {
      filterQuery.searchKey = { contains: searchText.toLowerCase() };
    }
    return filterQuery;
  };

  const getStatusKey = name => {
    if (name === 'Pending') {
      return 1;
    } else if (name === 'Unapproved') {
      return 2;
    } else if (name === 'Approved') {
      return 3;
    } else if (name === 'Published') {
      return 4;
    } else if (name === 'Disabled') {
      return 5;
    } else {
      return null;
    }
  };

  const parseData = data => {
    return data.map(el => {
      return {
        id: el.id,
        message:
          el.message.length > 33
            ? el.message.substring(0, 33) + '...'
            : el.message,
        status: {
          statusID: parseInt(el.status),
          processing: false,
          editing: null,
          error: null,
        },
        group: getGroup(el.group),
        botType: el.botType,
        submittedBy: el.createdBy,
        submittedOn: moment(el.createdAt).format('x'),
        actions: {
          editAsName: roleCheckEdit(el.group, el.submittedBy) ? true : false,
          delete:
            roleCheckDel(el.group, el.submittedBy) && el.status !== '4'
              ? true
              : false,
        },
      };
    });
  };

  const roleCheckEdit = (group, asurite) => {
    if (roleCheck(tokens.role, 'alerts', 'edit')) {
      if (tokens.asurite === asurite) {
        return true;
      } else {
        if (tokens.role === 'content creator' || tokens.role === 'approver') {
          if (tokens.groups.includes(group)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  const roleCheckDel = (group, asurite) => {
    if (roleCheck(tokens.role, 'alerts', 'delete')) {
      if (tokens.asurite === asurite) {
        return true;
      } else {
        if (tokens.role === 'content creator' || tokens.role === 'approver') {
          if (tokens.groups.includes(group)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    console.log("[CustomIntroductions.js] lastMessage updated", props.lastMessage);

    let post = {
      data: {},
      cms_identifier: ""
    };
    
    if (props.lastMessage !== null && props.lastMessage.data) {
      let full_post = JSON.parse(props.lastMessage.data)
      post.data = full_post.data || {};
      post.cms_identifier = full_post.cms_identifier || "";
    }

    if (post.cms_identifier === "onUpdateChatbotIntroMessage") {
      let kbItem = post.data;
      let tableUpdateResult = tableUpdate(kbItem, rawData);
      let foundId = tableUpdateResult.foundId;
      rawData = tableUpdateResult.newArray;

      if (foundId) {
        if (kbItem.deleted) {
          createFetchData();
        } else {
          let parsedData = parseData(tableUpdateResult.newArray);
          setData(parsedData);
          console.log("[CustomIntroductions.js] updatedAt", kbItem.updatedAt);
          setRerenderKey(kbItem.updatedAt);
        }
      }
    }

    return () => {
      // clean up in hook
    };
  }, [props.lastMessage]);

  const onSubmitDelete = async el => {
    let spliceIndex = null;

    el = rawData.filter((object, index) => {
      if (el.id === object.id) {
        spliceIndex = index;
        return true;
      } else {
        return false;
      }
    });

    if (el.length > 0) {
      el = el[0];

      if (el.status === '4') {
        return;
      }

      try {
        await API.graphql(
          graphqlOperation(mutations.updateChatbotIntroMessage, {
            input: {
              id: el.id,
              deleted: true,
              deletedBy: {
                asurite: tokens.asurite,
                timestamp: Date.now().toString(),
              },
            },
          })
        );

        rawData.splice(spliceIndex, 1);

        setData(parseData(rawData).slice(0, pageLimit));

        onClickReset();
      } catch (error) {
        console.log('chatbotIntroMessage delete error: ', error);
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onClickEdit = el => {
    rawData.forEach(data => {
      if (el.id === data.id) {
        dispatch({
          type: 'updateEditInfo',
          editInfo: {
            id: data.id,
            message: data.message,
            status: data.status,
            botType: data.botType,
            matches: data.matches,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            group: data.group,
            createdBy: data.createdBy,
          },
        });
      }
    });
    props.history.push({
      pathname: '/CustomIntroForm',
    });
  };

  const onChangeSearch = value => {
    setSearchKeyword(value);
  };

  const onClickSearch = () => {
    if (searchKeyword === '') {
      return;
    } else {
      setNextTokenList([]);
      setSearchActive(true);
      if (currentPage === 1) {
        setRerenderKey(Date.now());
      } else {
        setCurrentPage(1);
      }
    }
  };

  const onClickReset = () => {
    setNextTokenList([]);
    setSearchActive(false);
    setRerenderKey(Date.now);
    dispatch({
      type: 'changeTableSort',
      tableSort: null,
    });

    if (currentPage === 1) {
      setRerenderKey(Date.now());
    } else {
      setCurrentPage(1);
    }
  };

  const onSelectFilter = value => {
    setCurrentPage(1);
    dispatch({
      type: 'clearNextTokens',
      clearNextTokens: true,
    });
    if (value === filter || !value) {
      setFilter('All');
      dispatch({ type: 'changeTableFilter', tableFilter: 'All' });
    } else {
      setFilter(value);
      dispatch({ type: 'changeTableFilter', tableFilter: value });
    }
  };

  const onClickFetchMore = () => {
    setPrevPageClicked(false);
    if (currentPage === Math.ceil(totalResults / pageLimit)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onClickFetchLess = async () => {
    setPrevPageClicked(true);
    if (currentPage <= 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const onChangeJumpToPage = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const jumpToPageOptions = () => {
    const totalPages = Math.ceil(totalResults / pageLimit);
    const options = [];

    for (let i = 1; i <= totalPages; i++) {
      options.push(i);
    }
    const mappedOptions = options.map(el => {
      return { value: el, label: el };
    });
    return mappedOptions;
  };

  const onClickCreateNew = () => {
    dispatch({ type: 'updateEditInfo', editInfo: { empty: true } });
    dispatch({
      type: 'changeTableSort',
      tableSort: null,
    });
    dispatch({ type: 'changeTableFilter', tableFilter: 'All' });
  };

  const filterOptions = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Unapproved', label: 'Unapproved' },
    { value: 'Published', label: 'Published' },
    { value: 'Disabled', label: 'Disabled' },
  ];

  const tableLabels = [
    { title: 'Message', notClickable: true },
    { title: 'Status', notClickable: true },
    { title: 'Group', notClickable: true },
    { title: 'Bot ID', notClickable: true },
    { title: 'Created By', notClickable: true },
    { title: 'Created On', notClickable: true },
    { title: 'Actions', alignRight: true, notClickable: true },
  ];

  const getSortKey = label => {
    label = label.toUpperCase();
    if (label === 'MESSAGE') {
      return 'messageKey';
    } else if (label === 'STATUS') {
      return 'statusKey';
    } else if (label === 'CREATED BY') {
      return 'submittedByKey';
    } else if (label === 'CREATED ON') {
      return 'timestampKey';
    } else if (label === 'GROUP') {
      return 'groupKey';
    }
  };

  const closeRunBot = () => {
    setShowProperties(false);
  };

  const openRunBot = () => {
    setShowProperties(true);
  };

  const botInfo = {
    type: 'runBot',
  };
  return (
    <div style={theme.components.pageContainer} key={rerenderKey}>
      <div className="SectionHeader">
        <SectionHeader
          onChangeSearch={onChangeSearch}
          onClickSearch={onClickSearch}
          onClickReset={onClickReset}
          onSelectFilter={onSelectFilter}
          filterOptions={filterOptions}
          noSort
          noModality
          noGroup
          rightView={
            tokens.role !== 'chat agent' && (
              <div style={{ display: 'flex' }}>
                <div className="ExportButton"></div>
                <div onClick={onClickCreateNew}>
                  <button class="btn">
                    <CreateNewButton
                      to="/CustomIntroForm"
                      label="Create New Intro"
                      noWrap={true}
                    />
                  </button>
                </div>
              </div>
            )
          }
        />
      </div>
      <NodeProperties
        show={showProperties}
        node={botInfo}
        close={closeRunBot}
      />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Alert has been deleted successfully!
        </Alert>
      </Snackbar>

      <div className="TablePage">
        <Paper style={theme.components.paper}>
          {/* <div /> */}
          <Table
            title="Custom Introductions"
            body="This is the main page for Custom Introductions."
            labels={tableLabels}
            data={data}
            useversionstatus={true}
            onChangeSearch={onChangeSearch}
            onClickSearch={onClickSearch}
            onClickReset={onClickReset}
            onSelectFilter={onSelectFilter}
            onSubmitDelete={onSubmitDelete}
            onClickEdit={onClickEdit}
            // onClickView={onClickView}
            filterOptions={filterOptions}
            getSortKey={getSortKey}
            defaultSortKey="timestampKey"
            noSort
            //noFilter
            noSearch
          />
          <PaginationPanel
            theme={theme}
            currentPage={currentPage}
            pageCount={Math.ceil(totalResults / pageLimit)}
            onClickFetchMore={onClickFetchMore}
            onClickFetchLess={onClickFetchLess}
            jumpToPageOptions={jumpToPageOptions}
            onChangeJumpToPage={onChangeJumpToPage}
          />
        </Paper>
      </div>
    </div>
  );
}

Alerts.propTypes = {
  navigation: PropTypes.object,
};
