import React, { useState, useEffect, useContext } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useLocation } from 'react-router-dom';
import AuthContext from 'contexts/AuthContext';

import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';

const useDialogueSocket = caller => {
  console.log("Dialogues Websocket caller is " + caller + ".js");

  const tokens = useContext(AuthContext);
  let location = useLocation();
  const [wssToken, setWssToken] = useState("");
  const [hasAddedConvo, setHasAddedConvo] = useState(false);

  const getToken = async () => {
    let queryNames = ['getTokenForWss', 'getTokenForWSS'];
    let resp = await API.graphql(
      graphqlOperation(
        Object.keys(queries).includes(queryNames[0])
          ? queries[queryNames[0]]
          : queries[queryNames[1]],
        { operation: 'getJWT' }
      )
    );
    let token = resp.data.getTokenForWSS;
    setWssToken(token);
  };

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    console.log("[useDialogueSocket] Location changed to", location);
    if (location.pathname !== "/dialogues") {
      //skip prevent add convo
      //setHasAddedConvo(true);
    } else {
      //skip prevent re-add convo
      //setHasAddedConvo(false);
    }
  }, [location]);

  const wss_url = process.env.REACT_APP_APIGATEWAY_DASHBOARD_DIALOGUE +
    '?Authorizer=Bearer ' +
    wssToken;

  console.log("Dialogues Websocket hook called.");

  const [sendMessage, lastMessage, readyState] = useWebSocket(wss_url);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
  }[readyState];

  if (hasAddedConvo === false && connectionStatus === 'Open') {
    console.log("[sendMessage] Dialogues Websocket token is ", wssToken === "" ? " null." : " being used.");
    sendMessage(
      JSON.stringify({
        action: 'addConvo',
        convoId: 'NONE',
        agentChatting: false,
        asurite: 'SELF',
        phoneNumber: 'NONE',
        token: wssToken,
        type: 'BASIC',
        agentDetails: {
          asurite: tokens.asurite,
          displayName: tokens.displayName,
        },
      })
    );
    setHasAddedConvo(true);
  }

  return {
    lastMessage,
    sendMessage,
    wssToken,
    readyState,
    connectionStatus,
  };
};

export default useDialogueSocket;
